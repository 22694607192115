import { AnyAction } from 'redux'
import { PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { TravelState } from './types'
import { CLEAR_COMMUTE,
    CLEAR_COMMUTE_START_END,
    CLEAR_LEG_TRANSPORT,
    CLEAR_SURVEY_DETAIL,
    COMMUTE_SAVE_SUCCESS,
    COMMUTE_START_END_SUCCESS,
    FETCH_CARPOOLING_JOURNEY_SUCCESS, FETCH_CARPOOLING_CHAT_SUCCESS,
    FETCH_CARPOOLING_REVIEWS_SUCCESS,
    FETCH_CARPOOLING_USER_JOURNEY_SUCCESS,
    FETCH_FAVOURITE_ROUTES_SUCCESS,
    FETCH_GAME_LIST_SUCCESS,
    FETCH_JOURNEY_TYPES_SUCCESS,
    FETCH_LOG_JOURNEY_AI_SUCCESS, FETCH_MATCHES_JOURNEY_SUCCESS,
    FETCH_RECENT_ROUTES_SUCCESS,
    FETCH_STATISTICS_EMPLOYEE_SUCCESS,
    FETCH_STATISTICS_FAN_SUCCESS,
    FETCH_SURVEYS_SUCCESS,
    FETCH_TRANSPORT_CEO2_SUCCESS,
    FETCH_TRANSPORT_LIST_SUCCESS,
    FETCH_TRANSPORT_MODES_SUCCESS,
    FETCH_TRAVEL_ARTICLES_SUCCESS,
    FETCH_TRAVEL_COMMUTE_DETAIL_SUCCESS,
    FETCH_TRAVEL_CONTENT_BLOCK_SUCCESS,
    FETCH_TRAVEL_ERROR,
    FETCH_TRAVEL_START,
    LEG_START_END_SUCCESS,
    LEG_TRANSPORT_DETAILS_SUCCESS,
    LEG_TRANSPORT_SUCCESS,
    MULTIPLE_SAVE_LEGS_SUCCESS,
    PLAN_JOURNEY_SUCCESS,
    POST_ACCOMMODATION_SUCCESS,
    POST_LOG_ROUTE_SUCCESS,
    POST_SURVEY_SUCCESS,
    POST_TRAVEL_ARTICLE_BOOKMARK_SUCCESS,
    PRIZE_DRAW_ENTRY_SUCCESS, FETCH_CARPOOLING_MESSAGE_SUCCESS, FETCH_CLEAR_CARPOOLING_MESSAGE_SUCCESS } from './consts'

const initialState: TravelState = {
    gameList: [],
    transportList: [],
    savedRoutes: [],
    recentRoutes: [],
    // @ts-ignore
    transportCeo2: {},
    // @ts-ignore
    statisticsFan: {},
    // @ts-ignore
    statisticsEmployee: {},
    journeyTypes: [],
    logRoute: null,
    loading: false,
    transportModes: [],
    travelContentBlock: {
        image: '',
        title: '',
        subtitle: '',
        backgroundColor: '',
        overlayArticle: 0,
        bodyButtonUrl: '',
    },
    commuteStartEnd: {},
    legTransports: {},
    legTransportDetails: {},
    legStartEnd: {},
    multipleSaveLegs: [],
    // @ts-ignore
    commuteSave: {},
    travelArticles: {},
    // @ts-ignore
    planJourneyData: {},
    prizeDrawEntry: {},
    // @ts-ignore
    travelAccommodation: {},
    // @ts-ignore
    travelCommuteDetail: {},
    // @ts-ignore
    surveyDetail: {},
    carpoolingJourney: [],
    // @ts-ignore
    surveyById: {},
    carpoolingUserJourney: [],
    carpoolingReviews: [],
    matchesJourney: [],
    chatList: [],
    messageList: [],
}

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action: AnyAction): TravelState => {
    const { payload } = action
    switch (action.type) {
    case FETCH_TRAVEL_START:
        return { ...state, loading: true }

    case FETCH_GAME_LIST_SUCCESS:
        return {
            ...state,
            gameList: payload,
            loading: false,
        }

    case FETCH_TRANSPORT_LIST_SUCCESS:
        return {
            ...state,
            transportList: payload,
            loading: false,
        }

    case FETCH_FAVOURITE_ROUTES_SUCCESS:
        return {
            ...state,
            savedRoutes: payload,
            loading: false,
        }

    case POST_LOG_ROUTE_SUCCESS:
        return {
            ...state,
            logRoute: payload,
            loading: false,
        }

    case FETCH_RECENT_ROUTES_SUCCESS:
        return {
            ...state,
            recentRoutes: payload,
            loading: false,
        }

    case FETCH_TRANSPORT_CEO2_SUCCESS:
        return {
            ...state,
            transportCeo2: payload,
            loading: false,
        }

    case FETCH_STATISTICS_FAN_SUCCESS:
        return {
            ...state,
            statisticsFan: payload,
            loading: false,
        }
    case FETCH_STATISTICS_EMPLOYEE_SUCCESS:
        return {
            ...state,
            statisticsEmployee: payload,
            loading: false,
        }
    case FETCH_JOURNEY_TYPES_SUCCESS:
        return {
            ...state,
            journeyTypes: payload,
            loading: false,
        }
    case FETCH_TRANSPORT_MODES_SUCCESS:
        return {
            ...state,
            transportModes: payload,
            loading: false,
        }
    case FETCH_TRAVEL_CONTENT_BLOCK_SUCCESS:
        return {
            ...state,
            travelContentBlock: payload,
            loading: false,
        }
    case COMMUTE_START_END_SUCCESS:
        return {
            ...state,
            commuteStartEnd: payload,
            legStartEnd: {},
            // @ts-ignore
            travelAccommodation: {},
            loading: false,
        }
    case LEG_TRANSPORT_SUCCESS:
        return {
            ...state,
            legTransports: payload,
            loading: false,
        }
    case CLEAR_LEG_TRANSPORT:
        return {
            ...state,
            legTransports: {},
            legTransportDetails: {},
            legStartEnd: {},
        }
    case CLEAR_COMMUTE_START_END:
        return {
            ...state,
            commuteStartEnd: {},
            // @ts-ignore
            travelAccommodation: {},
        }
    case LEG_TRANSPORT_DETAILS_SUCCESS:
        return {
            ...state,
            legTransportDetails: payload,
            loading: false,
        }
    case LEG_START_END_SUCCESS:
        return {
            ...state,
            legStartEnd: payload,
            loading: false,
        }
    case MULTIPLE_SAVE_LEGS_SUCCESS:
        return {
            ...state,
            multipleSaveLegs: payload,
            loading: false,
        }
    case COMMUTE_SAVE_SUCCESS:
        return {
            ...state,
            commuteSave: payload,
            loading: false,
        }
    case CLEAR_COMMUTE:
        return {
            ...state,
            // @ts-ignore
            commuteSave: {},
        }
    case PLAN_JOURNEY_SUCCESS:
        return {
            ...state,
            planJourneyData: payload,
            loading: false,
        }
    case PRIZE_DRAW_ENTRY_SUCCESS:
        return {
            ...state,
            planJourneyData: payload,
            loading: false,
        }
    case FETCH_TRAVEL_ARTICLES_SUCCESS:
        return {
            ...state,
            travelArticles: payload,
            loading: false,
        }
    case POST_TRAVEL_ARTICLE_BOOKMARK_SUCCESS:
        const newTravelArticleList = state?.travelArticles?.articles?.map((item) => (item.id === payload.bookmarkedId ? {
            ...item,
            isBookmark: payload.isBookmark,
        }
            : item))
        return {
            ...state,
            travelArticles: {
                articles: newTravelArticleList,
            },
        }
    case POST_ACCOMMODATION_SUCCESS:
        return {
            ...state,
            travelAccommodation: payload,
            legStartEnd: {},
            loading: false,
        }
    case FETCH_TRAVEL_COMMUTE_DETAIL_SUCCESS:
        return {
            ...state,
            travelCommuteDetail: payload,
        }
    case POST_SURVEY_SUCCESS:
        return {
            ...state,
            surveyDetail: payload,
        }
    case FETCH_CARPOOLING_JOURNEY_SUCCESS:
        return {
            ...state,
            carpoolingJourney: payload,
        }
    case FETCH_CARPOOLING_USER_JOURNEY_SUCCESS:
        return {
            ...state,
            carpoolingUserJourney: payload,
        }
    case FETCH_CARPOOLING_REVIEWS_SUCCESS:
        return {
            ...state,
            carpoolingReviews: payload,
            loading: false,
        }
    case FETCH_LOG_JOURNEY_AI_SUCCESS:
        return {
            ...state,
            loading: false,
        }
    case FETCH_MATCHES_JOURNEY_SUCCESS:
        return {
            ...state,
            matchesJourney: payload,
            loading: false,
        }

    case CLEAR_SURVEY_DETAIL:
        return {
            ...state,
            // @ts-ignore
            surveyDetail: {},
        }
    case FETCH_SURVEYS_SUCCESS:
        return {
            ...state,
            surveyById: payload,
            loading: false,
        }
    case FETCH_CARPOOLING_CHAT_SUCCESS:
        const newChatList = payload
        const updatedChatList = state.chatList.map((chat) => {
            const updatedChat = newChatList.find((newChat: any) => newChat.id === chat.id)
            return updatedChat ? { ...chat, ...updatedChat } : chat
        })

        newChatList.forEach((newChat: any) => {
            if (!state.chatList.some((chat) => chat.id === newChat.id)) {
                updatedChatList.push(newChat)
            }
        })

        return {
            ...state,
            chatList: updatedChatList,
            loading: false,
        }
    case FETCH_CARPOOLING_MESSAGE_SUCCESS:
        return {
            ...state,
            messageList: [
                ...state.messageList,
                ...payload.filter((msg: any) => !state.messageList.some((existingMsg) => existingMsg.id === msg.id)),
            ],
            loading: false,
        }
    case FETCH_CLEAR_CARPOOLING_MESSAGE_SUCCESS:
        return {
            ...state,
            messageList: [],
        }
    case FETCH_TRAVEL_ERROR:
        return { ...state, loading: false }

    default:
        return state
    }
}

const persistConfig: PersistConfig<TravelState> = {
    key: 'travel',
    storage,
    whitelist: [
        'multipleSaveLegs',
        'legStartEnd',
        'legTransportDetails',
        'legTransports',
        'commuteStartEnd',
        'travel',
        'transportList',
        'gameList',
        'savedRoutes',
        'recentRoutes',
        'journeyTypes',
        'transportModes',
        'travelContentBlock',
        'planJourneyData',
        'travelArticles',
        'commuteSave',
        'travelAccommodation',
        'travelCommuteDetail',
        'carpoolingJourney',
        'carpoolingUserJourney',
        'matchesJourney',
        'chatList',
    ],
}

export default persistReducer(persistConfig, reducer)

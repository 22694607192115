import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import Select, { GroupBase } from 'react-select'
import { useNavigate } from 'react-router-dom'
import Layout from '../../components/layout/Layout'
import SecondButton from '../../components/common/SecondButton'
import { getSavedPlacesList, getUser, getWhiteLabelingList } from '../../state/user/selectors'
import Dropdown from '../../components/travel/DropDown'
import GoogleAutoComplete from '../../components/GoogleAutoComplete'
import { DefaultHeartIcon } from '../../components/common/Svg'
import { fetchSavedPlaces } from '../../state/user/actions'
import JourneyInfoModal from '../../components/carpooling/JourneyInfoModal'
import { ColourOption, colourStyles } from '../../utils/organisationUnit'
import { optionsTravelling } from '../../utils/carpooling'
import { IGame } from '../../types/data'
import { fetchGame } from '../../state/travel/actions'

export default function CarpoolingNewJourney() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector(getUser)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const { savedPlaces } = useSelector(getSavedPlacesList)
    const [t] = useTranslation('global')
    const [gameDate, setGameDate] = useState<IGame | null>(null)
    const [travellingAs, setTravellingAs] = useState<any>('')
    const [startPlace, setStartPlace] = useState({ placeId: '', lon: '', lat: '', name: '' })
    const [showInfoModal, setShowInfoModal] = useState<boolean>(false)
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

    useEffect(() => () => {
        setShowInfoModal(true)
    }, [])

    useEffect(() => {
        dispatch(fetchSavedPlaces)
        dispatch(fetchGame())
        const handlePopState = () => {
            setShowInfoModal(true)
            window.history.pushState(null, document.title, window.location.pathname)
        }

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

        window.addEventListener('popstate', handlePopState)
        window.history.pushState(null, document.title, window.location.pathname)

        return () => {
            window.removeEventListener('popstate', handlePopState)
        }
    }, [dispatch])

    const handleSelectGameChange = (selectedOption: any) => {
        setGameDate(selectedOption?.item)
    }

    const handleSelectTravellingAsChange = (selectedOption: any) => {
        setTravellingAs(selectedOption)
    }

    const handleCancel = () => {
        setShowInfoModal(true)
    }

    useEffect(() => {
        const handleBeforeUnload = (event: any) => {
            const message = ''
            event.returnValue = message
            return message
        }

        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])

    const saveJourneyData = () => {
        const journeyData = {
            gameDate,
            startPlace,
            travellingAs,
        }
        sessionStorage.setItem('carpoolingJourneyData', JSON.stringify(journeyData))
    }

    const savedJourneyData = sessionStorage.getItem('carpoolingJourneyData')
    const parsedData = savedJourneyData ? JSON.parse(savedJourneyData) : ''

    useEffect(() => {
        if (savedJourneyData) {
            setGameDate(parsedData?.gameDate)
            setStartPlace(parsedData?.startPlace)
            setTravellingAs(parsedData?.travellingAs)
        }
        // eslint-disable-next-line
    }, [])

    const selectProps = {
        defaultValue: optionsTravelling(t).find(
            (option) => option.id === parsedData?.travellingAs?.id,
        ),
    }

    return (
        <Layout showFooter={false}>
            <div className="w-full min-h-screen">
                <div
                    className="pb-[100px] flex flex-col items-center mobile:mt-[54px] mt-[12px] mobile:px-[42px] px-[16px]">
                    <div className="max-w-[600px] w-full">
                        <h1 className="heading-uppercase text-22 tracking-tight font-semibold text-cavolo mt-2">
                            {t('carpooling.new-journey')}
                        </h1>

                        <h1 className="font-normal not-heading-font text-cavolo text-14 mt-2">
                            {t('carpooling.tell-us-about-the-journey-you-would-like-to-share')}
                        </h1>

                        <h1 className="font-medium not-heading-font text-cavolo text-18 mt-2">
                            {t('carpooling.select-event-you-are-attending').replace('[event]', user?.company?.travelEventName)}
                        </h1>

                        <div className="mt-[7px]">
                            <Dropdown carpooling handleSelectChange={handleSelectGameChange}
                                defaultGameId={gameDate?.id || parsedData?.gameDate?.id} />
                        </div>

                        <h1 className="font-medium not-heading-font text-cavolo text-18 mt-4">
                            {t('global.starting-from')}
                        </h1>

                        <h1 className="font-normal not-heading-font text-cavolo text-14 my-1.5">
                            {t('carpooling.this-will-be-visible-to-others-so-we-suggest-your-road-name-or-postcode-not-your-exact-address')}
                        </h1>

                        {isEmpty(savedPlaces) && user?.company?.showSavedPlaces ? (
                            <div className="flex items-center mb-2.5">
                                <DefaultHeartIcon />

                                <h1 className="ml-2.5 text-12 text-grey01">
                                    {t('savedPlaces.tap-the-heart-to-save-a-place-for-future-use')}
                                </h1>
                            </div>
                        ) : null}

                        <GoogleAutoComplete placeholder={t('log.start-typing')} setPlace={setStartPlace}
                            defaultValue={startPlace} />

                        <h1 className="font-medium not-heading-font text-cavolo text-18 mt-4 mb-1.5">
                            {t('carpooling.travelling-as')}
                        </h1>

                        <Select<ColourOption, false, GroupBase<ColourOption>>
                            {...selectProps}
                            maxMenuHeight={200}
                            options={optionsTravelling(t)}
                            placeholder={t('log.select-an-option')}
                            styles={colourStyles(whiteLabeling)}
                            isSearchable={false}
                            onChange={handleSelectTravellingAsChange}
                        />

                        {isSubmitted && (!gameDate || !travellingAs || !startPlace.placeId) && (
                            <h1 className="text-[#BD4823] tracking-[-0.02em] leading-[140%] text-15 font-semibold mt-2">
                                {t('carpooling.please-complete-all-fields')}
                            </h1>
                        )}

                        <SecondButton
                            onClick={() => {
                                setIsSubmitted(true)

                                if (gameDate && travellingAs && startPlace.placeId) {
                                    saveJourneyData()
                                    navigate('/carpooling-journeys/journey-detail')
                                }
                            }}
                            text={t('carpooling.next')}
                            className="mt-4 w-full h-[48px] font-bold bg-rocket rounded-md text-16 text-pistachio"
                        />

                        <SecondButton
                            onClick={handleCancel}
                            text={t('global.cancel')}
                            className="mt-2 w-full h-[48px] font-bold border border-cavolo rounded-md text-16 text-cavolo"
                        />
                    </div>
                </div>

                <JourneyInfoModal
                    title={t('carpooling.are-you-sure-you-want-to–leave-without-adding-a-new-journey')}
                    showDeleteModal={showInfoModal}
                    setShowDeleteModal={setShowInfoModal}
                />
            </div>
        </Layout>
    )
}

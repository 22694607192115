import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import cn from 'classnames'
import ReactGA from 'react-ga4'
import ContentBlockComponent from '../components/ContentBlockComponent'
import { getLandingPageList, getUser } from '../state/user/selectors'
import { fetchLandingPage } from '../state/user/actions'
import { ContentBlock, ILandingPageData } from '../types/data'
import { sortSticky } from '../utils/helpers'

export default function LandingPage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { token } = useSelector(getUser)
    const { landingPage } = useSelector(getLandingPageList)
    const { id } = useParams()

    useEffect(() => {
        dispatch(fetchLandingPage(Number(id)))
        // eslint-disable-next-line
    }, [id])

    useEffect(() => {
        if (token && token.token) {
            navigate('/')
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Landing page' })
    }, [])

    const content = sortSticky(landingPage?.map((item: ILandingPageData) => ({
        position: item?.position || 0,
        component: <ContentBlockComponent item={item} />,
        contentBlock: item.contentBlock,
    })))

    return (
        <div className="flex flex-col items-center min-h-screen pt-3">
            {content?.map((i: {position: number, component: JSX.Element, contentBlock: ContentBlock}) => (
                <div className={cn('max-w-[500px]', {
                    sticky: i.contentBlock.sticky,
                    'flex-1': !i.contentBlock.sticky,
                    'mb-3': i.contentBlock.sticky !== 'sticky-bottom',
                })} style={{
                    bottom: i.contentBlock.sticky === 'sticky-bottom' ? 0 : undefined,
                    top: i.contentBlock.sticky === 'sticky-top' ? 0 : undefined,
                    zIndex: i.contentBlock.sticky ? 10 : 1,
                }}>
                    {i.component}
                </div>
            ))}
        </div>
    )
}

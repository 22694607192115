import React from 'react'
import { useSelector } from 'react-redux'
import { getWhiteLabelingList } from '../../state/user/selectors'

interface ClockProps {
  hour: number ; // 0-12
  minute: number; // 0-59
}

// eslint-disable-next-line react/function-component-definition
const Clock: React.FC<ClockProps> = ({ hour, minute }) => {
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    // Normalize hour to 12-hour format
    const normalizedHour = hour % 12

    // Calculate angles
    const hourAngle = (normalizedHour + minute / 60) * 30 // Each hour is 30 degrees
    const minuteAngle = minute * 6 // Each minute is 6 degrees

    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
            {/* Outer Circle */}
            <circle cx="9" cy="9" r="8.75" stroke="#00362B" strokeWidth="0.5" />

            {/* Hour Hand */}
            <line
                x1="9"
                y1="9"
                x2="9"
                y2="4.5"
                stroke={whiteLabeling?.cavolo}
                strokeWidth="1"
                transform={`rotate(${hourAngle} 9 9)`}
            />

            {/* Minute Hand */}
            <line
                x1="9"
                y1="9"
                x2="9"
                y2="2.5"
                stroke={whiteLabeling?.cavolo}
                strokeWidth="1"
                transform={`rotate(${minuteAngle} 9 9)`}
            />
        </svg>
    )
}

export default Clock

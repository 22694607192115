import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import Layout from '../../components/layout/Layout'
import { ArrowTravel, RightArrowMessage, UserDefaultIcon } from '../../components/common/Svg'
import { agreeGoingTogether,
    clearCarpoolingMessageSuccess,
    createCarpoolingMessages,
    fetchCarpoolingChat,
    fetchCarpoolingMessage } from '../../state/travel/actions'
import { chatsList, messagesList } from '../../state/travel/selectors'
import { CarpoolingMessageList } from '../../types/data'
import { getUser, getWhiteLabelingList } from '../../state/user/selectors'
import { formatResponseHour } from '../../utils/date'
import SecondButton from '../../components/common/SecondButton'

export default function CarpoolingMessageIndividual() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { user } = useSelector(getUser)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const { chatList } = useSelector(chatsList)
    const { id } = useParams()
    const { messageList } = useSelector(messagesList)
    const [t] = useTranslation('global')
    const [message, setMessage] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const messagesContainerRef = useRef<HTMLDivElement>(null)

    const chatListDetail = chatList.find((item) => item?.id === Number(id))
    const isUserAgreed = chatListDetail?.agreedUsers?.includes(user?.id)

    const scrollToBottomIfNeeded = () => {
        const container = messagesContainerRef.current
        if (container && container.scrollHeight > container.clientHeight) {
            container.scrollTo({ top: container.scrollHeight + 1000, behavior: 'smooth' })
        }
    }

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(e.target.value)
    }

    const onSubmit = (event: any) => {
        event?.preventDefault()
        dispatch(createCarpoolingMessages({
            isReportChat: location?.state?.isReportUser || location?.state?.data?.isReportChat,
            receiver: location?.state?.data?.user?.id,
            text: message,
        }, () => {
            setMessage('')

            dispatch(fetchCarpoolingMessage(id, undefined, () => {
                setTimeout(scrollToBottomIfNeeded, 100)
            }))
        }))
    }

    const onRestartLoad = useCallback(() => {
        dispatch(clearCarpoolingMessageSuccess())

        dispatch(fetchCarpoolingMessage(id, undefined, () => {
            setTimeout(scrollToBottomIfNeeded, 100)
        }))
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(fetchCarpoolingMessage(id, messageList[messageList.length - 1]?.id))
        }, 10000)

        return () => clearInterval(interval)
    }, [dispatch, id, messageList])

    const groupedMessages: { [date: string]: CarpoolingMessageList[] } = {}

    messageList.forEach((msg) => {
        const messageDate = moment(msg.timestamp).format('D MMM')
        if (!groupedMessages[messageDate]) {
            groupedMessages[messageDate] = []
        }
        groupedMessages[messageDate].push(msg)
    })

    const onSubmitAgreeGoingTogether = async () => {
        setLoading(true)
        dispatch(agreeGoingTogether({ isAgree: !isUserAgreed, chat: Number(id) }, () => {
            dispatch(fetchCarpoolingChat())

            dispatch(fetchCarpoolingMessage(id, undefined, () => {
                setTimeout(scrollToBottomIfNeeded, 100)
            }))
            setLoading(false)
        }))
    }

    return (
        <Layout showFooter={false}>
            <div className="w-full min-h-screen">
                <div className="w-full flex justify-center">
                    <div className="flex items-center w-full max-w-[620px] fixed h-[60px] bg-white px-4">
                        <div onClick={() => navigate(-1)} className="flex justify-end cursor-pointer">
                            <ArrowTravel width={22} height={22} />
                        </div>

                        {location?.state?.data?.user?.uploadedImage ? (
                            <img
                                className="rounded-full medium:w-10 medium:h-10 w-[36px] h-[36px] object-cover mx-2.5"
                                src={location?.state?.data?.user?.uploadedImage}
                                alt=""
                            />
                        ) : (
                            <UserDefaultIcon className="w-[38px] h-[38px] mx-2.5" />
                        )}

                        <h1 className="heading-uppercase text-22 tracking-tight font-semibold text-cavolo">
                            {location?.state?.isReportUser || location?.state?.data?.isReportChat ? t('carpooling.report-a-problem') : `${location?.state?.data?.user?.firstName} ${location?.state?.data?.user?.lastName[0]}.`}
                        </h1>
                    </div>
                </div>

                <div ref={messagesContainerRef}
                    className="flex flex-col items-center overflow-y-auto h-[calc(100vh-400px)] mobile:mt-[54px] mt-3 mobile:px-[42px] px-4 mb-[200px]">
                    <div className="max-w-[600px] w-full max-mobile:mt-[50px]">
                        {Object.entries(groupedMessages).map(([date, messages]) => (
                            <div key={date}>
                                <h1 className="my-2 text-center font-normal text-12 text-cavolo">
                                    {date}
                                </h1>

                                <div className="w-full">
                                    {messages.map((msg: CarpoolingMessageList) => {
                                        const isHighlighted = msg.text === 'you-are-going-together' || msg.text === 'you-are-not-going-together'

                                        return (
                                            <div key={msg.id}
                                                className={`flex items-center mb-2 ${
                                                    msg?.sender === Number(user?.id) ? 'justify-end' : 'justify-between'}`}>
                                                <div style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
                                                    className={`max-w-[70%] rounded-[15px] py-2.5 px-3 ${
                                                        isHighlighted
                                                            ? 'bg-raspberry text-savoy'
                                                            : msg?.sender === Number(user?.id)
                                                                ? 'bg-spinach text-savoy'
                                                                : 'bg-pistachio text-cavolo'
                                                    }`}
                                                >
                                                    <h1 className="font-normal text-12">
                                                        {isHighlighted
                                                            ? t(`carpooling.${msg.text}`).replace('[name]', location?.state?.data?.user?.firstName)
                                                            : msg?.text}
                                                    </h1>
                                                </div>

                                                <span className="font-normal text-cavolo text-12 ml-5 mr-2.5">
                                                    {formatResponseHour(msg?.timestamp)}
                                                </span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="fixed bottom-0 w-full">
                    <div className="mx-auto max-w-[600px] relative h-full">
                        <div className="mb-[22px] relative mx-4">
                            <form onSubmit={onSubmit}>
                                <input
                                    className="w-full border border-avocado rounded-lg p-2.5 text-black"
                                    name="message"
                                    placeholder={t('carpooling.type-a-message')}
                                    type="text"
                                    value={message}
                                    onSubmit={() => console.log('submit')}
                                    onChange={handleChange}
                                />
                            </form>

                            {message && (
                                <div onClick={onSubmit}
                                    className="w-[27px] h-[27px] flex items-center justify-center border border-rocket rounded-full absolute top-0 bottom-0 my-auto right-3 cursor-pointer">
                                    <RightArrowMessage />
                                </div>
                            )}
                        </div>
                    </div>

                    {location?.state.data ? (
                        (!location?.state?.data?.isReportChat && user.email !== 'carpooling_report@lowr.co') && (
                            <div className="bg-pistachio px-[14px] py-2">
                                <h1 className="text-12 font-normal text-cavolo">
                                    {t('carpooling.decided-to-go-together-confirm-here')}
                                </h1>

                                <div className="flex justify-center">
                                    <SecondButton
                                        loading={loading}
                                        onClick={onSubmitAgreeGoingTogether}
                                        text={t(!isUserAgreed ? 'carpooling.i’m-going-with-name' : 'carpooling.i’m-not-going-with-name').replace('[name]', location?.state?.data?.user.firstName)}
                                        className={`${loading ? 'w-[230px]' : ''} px-[27px] mt-[14px] h-[37px] font-bold border border-cavolo rounded-md text-14 text-cavolo`}
                                        loaderColor={whiteLabeling?.spinach}
                                    />
                                </div>
                            </div>
                        )
                    ) : (
                        (!location?.state?.isReportUser && user.email !== 'carpooling_report@lowr.co') && (
                            <div className="bg-pistachio px-[14px] py-2">
                                <h1 className="text-12 font-normal text-cavolo">
                                    {t('carpooling.decided-to-go-together-confirm-here')}
                                </h1>

                                <div className="flex justify-center">
                                    <SecondButton
                                        loading={loading}
                                        onClick={onSubmitAgreeGoingTogether}
                                        text={t(!isUserAgreed ? 'carpooling.i’m-going-with-name' : 'carpooling.i’m-not-going-with-name').replace('[name]', location?.state?.data?.user.firstName)}
                                        className={`${loading ? 'w-[230px]' : ''} px-[27px] mt-[14px] h-[37px] font-bold border border-cavolo rounded-md text-14 text-cavolo`}
                                        loaderColor={whiteLabeling?.spinach}
                                    />
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
        </Layout>
    )
}

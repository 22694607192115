import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getWhiteLabelingList } from '../../state/user/selectors'

export function AudioVisualizer({ analyser }: { analyser: AnalyserNode | null }) {
    const [frequencyData, setFrequencyData] = useState<Uint8Array>(new Uint8Array(0))
    const [speaking, setSpeaking] = useState(false) // Tracks if the user is speaking
    const { whiteLabeling } = useSelector(getWhiteLabelingList)

    useEffect(() => {
        if (!analyser) return

        const dataArray = new Uint8Array(analyser.frequencyBinCount)
        const updateFrequencyData = () => {
            analyser.getByteFrequencyData(dataArray)

            const avgVolume = dataArray.reduce((sum, val) => sum + val, 0) / dataArray.length

            setSpeaking(avgVolume > 30)

            if (speaking) {
                setFrequencyData(new Uint8Array(dataArray))
            }

            requestAnimationFrame(updateFrequencyData)
        }

        updateFrequencyData()
    }, [analyser, speaking])

    const sticks = Array.from({ length: 24 }, (_, i) => {
        const barHeight = speaking ? frequencyData[i] || 0 : 10
        const maxBarHeight = 60
        const normalizedHeight = (barHeight / 255) * maxBarHeight

        return (
            <rect
                key={i}
                x={24 * i}
                y={100 - normalizedHeight}
                width={10}
                height={normalizedHeight}
                fill={whiteLabeling?.raspberry}
                rx={5}
                ry={5}
            />
        )
    })

    return (
        <svg width="100%" height="100" xmlns="http://www.w3.org/2000/svg">
            <g>{sticks}</g>
        </svg>
    )
}

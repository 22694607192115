import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { JourneyCarIcon, JourneyMoreIcon, PassengerIcon, WheelIcon } from '../common/Svg'
import Clock from '../common/ClockIcon'
import CarpoolingMoreMenuModal from './CarpoolingMoreMenuModal'
import { Carpooling } from '../../types/data'
import { replacePartsOfText } from '../../utils/helpers'
import { formatTime, getHourAndMinute } from '../../utils/date'
import { getUser } from '../../state/user/selectors'

interface IProps {
    active: boolean
    item: Carpooling
}

export default function JourneyCard({ active, item }: IProps) {
    const navigate = useNavigate()
    const { user } = useSelector(getUser)
    const [t] = useTranslation('global')
    const [showModal, setShowModal] = useState<boolean>(false)

    const handleMoreIconClick = (event: React.MouseEvent) => {
        event.stopPropagation()
        setShowModal(true)
    }

    const handleChangeIconClick = (event: React.MouseEvent) => {
        event.stopPropagation()
        navigate('/carpooling-messages')
    }

    return (
        <div key={item?.id}>
            <div onClick={() => active && navigate(`/carpooling-journeys/your-matches/${item?.id}`)}
                className={`w-full ${active ? 'bg-pistachio' : 'bg-white'} mt-2 rounded-2xl px-4 py-3 cursor-pointer`}
                style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                <div className="flex items-center justify-between">
                    <h1 className="font-bold text-14 text-cavolo not-heading-font">
                        {item?.game?.name}
                    </h1>

                    <div onClick={handleMoreIconClick} className="cursor-pointer">
                        <JourneyMoreIcon />
                    </div>
                </div>

                <div className="flex items-center mt-2.5">
                    <div className="mr-1">
                        <Clock {...getHourAndMinute(item?.leavingDate)} />
                    </div>

                    {item?.returningDate && (
                        <Clock {...getHourAndMinute(item?.returningDate)} />
                    )}

                    <h1 className="ml-2 text-cavolo font-normal text-12">
                        {replacePartsOfText(t('carpooling.leaving-at-and-returning-at'), {
                            '[leaving date]': formatTime(item?.leavingDate),
                            '[returning date]': item?.returningDate ? formatTime(item?.returningDate) : t('carpooling.immediately-after-the-event').replace('[event]', user?.company?.travelEventName).toLowerCase(),
                        })}
                    </h1>
                </div>

                <div className="flex items-center mt-2">
                    {item?.travellingAs === 'driver_or_passenger' || item?.travellingAs === 'driver' ? (
                        <div className="mr-1">
                            <WheelIcon />
                        </div>
                    ) : (
                        <div className="w-[19px] h-[19px]" />
                    )}

                    {item?.travellingAs === 'driver_or_passenger' || item?.travellingAs === 'passenger' ? (
                        <PassengerIcon />
                    ) : (
                        <div className="w-[19px] h-[19px]" />
                    )}

                    <h1 className="ml-2 text-cavolo font-normal text-12 ">
                        {item?.travellingAs === 'driver_or_passenger' ? (
                            t('carpooling.happy-to-be-driver-or-passenger')
                        ) : (
                            t(`carpooling.${item?.travellingAs}`)
                        )}
                    </h1>
                </div>

                {!isEmpty(item?.goingWith) && (
                    active && (
                        <div className="flex items-center justify-between mt-2">
                            <div className="flex items-center">
                                <JourneyCarIcon />

                                <div className="w-[19px] h-[19px]" />

                                <h1 className="ml-2 text-raspberry font-normal text-12">
                                    {t('carpooling.going-with-name').replace('[name]', item?.goingWith?.map((i: any) => i.firstName).join(', ') || '')}
                                </h1>
                            </div>

                            <div onClick={handleChangeIconClick} className="cursor-pointer border border-cavolo rounded-md">
                                <h1 className="text-cavolo text-12 font-bold not-heading-font px-[28px] py-1">
                                    {t('carpooling.change')}
                                </h1>
                            </div>
                        </div>
                    )
                )}
            </div>

            <CarpoolingMoreMenuModal
                showModal={showModal}
                setShowModal={setShowModal}
                item={item}
                active={active} />
        </div>
    )
}

import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Layout from '../../components/layout/Layout'
import { fetchCarpoolingChat } from '../../state/travel/actions'
import { chatsList } from '../../state/travel/selectors'
import { CarpoolingChatList } from '../../types/data'
import ChatCard from '../../components/carpooling/ChatCard'

export default function CarpoolingMessages() {
    const dispatch = useDispatch()
    const [t] = useTranslation('global')
    const { chatList } = useSelector(chatsList)

    const latestTimestamp = chatList.reduce((max, item) => (new Date(item?.lastMessageTimestamp) > new Date(max) ? item?.lastMessageTimestamp : max), chatList[0]?.lastMessageTimestamp)

    const sortedChatsList = chatList.sort((a, b) => new Date(b.lastMessageTimestamp).getTime() - new Date(a.lastMessageTimestamp).getTime())

    const unReadChats = sortedChatsList.filter((item) => Number(item.unreadMessagesCount) > 0 && !item.isArchive)
    const readChats = sortedChatsList.filter((item) => Number(item.unreadMessagesCount) === 0 && !item.isArchive)
    const archiveChats = sortedChatsList.filter((item) => item.isArchive)

    const onRestartLoad = useCallback(() => {
        dispatch(fetchCarpoolingChat())
        // last_message_timestamp
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(fetchCarpoolingChat(latestTimestamp))
        }, 10000)

        return () => clearInterval(interval)
    }, [dispatch, latestTimestamp])

    return (
        <Layout showFooter={false}>
            <div className="w-full min-h-screen">
                <div
                    className="pb-[100px] flex flex-col items-center mobile:mt-[54px] mt-[12px] mobile:px-[42px] px-[16px]">
                    <div className="max-w-[600px] w-full">
                        <h1 className="heading-uppercase text-22 tracking-tight font-semibold text-cavolo mt-2">
                            {t('navbar.Messages')}
                        </h1>

                        {unReadChats?.map((item: CarpoolingChatList) => (
                            <ChatCard item={item} unread />
                        ))}

                        {readChats?.map((item: CarpoolingChatList) => (
                            <ChatCard item={item} read />
                        ))}

                        {archiveChats?.map((item: CarpoolingChatList) => (
                            <ChatCard item={item} archive />
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { MapIcon, UserDefaultIcon } from '../common/Svg'
import { formatResponseDate } from '../../utils/date'
import { displayDistanceWithUnit } from '../../utils/helpers'
import { Context } from '../common/BaseContext'
import { getUser, getWhiteLabelingList } from '../../state/user/selectors'

interface IPops{
    item: any
    unread?: boolean
    archive?: boolean
    read?: boolean
}

export default function ChatCard({ item, unread, archive, read }: IPops) {
    const [t] = useTranslation('global')
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const navigate = useNavigate()
    const { distanceUnit } = useContext(Context)
    const { user } = useSelector(getUser)

    return (
        <div onClick={() => navigate(`/carpooling-messages/${item?.id}`, { state: { data: item } })}
            key={item?.id}
            className={`flex items-center justify-between mt-2 w-full rounded-2xl py-3 px-4 ${unread ? 'bg-pistachio' : ''} ${read ? 'bg-white' : ''} ${archive ? 'bg-grey03' : ''} cursor-pointer`}
            style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', backgroundColor: 'rgba(204, 204, 204, 1)' }}>
            <div className={`max-x-mobile:w-[169px] max-mobile:w-2/3 flex items-center ${item?.unreadMessagesCount !== 0 ? '' : 'mr-5'}`}>
                {item?.user?.uploadedImage ? (
                    <img
                        className="rounded-full medium:w-10 medium:h-10 w-[36px] h-[36px] object-cover mx-2.5"
                        src={item?.user?.uploadedImage}
                        alt=""
                    />
                ) : (
                    archive
                        ? <UserDefaultIcon className="w-[38px] h-[38px] mx-2.5" colorPerson={whiteLabeling?.grey01} colorCircle={whiteLabeling.grey03} />
                        : <UserDefaultIcon className="w-[38px] h-[38px] mx-2.5" />
                )}

                <div className="ml-2">
                    <div className="flex items-center justify-between">
                        {!item?.isReportChat ? (
                            <h1 className="text-14 text-cavolo font-bold">
                                {item?.user?.firstName} {item?.user?.lastName[0]}.
                            </h1>
                        ) : (
                            <h1 className="text-14 text-cavolo font-bold">
                                {t('carpooling.report-a-problem')}
                            </h1>
                        )}

                        <h1 className={`${item?.lastMessage?.length > 25 ? '' : 'ml-2'} text-12 font-normal text-cavolo`}>
                            {formatResponseDate(item?.lastMessageTimestamp)}
                        </h1>
                    </div>

                    <h1 className="text-12 font-normal text-cavolo line-clamp-1">
                        {item?.lastMessage === 'you-are-going-together' || item?.lastMessage === 'you-are-not-going-together' ? t(`carpooling.${item.lastMessage}`).replace('[name]', item?.user?.firstName) : item?.lastMessage}
                    </h1>
                </div>
            </div>

            {item?.unreadMessagesCount !== 0 && (
                <div>
                    <div className="flex items-center justify-center w-[22px] h-[22px] bg-avocado rounded-full mx-2.5">
                        <h1 className="text-pistachio text-16 font-medium">
                            {item?.unreadMessagesCount}
                        </h1>
                    </div>
                </div>
            )}

            {(!item?.isReportChat && user.email !== 'carpooling_report@lowr.co') && (
                <div className="flex">
                    <div>
                        <MapIcon />
                    </div>

                    <div className="flex flex-col ml-1">
                        <span className="text-12 font-normal text-cavolo">
                            {item?.locationName?.split(',')[0]}
                        </span>

                        <span className="text-10 text-grey01 font-normal">
                            {displayDistanceWithUnit(Number(item?.distance), distanceUnit, t)} {t('carpooling.away')}
                        </span>
                    </div>
                </div>
            )}
        </div>
    )
}

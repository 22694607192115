import React from 'react'
import cn from 'classnames'

interface RadioProps {
  label: string | number | undefined
  value: string | number
  checked: boolean
  disabled?: boolean
  readOnly?: boolean
  onChange: (value: string | number) => void
  inputClassName?: string
  labelClassName?: string
  containerClassName?: string
  containerStyles?: object
  checkBox?: boolean
  date?: string
}

// eslint-disable-next-line react/function-component-definition
export const Radio: React.FC<RadioProps> = ({ date, checkBox, label, disabled, readOnly = false, value, checked, onChange, containerStyles, containerClassName, inputClassName, labelClassName }) => (
    <div onClick={() => {
        if (!disabled) onChange(value)
    }} className={cn('flex items-center', containerClassName)} style={containerStyles}>
        <input
            readOnly={readOnly}
            type={checkBox ? 'checkbox' : 'radio'}
            className={cn(`cursor-pointer appearance-none w-6 h-6 ${checkBox && 'rounded-sm'} border-rocket transition duration-300 ease-in-out`, inputClassName)}
            value={value}
            checked={checked}
        />

        {date ? (
            <div className="w-full flex justify-between">
                <label className={cn('ml-3', labelClassName)}>
                    {label}
                </label>

                <label className={cn('ml-3', labelClassName)}>
                    {date}
                </label>
            </div>
        ) : (
            <label className={cn('ml-3', labelClassName)}>
                {label}
            </label>
        )}
    </div>
)

import { Dispatch } from 'redux'

import { AgreeGoingTogetherData, Carpooling, CarpoolingChatList,
    CarpoolingJourney,
    CarpoolingMessageData, CarpoolingMessageList,
    CarpoolingReviews,
    IAccommodation,
    ICommuteSave,
    ICommuteStartEnd,
    IGame,
    ILeg,
    ILegStartEnd,
    ILegTransport,
    ILegTransportDetails,
    ILogRouteData,
    IMultipleSaveLeg,
    IMultipleSaveLegs,
    IPlanJourneyData,
    IPrizeDrawEntryData,
    IRemoveOrOffsetData,
    IReviewsData,
    ISavedRoute,
    ISaveLegDetails,
    IStatisticsResponse,
    ISurveyData,
    ITransportCeo2Data,
    ITransportData,
    ITravelArticles,
    ITravelCommuteData,
    ITravelData,
    JourneyTypes } from '../../types/data'
import { AGREE_GOING_TOGETHER_SUCCESS, CLEAR_COMMUTE,
    CLEAR_COMMUTE_START_END,
    CLEAR_LEG_TRANSPORT,
    COMMUTE_SAVE_SUCCESS,
    COMMUTE_START_END_SUCCESS, FETCH_CARPOOLING_CHAT_SUCCESS,
    FETCH_CARPOOLING_JOURNEY_SUCCESS, FETCH_CARPOOLING_MESSAGE_SUCCESS,
    FETCH_CARPOOLING_REVIEWS_SUCCESS,
    FETCH_CARPOOLING_USER_JOURNEY_SUCCESS, FETCH_CLEAR_CARPOOLING_MESSAGE_SUCCESS,
    FETCH_FAVOURITE_ROUTES_SUCCESS,
    FETCH_GAME_LIST_SUCCESS,
    FETCH_JOURNEY_TYPES_SUCCESS,
    FETCH_LOG_JOURNEY_AI_SUCCESS,
    FETCH_MATCHES_JOURNEY_SUCCESS,
    FETCH_RECENT_ROUTES_SUCCESS,
    FETCH_STATISTICS_EMPLOYEE_SUCCESS,
    FETCH_STATISTICS_FAN_SUCCESS,
    FETCH_SURVEYS_SUCCESS,
    FETCH_TRANSPORT_CEO2_SUCCESS,
    FETCH_TRANSPORT_LIST_SUCCESS,
    FETCH_TRANSPORT_MODES_SUCCESS,
    FETCH_TRAVEL_ARTICLES_SUCCESS,
    FETCH_TRAVEL_COMMUTE_DETAIL_SUCCESS,
    FETCH_TRAVEL_CONTENT_BLOCK_SUCCESS,
    FETCH_TRAVEL_ERROR,
    FETCH_TRAVEL_START,
    LEG_START_END_SUCCESS,
    LEG_TRANSPORT_DETAILS_SUCCESS,
    LEG_TRANSPORT_SUCCESS,
    MULTIPLE_SAVE_LEGS_SUCCESS,
    PLAN_JOURNEY_SUCCESS,
    POST_ACCOMMODATION_SUCCESS,
    POST_CARPOOLING_JOURNEY_SUCCESS,
    POST_CARPOOLING_MESSAGES_SUCCESS,
    POST_CARPOOLING_REVIEWS_SUCCESS,
    POST_LOG_ROUTE_SUCCESS,
    POST_SURVEY_SUCCESS,
    POST_TRAVEL_ARTICLE_BOOKMARK_SUCCESS,
    POST_TRAVEL_COMMUTE_SUCCESS,
    PRIZE_DRAW_ENTRY_SUCCESS,
    REMOVE_OR_OFFSET_SUCCESS } from './consts'
import { changeCarpoolingJourney, changeLegDetailsPost, deleteCarpoolingJourney,
    deleteLeg, deleteSavedRoutes, getCarpoolingJourney, getCarpoolingReviews, getCarpoolingUserJourney, getChatItem,
    getGame,
    getJourneyTypes, getMatchesJourney, getMessageItem,
    getRecentRoutes,
    getSavedRoutes,
    getStatisticsEmployee,
    getStatisticsFan, getSurveyItem,
    getTransport,
    getTransportCeo2,
    getTransportModes, getTravelArticles, getTravelCommute,
    getTravelContentBlock, postAccommodationItem, postAgreeGoingTogether, postCarpoolingJourney, postCarpoolingReviews,
    postCommuteSave,
    postCommuteStartEnd, postLastMultiDaySectionItem,
    postLegStartEnd,
    postLegTransport,
    postLegTransportDetails, postLogJourneyAI,
    postLogRoute, postMessagesItem,
    postMultipleSaveLegs,
    postPlanJourney,
    postPrizeDrawEntryItems,
    postRemoveOrOffset,
    postSavedRoutes, postSurveyItem, postTravelArticleBookmarkItem,
    postTravelCommuteItem,
    putTravelCommute } from './api'

const fetchTravelStart = () => ({
    type: FETCH_TRAVEL_START,
})

export const fetchGameSuccess = (response: IGame) => ({
    type: FETCH_GAME_LIST_SUCCESS,
    payload: response,
})

export const fetchTransportSuccess = (response: ITransportData) => ({
    type: FETCH_TRANSPORT_LIST_SUCCESS,
    payload: response,
})

const postTravelCommuteSuccess = (response: ITravelCommuteData) => ({
    type: POST_TRAVEL_COMMUTE_SUCCESS,
    payload: response,
})

const logRouteSuccess = (response: ITravelData) => ({
    type: POST_LOG_ROUTE_SUCCESS,
    payload: response,
})

const fetchRecentRoutesSuccess = (response: ITravelData) => ({
    type: FETCH_RECENT_ROUTES_SUCCESS,
    payload: response,
})

const fetchTransportCeo2Success = (response: ITransportCeo2Data) => ({
    type: FETCH_TRANSPORT_CEO2_SUCCESS,
    payload: response,
})

const fetchSavedRoutesSuccess = (response: ISavedRoute) => ({
    type: FETCH_FAVOURITE_ROUTES_SUCCESS,
    payload: response,
})

const fetchStatisticsFanSuccess = (response: IStatisticsResponse) => ({
    type: FETCH_STATISTICS_FAN_SUCCESS,
    payload: response,
})

const fetchStatisticsEmployeeSuccess = (response: IStatisticsResponse) => ({
    type: FETCH_STATISTICS_EMPLOYEE_SUCCESS,
    payload: response,
})

const fetchJourneyTypesSuccess = (response: JourneyTypes) => ({
    type: FETCH_JOURNEY_TYPES_SUCCESS,
    payload: response,
})

const fetchTransportModesSuccess = (response: JourneyTypes) => ({
    type: FETCH_TRANSPORT_MODES_SUCCESS,
    payload: response,
})

const fetchTravelContentBlockSuccess = (response: JourneyTypes) => ({
    type: FETCH_TRAVEL_CONTENT_BLOCK_SUCCESS,
    payload: response,
})

const commuteStartEndSuccess = (response: ICommuteStartEnd) => ({
    type: COMMUTE_START_END_SUCCESS,
    payload: response,
})

const legTransportSuccess = (response: ILegTransport) => ({
    type: LEG_TRANSPORT_SUCCESS,
    payload: response,
})

export const clearLegTransport = () => ({
    type: CLEAR_LEG_TRANSPORT,
})

export const clearCommuteSave = () => ({
    type: CLEAR_COMMUTE,
})

export const clearCommuteStartEnd = () => ({
    type: CLEAR_COMMUTE_START_END,
})

const legTransportDetailsSuccess = (response: ILegTransportDetails) => ({
    type: LEG_TRANSPORT_DETAILS_SUCCESS,
    payload: response,
})

const legStartEndSuccess = (response: ILegTransportDetails) => ({
    type: LEG_START_END_SUCCESS,
    payload: response,
})

const multipleSaveLegsSuccess = (response: IMultipleSaveLegs) => ({
    type: MULTIPLE_SAVE_LEGS_SUCCESS,
    payload: response,
})

const commuteSaveSuccess = (response: ITravelData) => ({
    type: COMMUTE_SAVE_SUCCESS,
    payload: response,
})

const planJourneySave = (response: ITravelData) => ({
    type: PLAN_JOURNEY_SUCCESS,
    payload: response,
})

const prizeDrawEntrySuccess = (response: IPrizeDrawEntryData) => ({
    type: PRIZE_DRAW_ENTRY_SUCCESS,
    payload: response,
})

const removeOrOffsetSuccess = (response: IRemoveOrOffsetData) => ({
    type: REMOVE_OR_OFFSET_SUCCESS,
    payload: response,
})

const fetchTravelArticlesSuccess = (response: ITravelArticles) => ({
    type: FETCH_TRAVEL_ARTICLES_SUCCESS,
    payload: response,
})

const postTravelArticleBookmarkSuccess = (isBookmark: boolean, bookmarkedId: number | string | undefined) => ({
    type: POST_TRAVEL_ARTICLE_BOOKMARK_SUCCESS,
    payload: { bookmarkedId, isBookmark },
})

const fetchSurveysSuccess = (response: any) => ({
    type: FETCH_SURVEYS_SUCCESS,
    payload: response,
})

const postTravelAccommodationSuccess = (response: ITravelData) => ({
    type: POST_ACCOMMODATION_SUCCESS,
    payload: response,
})

const fetchTravelCommuteDetailSuccess = (response: any) => ({
    type: FETCH_TRAVEL_COMMUTE_DETAIL_SUCCESS,
    payload: response,
})

const postSurveySuccess = (response: ISurveyData) => ({
    type: POST_SURVEY_SUCCESS,
    payload: response,
})

export const fetchLogJourneyAISuccess = (user: {text?: string, audio?: File | null, games?: number[], dates?: string[]}) => ({
    type: FETCH_LOG_JOURNEY_AI_SUCCESS,
    payload: user,
})

const fetchCarpoolingJourneySuccess = (response: Carpooling) => ({
    type: FETCH_CARPOOLING_JOURNEY_SUCCESS,
    payload: response,
})

const fetchCarpoolingUserJourneySuccess = (response: Carpooling) => ({
    type: FETCH_CARPOOLING_USER_JOURNEY_SUCCESS,
    payload: response,
})

const fetchCarpoolingReviewsSuccess = (response: CarpoolingReviews) => ({
    type: FETCH_CARPOOLING_REVIEWS_SUCCESS,
    payload: response,
})

const postCarpoolingReviewsSuccess = (response: any) => ({
    type: POST_CARPOOLING_REVIEWS_SUCCESS,
    payload: response,
})

const postCarpoolingJourneySuccess = (response: CarpoolingJourney) => ({
    type: POST_CARPOOLING_JOURNEY_SUCCESS,
    payload: response,
})

const fetchMatchesJourneySuccess = (response: Carpooling) => ({
    type: FETCH_MATCHES_JOURNEY_SUCCESS,
    payload: response,
})

const postCarpoolingMessagesSuccess = (response: CarpoolingMessageData) => ({
    type: POST_CARPOOLING_MESSAGES_SUCCESS,
    payload: response,
})

const fetchCarpoolingChatSuccess = (response: CarpoolingChatList) => ({
    type: FETCH_CARPOOLING_CHAT_SUCCESS,
    payload: response,
})

const fetchCarpoolingMessageSuccess = (response: CarpoolingMessageList) => ({
    type: FETCH_CARPOOLING_MESSAGE_SUCCESS,
    payload: response,
})

const agreeGoingTogetherSuccess = (response: AgreeGoingTogetherData) => ({
    type: AGREE_GOING_TOGETHER_SUCCESS,
    payload: response,
})

export const clearCarpoolingMessageSuccess = () => ({
    type: FETCH_CLEAR_CARPOOLING_MESSAGE_SUCCESS,
    payload: [],
})

const fetchEnd = () => ({
    type: FETCH_TRAVEL_ERROR,
})

/**
 * Fetch game list
 * @returns
 */
export const fetchGame = () => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await getGame()

        if (response) {
            dispatch(fetchGameSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchGame: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch transport list
 * @returns
 */
export const fetchTransport = () => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await getTransport()

        if (response) {
            dispatch(fetchTransportSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchGame: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchRecentRoutes = () => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await getRecentRoutes()

        if (response) {
            dispatch(fetchRecentRoutesSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchGame: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchTransportCeo2 = () => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await getTransportCeo2()

        if (response) {
            dispatch(fetchTransportCeo2Success(response))
        }
    } catch (e) {
        console.log('ERROR, fetchGame: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchSavedRoutes = () => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await getSavedRoutes()

        if (response) {
            dispatch(fetchSavedRoutesSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchGame: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * post travel commute
 * @returns
 */

export const postTravelCommute = (data: ITravelCommuteData, onSuccess: (response: any) => void) => async (dispatch: Dispatch) => {
    try {
        const response = await postTravelCommuteItem(data)

        if (response) {
            onSuccess(response)
            dispatch(postTravelCommuteSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, postTravelCommute: ', e)
        dispatch(fetchEnd())
    }
}

export const editTravelCommute = (data: any, onSuccess?: (response: ITravelData) => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await putTravelCommute(data)
        dispatch(fetchEnd())

        if (response) onSuccess?.(response)
    } catch (e) {
        console.log('ERROR, editTravelCommute: ', e)
        dispatch(fetchEnd())
    }
}

export const getTravelCommuteDetail = (id: number, onSuccess?: (response: ITravelData) => void) => async (dispatch: Dispatch) => {
    try {
        const response = await getTravelCommute(id)

        if (response) {
            dispatch(fetchTravelCommuteDetailSuccess(response))
            onSuccess?.(response)
        }
    } catch (e) {
        console.log('error: ', e)
        dispatch(fetchEnd())
    }
}

export const saveRoute = (data: ISavedRoute) => async () => {
    try {
        console.log('qq')
        await postSavedRoutes(data)
    } catch (e) {
        console.log('error: ', e)
    }
}

export const logRoute = (data: ILogRouteData, onSuccess: () => void) => async (dispatch: Dispatch) => {
    try {
        const response = await postLogRoute(data)

        if (response) {
            dispatch(logRouteSuccess(response))
            onSuccess()
        }
    } catch (e) {
        console.log('error: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * get statistics fan
 * @returns
 */

export const fetchStatisticsFan = () => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await getStatisticsFan()

        if (response) {
            dispatch(fetchStatisticsFanSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchStatisticsFan: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * get statistics employee
 * @returns
 */

export const fetchStatisticsEmployee = () => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await getStatisticsEmployee()

        if (response) {
            dispatch(fetchStatisticsEmployeeSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchStatisticsFan: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * delete travel leg
 * @returns
 */
export const removeTravelLeg = (legId: number | string | undefined | number[], onContinue?: () => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        await deleteLeg(legId)
        onContinue?.()
        dispatch(fetchEnd())
    } catch (e) {
        console.log('ERROR, removeTravelLeg: ', e)
        dispatch(fetchEnd())
        onContinue?.()
    }
}

export const fetchJourneyTypes = () => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await getJourneyTypes()

        if (response) {
            dispatch(fetchJourneyTypesSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchJourneyTypes: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchTransportModes = () => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await getTransportModes()

        if (response) {
            dispatch(fetchTransportModesSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchJourneyTypes: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchTravelContentBlock = () => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await getTravelContentBlock()

        if (response) {
            dispatch(fetchTravelContentBlockSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchJourneyTypes: ', e)
        dispatch(fetchEnd())
    }
}

export const changeCommuteDestination = (outboundData: any, commute: any, data: any, onSuccess?: (response: ICommuteStartEnd) => void) => (dispatch: Dispatch, getState: any) => {
    const { travelCommuteDetail } = getState().travel

    const requestData = {
        commute,

        startPlaceId: travelCommuteDetail.startPlaceId,
        startPlaceName: travelCommuteDetail.startPlaceName,
        startPlaceLon: travelCommuteDetail.startPlaceLon,
        startPlaceLat: travelCommuteDetail.startPlaceLat,

        endPlaceId: travelCommuteDetail.endPlaceId,
        endPlaceName: travelCommuteDetail.endPlaceName,
        endPlaceLon: travelCommuteDetail.endPlaceLon,
        endPlaceLat: travelCommuteDetail.endPlaceLat,

        journeyType: travelCommuteDetail.journeyType,

        // date: outboundData?.date && travelDate(outboundData.date),
        // game: outboundData?.gameId || outboundData?.game?.id,
        deleteReturnLegs: data.deleteReturnLegs,
        ...data,
    }

    // @ts-ignore
    dispatch(commuteStartEndPost(
        requestData,
        (response: ICommuteStartEnd) => {
            localStorage.setItem('returnTravelData', JSON.stringify({
                ...JSON.parse(localStorage.getItem('returnTravelData') || '{}'),
                startPlaceId: requestData.returnJourneyStartPlaceId,
                startPlaceName: requestData.returnJourneyStartPlaceName,
                startPlaceLon: requestData.returnJourneyStartPlaceLon,
                startPlaceLat: requestData.returnJourneyStartPlaceLat,
                endPlaceId: requestData.returnJourneyEndPlaceId,
                endPlaceName: requestData.returnJourneyEndPlaceName,
                endPlaceLon: requestData.returnJourneyEndPlaceLon,
                endPlaceLat: requestData.returnJourneyEndPlaceLat,
            }))

            localStorage.setItem('travelData', JSON.stringify({
                ...JSON.parse(localStorage.getItem('travelData') || '{}'),
                startPlaceId: requestData.startPlaceId,
                startPlaceName: requestData.startPlaceName,
                startPlaceLon: requestData.startPlaceLon,
                startPlaceLat: requestData.startPlaceLat,
                endPlaceId: requestData.endPlaceId,
                endPlaceName: requestData.endPlaceName,
                endPlaceLon: requestData.endPlaceLon,
                endPlaceLat: requestData.endPlaceLat,
            }))

            onSuccess?.(response)
        },
    ))
}
/**
 * post commute start end
 * @returns
 */

export const commuteStartEndPost = (data: ICommuteStartEnd, onSuccess?: (response: ICommuteStartEnd) => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await postCommuteStartEnd(data)

        if (response) {
            dispatch(commuteStartEndSuccess(response))
            if (typeof onSuccess === 'function') onSuccess(response)
        }
    } catch (e) {
        console.log('commuteStartEnd: ', e)
        dispatch(fetchEnd())
    }
}

export const changeLeg = (id: number, data: ISaveLegDetails, onSuccess?: (response: ILeg) => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await changeLegDetailsPost(id, data)
        dispatch(fetchEnd())

        if (response) {
            if (typeof onSuccess === 'function') onSuccess(response)
        }
    } catch (e) {
        console.log('commuteStartEnd: ', e)
        dispatch(fetchEnd())
    }
}

export const handleCommuteStartEnd = (storedData: any, commuteId: any, startPlace: any, endPlace: any, journeyType: any, onStartEndRequestSuccess?: (response: ICommuteStartEnd) => void) => async (dispatch: Dispatch) => {
    const commuteData = {
        commute: commuteId,

        startPlaceId: startPlace?.placeId,
        startPlaceName: startPlace.name,
        startPlaceLon: startPlace.lon,
        startPlaceLat: startPlace.lat,

        endPlaceId: endPlace.placeId,
        endPlaceName: endPlace.name,
        endPlaceLon: endPlace.lon,
        endPlaceLat: endPlace.lat,

        journeyType,

        // date: storedData?.date && travelDate(storedData.date),
        // game: storedData?.gameId || storedData?.game?.id,
        dates: storedData.date,
        games: storedData.gameIds,
        traveled_with_others_who_didnt_log_count: storedData.traveledWithOthersWhoDidntLogCount,
    }

    localStorage.setItem('travelData', JSON.stringify({
        ...storedData,

        startPlaceId: startPlace.placeId,
        startPlaceName: startPlace.name,
        startPlaceLon: startPlace.lon,
        startPlaceLat: startPlace.lat,
        endPlaceId: endPlace.placeId,
        endPlaceName: endPlace.name,
        endPlaceLon: endPlace.lon,
        endPlaceLat: endPlace.lat,
        journeyType,
    }))

    // @ts-ignore
    dispatch(commuteStartEndPost(commuteData, onStartEndRequestSuccess))
}

/**
 * post leg transport
 * @returns
 */

export const legTransport = (data: ILegTransport, onSuccess: () => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await postLegTransport(data)

        if (response) {
            dispatch(legTransportSuccess(response))
            onSuccess()
        }
    } catch (e) {
        console.log('legTransport: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * post leg transport details
 * @returns
 */

export const legTransportDetails = (data: ILegTransportDetails, onSuccess?: (response: ILegTransportDetails) => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await postLegTransportDetails(data)

        if (response) {
            dispatch(legTransportDetailsSuccess(response))
            onSuccess?.(response)
        }
    } catch (e) {
        console.log('legTransportDetails: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * post leg start end
 * @returns
 */

export const changeLegStartEnd = (data: ILegStartEnd, onSuccess: (response: ILegStartEnd) => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await postLegStartEnd(data)
        onSuccess(response)

        if (response) {
            dispatch(legStartEndSuccess(response))
        }
    } catch (e) {
        console.log('legStartEnd: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * post multiple save legs
 * @returns
 */

export const multipleSaveLegs = (data: { legs: IMultipleSaveLeg[], commute: number | string | undefined, deleteReturnLegs?: boolean }, onSuccess?: (response: IMultipleSaveLegs) => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await postMultipleSaveLegs(data)

        if (response) {
            dispatch(multipleSaveLegsSuccess(response))
            onSuccess?.(response)
        }
    } catch (e) {
        console.log('multipleSaveLegs: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * post commute save
 * @returns
 */

export const commuteSave = (data: ICommuteSave, onSuccess: (response: ITravelData) => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await postCommuteSave(data)

        if (response) {
            dispatch(commuteSaveSuccess(response))
            onSuccess(response)
        }
    } catch (e) {
        console.log('commuteSave: ', e)
        dispatch(fetchEnd())
    }
}

export const getPlanJourney = (data: IPlanJourneyData, onSuccess: () => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await postPlanJourney(data)

        if (response) {
            dispatch(planJourneySave({ ...response, ...data }))
            onSuccess()
        }
    } catch (e) {
        console.log('plan journey error: ', e)
        dispatch(fetchEnd())
    }
}

export const getPrizeDrawEntry = (data: IPrizeDrawEntryData, onSuccess: () => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await postPrizeDrawEntryItems(data)

        if (response) {
            dispatch(prizeDrawEntrySuccess(response))
            onSuccess()
        }
    } catch (e) {
        console.log('plan journey error: ', e)
        dispatch(fetchEnd())
    }
}

export const removeOrOffset = (data: IRemoveOrOffsetData, onSuccess?: () => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await postRemoveOrOffset(data)

        if (response) {
            dispatch(removeOrOffsetSuccess(response))
            onSuccess?.()
        }
    } catch (e) {
        console.log('removeOrOffset: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchTravelArticles = () => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await getTravelArticles()

        if (response) {
            dispatch(fetchTravelArticlesSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchTravelArticles: ', e)
        dispatch(fetchEnd())
    }
}

export const travelArticleBookMark = (id: number | undefined) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await postTravelArticleBookmarkItem(id)
        if (response) {
            dispatch(postTravelArticleBookmarkSuccess(response.isBookmark, id))
        }
    } catch (e) {
        console.log('ERROR, travelArticleBookMark: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchTravelAccommodation = (data: IAccommodation, onSuccess?: () => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await postAccommodationItem(data)

        if (response) {
            dispatch(postTravelAccommodationSuccess(response))
            onSuccess?.()
        }
    } catch (e) {
        console.log('ERROR, fetchTravelAccommodation: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchLastMultiDaySection = (data: IAccommodation, id: number | string, onSuccess: () => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await postLastMultiDaySectionItem(data, id)

        if (response) {
            dispatch(postTravelAccommodationSuccess(response))
            onSuccess()
        }
    } catch (e) {
        console.log('ERROR, fetchTravelAccommodation: ', e)
        dispatch(fetchEnd())
    }
}

export const postSurvey = (data: ISurveyData, onSuccess: () => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await postSurveyItem(data)

        if (response) {
            dispatch(postSurveySuccess(response))
            onSuccess()
        }
    } catch (e) {
        console.log('ERROR, postSurvey: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchSurveys = (id: number | string) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await getSurveyItem(id)
        if (response) {
            dispatch(fetchSurveysSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchSurveys: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * delete saved routes
 * @returns
 */
export const removeSavedRoutes = (id: number | string | undefined, onSuccess: () => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())

        await deleteSavedRoutes(id)

        dispatch(fetchEnd())

        onSuccess()
    } catch (e) {
        console.log('ERROR, removeSavedRoutes: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * Fetch log journey with AI
 * @returns
 */
export const fetchLogJourneyAI = (data: {text?: string, audio?: File | null, previousUserInput?: string, errorMessage?: string, dates?: string[], games?: number[]}, onSuccess?: (response: any) => void, onError?: (e: any) => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await postLogJourneyAI(data)

        if (response) {
            dispatch(fetchLogJourneyAISuccess(response))
            onSuccess?.(response)
        }
    } catch (e) {
        console.log('ERROR, fetchUserAvatarUpdate: ', e)
        onError?.(e)
        dispatch(fetchEnd())
    }
}

export const fetchCarpoolingJourney = (active: boolean) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await getCarpoolingJourney(active)

        if (response) {
            dispatch(fetchCarpoolingJourneySuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchCarpoolingJourney: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * delete carpooling journey
 * @returns
 */
export const removeCarpoolingJourney = (id: number | undefined, onSuccess: () => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())

        await deleteCarpoolingJourney(id)

        dispatch(fetchEnd())
        onSuccess()
    } catch (e) {
        console.log('ERROR, removeCarpoolingJourney: ', e)
        dispatch(fetchEnd())
    }
}

export const fetchCarpoolingUserJourney = () => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await getCarpoolingUserJourney()

        if (response) {
            dispatch(fetchCarpoolingUserJourneySuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchCarpoolingJourney: ', e)
        dispatch(fetchEnd())
    }
}
export const fetchCarpoolingReviews = (reviewType: string, userId: number | string) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await getCarpoolingReviews(reviewType, userId)

        if (response) {
            dispatch(fetchCarpoolingReviewsSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchCarpoolingJourney: ', e)
        dispatch(fetchEnd())
    }
}

export const createCarpoolingReviews = (data: IReviewsData, onSuccess: () => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await postCarpoolingReviews(data)

        if (response) {
            dispatch(postCarpoolingReviewsSuccess(response))
            onSuccess()
        }
    } catch (e) {
        console.log('ERROR, fetchCarpoolingJourney: ', e)
        dispatch(fetchEnd())
    }
}

export const createCarpoolingJourney = (data: CarpoolingJourney, onSuccess: (response: CarpoolingJourney) => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await postCarpoolingJourney(data)

        if (response) {
            dispatch(postCarpoolingJourneySuccess(response))
            onSuccess(response)
        }
    } catch (e) {
        console.log('ERROR, fetchCarpoolingJourney: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * edit carpooling journey
 * @returns
 */
export const editCarpoolingJourney = (id: number | undefined, data: CarpoolingJourney, onSuccess?: (response: CarpoolingJourney) => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await changeCarpoolingJourney(id, data)
        dispatch(fetchEnd())

        if (response) onSuccess?.(response)
    } catch (e) {
        console.log('ERROR, editCarpoolingJourney: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * fetch matches journey
 * @returns
 */
export const fetchMatchesJourney = (id: string | undefined) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await getMatchesJourney(id)
        dispatch(fetchEnd())

        if (response) {
            dispatch(fetchMatchesJourneySuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchMatchesJourney: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * post carpooling messages
 * @returns
 */
// @ts-ignore
export const createCarpoolingMessages = (data?: CarpoolingMessageData, onSuccess: (response: any) => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await postMessagesItem(data)
        dispatch(fetchEnd())

        if (response) {
            dispatch(postCarpoolingMessagesSuccess(response))
            onSuccess(response)
        }
    } catch (e) {
        console.log('ERROR, fetchMatchesJourney: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * fetch carpooling chat
 * @returns
 */
export const fetchCarpoolingChat = (lastMessageTimestamp?: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await getChatItem(lastMessageTimestamp)
        dispatch(fetchEnd())

        if (response) {
            dispatch(fetchCarpoolingChatSuccess(response))
        }
    } catch (e) {
        console.log('ERROR, fetchMatchesJourney: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * fetch carpooling message
 * @returns
 */
export const fetchCarpoolingMessage = (chat: string | undefined, lastMessageId?: number, onSuccess?: () => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await getMessageItem(chat, lastMessageId)
        dispatch(fetchEnd())

        if (response) {
            dispatch(fetchCarpoolingMessageSuccess(response))
            onSuccess?.()
        }
    } catch (e) {
        console.log('ERROR, fetchMatchesJourney: ', e)
        dispatch(fetchEnd())
    }
}

/**
 * agree going together
 * @returns
 */
export const agreeGoingTogether = (data: AgreeGoingTogetherData, onSuccess: () => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(fetchTravelStart())
        const response = await postAgreeGoingTogether(data)
        dispatch(fetchEnd())

        if (response) {
            dispatch(agreeGoingTogetherSuccess(response))
            onSuccess()
        }
    } catch (e) {
        console.log('ERROR, fetchMatchesJourney: ', e)
        dispatch(fetchEnd())
    }
}

import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
// import { AppleIcon, GoogleIcon, MicrosoftIcon } from './common/Svg'
import { useGoogleLogin } from '@react-oauth/google'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { GoogleIcon } from './common/Svg'
import { Context } from './common/BaseContext'
import { googleLoginApi } from '../state/user/api'
import { loginSuccess } from '../state/user/actions'
import { objectToUrlParams, useQueryParams } from '../hooks/queryString'
import { getLoginConfig } from '../state/user/selectors'

interface IProps{
    login: boolean
}

export default function SocialLogin({ login }: IProps) {
    const { screenWidth } = useContext(Context)
    const [t] = useTranslation('global')
    const params = useQueryParams()
    const navigate = useNavigate()
    const { loginConfig } = useSelector(getLoginConfig)

    const dispatch = useDispatch()
    const cleanQueryString = objectToUrlParams({ ...params, to: undefined })

    const googleLogin = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            dispatch(googleLoginApi({ accessToken: tokenResponse.access_token, accessTokenType: tokenResponse.token_type }, (authorizeData) => {
                dispatch(loginSuccess(authorizeData))
                navigate(`${params.to || '/'}?${cleanQueryString}`)
            }))
        },
    })

    const enabledSocialProviders = loginConfig?.enabledSocialProviders?.map((i) => i.name)

    const data = [
        {
            id: 1,
            name: 'google',
            icon: <GoogleIcon />,
            title: t('login.continue-with-google'),
            onClick: () => googleLogin(),
        },
        // {
        //     id: 2,
        //     icon: <AppleIcon />,
        //     title: t('login.continue-with-apple'),
        // },
        // {
        //     id: 3,
        //     icon: <MicrosoftIcon />,
        //     title: t('login.continue-with-microsoft'),
        // },
    ].filter((i) => enabledSocialProviders?.includes(i.name))

    if (data.length === 0) return <div />

    return (
        <div>
            {data?.map((item) => (
                <div
                    onClick={item.onClick}
                    key={item?.id}
                    className={`flex items-center justify-center cursor-pointer mb-[15px] ${login && screenWidth > 460 ? 'w-[343px]' : !login ? 'w-full' : 'w-[272px]'}  h-[50px] border border-black rounded-md`}>
                    <div className="mr-2.5">
                        {item.icon}
                    </div>

                    <h1 className="font-semibold text-14 text-rocket">
                        {item?.title}
                    </h1>
                </div>
            ))}

            <div className="flex items-center my-[25px]">
                <div className={`${login && screenWidth > 460 ? 'w-[149px]' : !login ? 'w-full' : 'w-[121.5px]'} border-b border-b-grey01`} />

                <h1 className="font-semibold text-black text-14 mx-[15px]">
                    {t('login.or')}
                </h1>

                <div className={`${login && screenWidth > 460 ? 'w-[149px]' : !login ? 'w-full' : 'w-[121.5px]'} border-b border-b-grey01`} />
            </div>
        </div>
    )
}

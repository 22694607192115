import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import Layout from '../../components/layout/Layout'
import { ArrowTravel, RateStarIcon } from '../../components/common/Svg'
import { getUser, getWhiteLabelingList } from '../../state/user/selectors'
import Input from '../../components/common/Input'
import SecondButton from '../../components/common/SecondButton'
import { required } from '../../utils/validators'
import { createCarpoolingReviews, fetchCarpoolingReviews } from '../../state/travel/actions'
import { replacePartsOfText } from '../../utils/helpers'

export default function CarpoolingCreateReviews() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector(getUser)
    const location = useLocation()
    const [t] = useTranslation('global')
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const [rating, setRating] = useState(0)
    const [error, setError] = useState('')
    const maxRating = [1, 2, 3, 4, 5]

    const onSubmit = (value: { description: string }) => {
        if (value?.description?.length < 30) {
            setError('Reviews must be at least 30 characters long')
        } else {
            setError('')
            dispatch(createCarpoolingReviews({
                journey: location?.state?.id,
                rating,
                comment: value?.description,
            }, () => {
                dispatch(fetchCarpoolingReviews('about_others', user?.id))
                navigate('/carpooling-reviews')
            }))
        }
    }

    return (
        <Layout showFooter={false}>
            <div className="w-full min-h-screen">
                <div
                    className="pb-[100px] flex flex-col items-center mobile:mt-[54px] mt-[12px] mobile:px-[42px] px-[16px]">
                    <div className="max-w-[600px] w-full">
                        <Formik initialValues={{ description: '' }} onSubmit={onSubmit}>
                            <Form>
                                <div className="flex items-center">
                                    <div onClick={() => navigate('/carpooling-reviews')} className="flex justify-end cursor-pointer mr-1">
                                        <ArrowTravel width={22} height={22} />
                                    </div>

                                    <h1 className="heading-uppercase text-22 tracking-tight font-semibold text-cavolo">
                                        {t('carpooling.leave-a-review')}
                                    </h1>
                                </div>

                                <h1 className="mt-2 font-normal text-14 text-cavolo">
                                    {replacePartsOfText(t('carpooling.how-was-your-journey-to-event-name-with-name'), {
                                        '[event-name]': location?.state?.game?.name,
                                        '[name]': location.state?.goingWith?.map((i: any) => i.firstName).join(', ') || '',
                                    })}
                                </h1>

                                <div className="flex mt-2">
                                    {maxRating.map((item) => (
                                        <div key={item}>
                                            <button onClick={() => setRating(item)} className="mr-2">
                                                {item <= rating ? (
                                                    <RateStarIcon color={whiteLabeling.rocket} width={23} height={23} />
                                                ) : (
                                                    <RateStarIcon color={whiteLabeling.white} width={23} height={23} />
                                                )}
                                            </button>
                                        </div>
                                    ))}
                                </div>

                                <h1 className="mt-2 font-normal text-14 text-cavolo">
                                    {t('carpooling.please-tell-us-the-reason-for-your-rating')}
                                </h1>

                                <Input
                                    validate={required(t)}
                                    placeholder={t('carpooling.provide-details-here')}
                                    type="textarea"
                                    name="description"
                                    className="h-[197px] mt-2 mb-[0px] border border-avocado"
                                    component="textarea"
                                />

                                {error && (
                                    <div className="text-[#BD4823] tracking-[-0.02em] leading-[140%] text-[15px] font-semibold">
                                        {t('carpooling.reviews-must-be-at-least-30-characters-long')}
                                    </div>
                                )}

                                <SecondButton
                                    text={t('global.submit')}
                                    className="mt-2 w-full h-[48px] font-bold bg-rocket rounded-md text-16 text-pistachio"
                                />

                                <SecondButton
                                    onClick={() => navigate('/carpooling-reviews')}
                                    text={t('global.cancel')}
                                    className="mt-2 w-full h-[48px] font-bold bg-white border border-cavolo rounded-md text-16 text-cavolo"
                                />
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

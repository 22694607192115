import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Layout from '../../components/layout/Layout'
import SecondButton from '../../components/common/SecondButton'
import { TravelTreeBigEllipse, TravelTreeSmallEllipse } from '../../components/common/Svg'
import { getUser } from '../../state/user/selectors'

export default function Carpooling() {
    const navigate = useNavigate()
    const { user } = useSelector(getUser)
    const [t] = useTranslation('global')

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    return (
        <Layout showFooter={false}>
            <div className="w-full min-h-screen">
                <div
                    className="pb-[100px] flex flex-col items-center mobile:mt-[54px] mt-[12px] mobile:px-[42px] px-[16px]">
                    <div className="max-w-[600px] w-full">
                        <h1 className="heading-uppercase text-22 tracking-tight font-semibold text-cavolo mt-2">
                            {t('carpooling.car-pooling')}
                        </h1>

                        <h1 className="font-normal not-heading-font text-black text-14 mt-2">
                            {t('carpooling.share-a-journey-with-another-fan-to-save-the-environment-and-save-money').replace('[fan]', user?.company?.impactFanText || '')}
                        </h1>

                        <div className="mt-5">
                            <div className="flex items-end">
                                <div>
                                    <TravelTreeBigEllipse />
                                </div>

                                <h1 className="text-14 text-cavolo font-bold ml-[19px]">
                                    1. {t('carpooling.add-your-journey')}
                                </h1>
                            </div>

                            <div className="border-spinach border-l-[10px] ml-3 pl-5 mt-[-8px] py-[15px]">
                                <div className="flex">
                                    <h1 className="ml-2.5 font-normal text-grey01 text-14">
                                        {t('carpooling.add-your-journey-description')}
                                    </h1>
                                </div>

                                <div>
                                    <div className="flex relative items-center mt-[42px]">
                                        <div className="absolute left-[-33.7px]">
                                            <TravelTreeSmallEllipse />
                                        </div>

                                        <h1 className="text-14 text-cavolo font-bold ml-2.5">
                                            2. {t('carpooling.view-matches')}
                                        </h1>
                                    </div>

                                    <h1 className="ml-2.5 font-normal text-grey01 text-14 mt-2">
                                        {t('carpooling.view-matches-description')}
                                    </h1>
                                </div>

                                <div className="mb-[28px]">
                                    <div className="flex relative items-center mt-[42px]">
                                        <div className="absolute left-[-33.7px]">
                                            <TravelTreeSmallEllipse />
                                        </div>

                                        <h1 className="text-14 text-cavolo font-bold ml-2.5">
                                            3. {t('carpooling.agree-details')}
                                        </h1>
                                    </div>

                                    <h1 className="ml-2.5 font-normal text-grey01 text-14 mt-2">
                                        {t('carpooling.agree-details-description')}
                                    </h1>
                                </div>
                            </div>

                            <div className="flex mt-[-2px]">
                                <div>
                                    <TravelTreeBigEllipse />
                                </div>

                                <div className="flex flex-col">
                                    <h1 className="text-14 text-cavolo font-bold ml-[19px]">
                                        4. {t('carpooling.travel-together')}
                                    </h1>

                                    <h1 className="ml-[19px] font-normal text-grey01 text-14 mt-2">
                                        {t('carpooling.travel-together-description')}
                                    </h1>
                                </div>
                            </div>
                        </div>

                        <SecondButton
                            onClick={() => navigate('/carpooling-journeys')}
                            text={t('carpooling.get-started')}
                            className="mt-[23px] w-full h-[48px] font-bold bg-rocket rounded-md text-16 text-pistachio"
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

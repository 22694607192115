import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { IWriteRecipeReviewData } from '../../types/data'
import SecondButton from '../common/SecondButton'
import Input from '../common/Input'
import { getRecipe } from '../../state/recipe/selectors'
import { fetchRecipeComments, fetchRecipeDetail, postCommentCategory } from '../../state/recipe/actions'
import PointModal from '../common/PointModal'
import { fetchUser } from '../../state/user/actions'

export default function RecipeWriteReviewModal() {
    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const { recipeDetail: recipe } = useSelector(getRecipe)
    const [showModalPoint, setShowModalPoint] = useState(false)
    const [t] = useTranslation('global')
    const initialValues: IWriteRecipeReviewData = {
        recipe: recipe?.id || 0,
        body: '',
    }
    const onSubmit = async (data: IWriteRecipeReviewData) => {
        setLoading(true)
        await dispatch(
            postCommentCategory(data, (response) => {
                dispatch(fetchUser)
                // eslint-disable-next-line no-unused-expressions
                !recipe?.hasCommented ? setShowModalPoint(true) : response ? setShowModal(false) : null
            }),
        )
        dispatch(fetchRecipeComments({ recipe: recipe?.id, page: 1 }))
        dispatch(fetchRecipeDetail(Number(recipe?.id)))
        setLoading(false)
        setShowModal(false)
        // eslint-disable-next-line
    }

    return (
        <>
            <button onClick={() => setShowModal(true)} className="font-semibold tracking-tight underline underline-offset-2 text-rocket ml-3 text-14">
                {t('global.write-a-review')}
            </button>

            {showModal ? (
                <div>
                    <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                        <div onClick={() => setShowModal(false)} className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                        <div className="flex items-center justify-center min-h-screen">
                            <div className="relative xs-mobile:w-[423px] w-11/12 bg-white mobile:p-10 p-6 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                                <h1 className="text-12 font-normal text-grey01">
                                    {recipe?.title}
                                </h1>

                                <h1 className="text-26 font-medium font tracking-tight text-black mb-4">
                                    {t('lower.review-this-recipe')}
                                </h1>

                                <Formik initialValues={initialValues} onSubmit={onSubmit}>
                                    <Form>
                                        <div className="mt-6">
                                            <h1 className="text-14 tracking-tight font-medium text-black font">
                                                {t('lower.add-some-details')}
                                            </h1>

                                            <Input
                                                type="textarea"
                                                name="body"
                                                className="h-28"
                                                component="textarea"
                                                placeholder={t('lower.what-did-you-like-or-dislike-about-this-recipe')} />
                                        </div>

                                        <div className="flex mt-8">
                                            <SecondButton
                                                text={t('global.cancel')} onClick={() => setShowModal(false)}
                                                className="mobile:w-[136px] h-[48px] w-[94px] mr-6 font-semibold bg-mint border-2 border-[#417339] rounded-md text-14 text-rocket hover:text-spinach hover:ease-in hover:duration-200 hover:bg-[#A7D49D]" />

                                            <SecondButton
                                                text={t('lower.submit-your-review')}
                                                loading={loading}
                                                className="w-[183px] h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200" />
                                        </div>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            <PointModal
                isShown={showModalPoint}
                onClose={() => setShowModalPoint(false)}
                point={5}
                description={t('lower.just-for-reviewing-you’ve-earned')} />
        </>
    )
}

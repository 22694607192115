import React from 'react'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { travelSelector } from '../../state/travel/selectors'
import { getDates } from '../../utils/date'
import { getWhiteLabelingList } from '../../state/user/selectors'
import { colourStyles } from '../../utils/organisationUnit'

interface IProps {
    handleSelectChange: (selected: any) => void
    defaultGameId?: number | string
    carpooling?: boolean
}

export default function Dropdown({ handleSelectChange, defaultGameId, carpooling = false }: IProps) {
    const { gameList } = useSelector(travelSelector)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const [t] = useTranslation('global')

    const options = gameList
        .filter((item) => {
            if (carpooling) {
                const gameDate = new Date(item.date)
                const today = new Date()
                today.setHours(0, 0, 0, 0)
                return gameDate > today
            }
            return !item.isDisabled
        })
        .map((item) => ({
            id: item.id,
            value: String(item.id),
            label: (
                <div className="flex justify-between">
                    <span className="font-bold not-heading-font text-14">{item.name}</span>
                    <p className="font-normal text-14">{getDates(item.date)}</p>
                </div>
            ),
            color: whiteLabeling?.black || 'rgba(0, 0, 0, 1)',
            isDisabled: !carpooling && item.isDisabled,
            item,
        }))

    const selectedOption = options.find((option) => option.id === defaultGameId)

    return (
        <Select
            value={selectedOption || null}
            maxMenuHeight={200}
            options={options}
            placeholder={t('log.select-an-option')}
            styles={colourStyles(whiteLabeling)}
            isSearchable={false}
            getOptionValue={(option) => String(option.id)}
            // @ts-ignore
            getOptionLabel={(option) => option.label}
            onChange={handleSelectChange}
        />
    )
}

import { StylesConfig } from 'react-select'
import { IOrganisationUnits } from '../types/data'

export interface ColourOption {
  value: string;
  label: string | JSX.Element;
  color?: string;
  id?: number | string
}

export const createColourStyles = (whiteLabeling: any): StylesConfig<ColourOption> => ({
    dropdownIndicator: (base: any) => ({
        ...base,
        color: whiteLabeling?.rocket,
        cursor: 'pointer',
    }),
    option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        cursor: 'pointer',
        color: isSelected
            ? whiteLabeling?.grey01
            : (whiteLabeling?.grey01 && isFocused ? whiteLabeling?.marrow : whiteLabeling?.grey01),
        backgroundColor: isSelected
            ? whiteLabeling?.white
            : isFocused
                ? whiteLabeling?.mint
                : whiteLabeling?.white,
    }),
    input: (styles) => ({ ...styles }),
    control: (styles) => ({ ...styles, padding: 7, borderRadius: 10, borderColor: whiteLabeling?.avocado }),
    placeholder: (styles) => ({ ...styles }),
    indicatorSeparator: () => ({ display: 'none' }),
})

export const createDictionaryWithParents = (item: IOrganisationUnits | undefined) => {
    if (!item) return {}

    const dictionary: Record<number, IOrganisationUnits> = {}

    function addToDictionary(currentItem: IOrganisationUnits | null): void {
        if (!currentItem) return

        // Add the current item to the dictionary with its level as the key
        dictionary[currentItem?.level || 0] = currentItem

        // Recursively add the parent to the dictionary
        addToDictionary(currentItem?.parent)
    }

    addToDictionary(item)
    return dictionary
}

export const colourStyles = (whiteLabeling: any): StylesConfig<ColourOption> => ({
    dropdownIndicator: (base: any) => ({
        ...base,
        color: whiteLabeling?.rocket,
        cursor: 'pointer',
    }),
    // @ts-ignore
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
        ...styles,
        cursor: 'pointer',
        color: isSelected
            ? whiteLabeling?.white
            : (whiteLabeling?.grey01 && isFocused
                ? whiteLabeling?.marrow
                : whiteLabeling?.grey01),
        backgroundColor: isDisabled
            ? undefined
            : isSelected
                ? whiteLabeling?.spinach
                : isFocused
                    ? whiteLabeling?.mint
                    : whiteLabeling?.white,
    }),
    input: (styles) => ({ ...styles }),
    control: (styles) => ({ ...styles, padding: 7, borderRadius: 10, borderColor: whiteLabeling.avocado }),
    placeholder: (styles) => ({ ...styles }),
    indicatorSeparator: () => ({ display: 'none' }),
})

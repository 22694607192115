import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import Select, { GroupBase } from 'react-select'
import Layout from '../../components/layout/Layout'
import SecondButton from '../../components/common/SecondButton'
import { ArrowIcon } from '../../components/common/Svg'
import { formatDateJourney } from '../../utils/date'
import { getUser, getWhiteLabelingList } from '../../state/user/selectors'
import { ColourOption, colourStyles } from '../../utils/organisationUnit'
import { Radio } from '../../components/common/Radio'
import JourneyInfoModal from '../../components/carpooling/JourneyInfoModal'
import { options, preferenceOptions, preferencesData } from '../../utils/carpooling'
import { createCarpoolingJourney } from '../../state/travel/actions'

export default function CarpoolingJourneyDetail() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector(getUser)
    const [t] = useTranslation('global')
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const [leavingDate, setLeavingDate] = useState<Date>()
    const [returningDate, setReturningDate] = useState<Date>()
    const [isOpenLeavingDate, setIsOpenLeavingDate] = useState<boolean>(false)
    const [isOpenReturningDate, setIsOpenReturningDate] = useState<boolean>(false)
    const [returningOption, setReturningOption] = useState<string>('')
    const [flexibility, setFlexibility] = useState<string>('')
    const [preferences, setPreferences] = useState({ smoking: 'No', verified: 'Don\'t mind', food: 'Don\'t mind', pets: 'Don\'t mind' })
    const [showInfoModal, setShowInfoModal] = useState<boolean>(false)
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

        const handlePopState = () => {
            setShowInfoModal(true)
            window.history.pushState(null, document.title, window.location.pathname)
        }

        window.addEventListener('popstate', handlePopState)
        window.history.pushState(null, document.title, window.location.pathname)
        return () => {
            window.removeEventListener('popstate', handlePopState)
        }
    }, [dispatch])

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            event.preventDefault()
            event.returnValue = ''
        }

        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])

    const handleChange = (key: string, value: string) => {
        setPreferences((prev) => ({
            ...prev,
            [key]: value,
        }))
    }

    const languageDataString = localStorage.getItem('language')
    const languageData = languageDataString ? JSON.parse(languageDataString) : 'en'

    const handleChangeLeavingDate = (date: Date) => {
        setLeavingDate(date)
    }

    const handleChangeReturningDate = (date: Date) => {
        setReturningDate(date)
    }

    const handleReturningOption = () => {
        setReturningOption(t('carpooling.immediately-after-the-event').replace('[event]', user?.company?.travelEventName))
        setIsOpenReturningDate(false)
    }

    useEffect(() => {
        if (returningDate) {
            setReturningOption('')
        }
    }, [returningDate])

    useEffect(() => {
        if (returningOption) {
            setReturningDate(undefined)
        }
    }, [returningOption])

    const handleSelectFlexibility = (selectedOption: any) => {
        setFlexibility(selectedOption?.value)
    }

    const saveJourneyData = () => {
        const journeyDetail = {
            leavingDate,
            returningDate,
            returningOption,
            flexibility,
            preferences,
        }
        sessionStorage.setItem('carpoolingJourneyDetail', JSON.stringify(journeyDetail))
    }

    const savedJourneyDetail = sessionStorage.getItem('carpoolingJourneyDetail')
    const parsedJourneyDetail = savedJourneyDetail ? JSON.parse(savedJourneyDetail) : ''

    const savedJourneyData = sessionStorage.getItem('carpoolingJourneyData')
    const parsedJourneyData = savedJourneyData ? JSON.parse(savedJourneyData) : ''

    useEffect(() => {
        if (savedJourneyDetail) {
            setLeavingDate(parsedJourneyDetail?.leavingDate)
            setReturningDate(parsedJourneyDetail?.returningDate)
            setReturningOption(parsedJourneyDetail?.returningOption)
            setFlexibility(parsedJourneyDetail?.flexibility)
            setPreferences(parsedJourneyDetail?.preferences)
        }
        // eslint-disable-next-line
    }, [])

    const onSubmitLeavingDate = () => {
        setIsOpenLeavingDate(!isOpenLeavingDate)
        if (parsedJourneyDetail?.leavingDate) {
            setLeavingDate(parsedJourneyDetail?.leavingDate ? new Date(parsedJourneyDetail?.leavingDate) : undefined)
        }
    }

    const onSubmitReturningDate = () => {
        setIsOpenReturningDate(!isOpenReturningDate)
        if (parsedJourneyDetail?.returningDate) {
            setReturningDate(parsedJourneyDetail?.returningDate ? new Date(parsedJourneyDetail?.returningDate) : undefined)
        }
    }

    const clearJourneyData = () => {
        sessionStorage.removeItem('carpoolingJourneyData')
        sessionStorage.removeItem('carpoolingJourneyDetail')
    }

    const selectProps = {
        defaultValue: options(t).find(
            (option) => option.value === parsedJourneyDetail?.flexibility,
        ),
    }

    const onSubmit = () => {
        setIsSubmitted(true)

        if (flexibility !== '' && leavingDate && (returningOption !== '' || returningDate)) {
            dispatch(createCarpoolingJourney({
                game: parsedJourneyData?.gameDate?.id,
                startPlaceId: parsedJourneyData?.startPlace?.placeId,
                startPlaceName: parsedJourneyData?.startPlace?.name,
                startPlaceLon: parsedJourneyData?.startPlace?.lon,
                startPlaceLat: parsedJourneyData?.startPlace?.lat,
                travellingAs: parsedJourneyData?.travellingAs?.value,
                leavingDate,
                returningDate,
                returningImmediately: !!returningOption,
                flexibility,
                smoking: preferences?.smoking?.toLowerCase()?.toLowerCase().replace(/\s+/g, '_'),
                food: preferences?.food?.toLowerCase()?.toLowerCase().replace(/\s+/g, '_'),
                pets: preferences?.pets?.toLowerCase()?.toLowerCase().replace(/\s+/g, '_'),
            }, (response) => {
                clearJourneyData()
                navigate(`/carpooling-journeys/your-matches/${response?.id}`)
            }))
        }
    }

    return (
        <Layout showFooter={false}>
            <div className="w-full min-h-screen">

                <style>
                    {`
                        .disable-radio-style input[type='radio'] {
                            outline: none;
                            appearance: none;
                            width: 24px;
                            height: 24px;
                            padding: 3px;
                        }
                        
                        .disable-radio-style input[type="radio"]:checked {
                            width: 24px;
                            height: 24px;                               
                            background: ${(whiteLabeling as any)?.grey01} content-box;
                        }
                        
                        .btnd:active,
                        .btnd:focus,
                        .btnd:focus:active {
                            background-image: none;
                            outline: 0;
                            box-shadow: none;
                        }
                        
                        .radio-style input[type='radio'] {
                            outline: none;
                            appearance: none;
                            width: 24px;
                            height: 24px;
                            padding: 3px;
                        }
                        
                        .radio-style input[type="radio"]:checked {
                            width: 24px;
                            height: 24px;
                            background: ${(whiteLabeling as any)?.rocket} content-box;
                        }
                    `}
                </style>

                <div
                    className="pb-[100px] flex flex-col items-center mobile:mt-[54px] mt-3 mobile:px-[42px] px-4">
                    <div className="max-w-[600px] w-full">
                        <h1 className="heading-uppercase text-22 tracking-tight font-semibold text-cavolo mt-2">
                            {t('carpooling.journey-details')}
                        </h1>

                        <h1 className="font-normal not-heading-font text-cavolo text-14 mt-2">
                            {t('carpooling.tell-us-more-about-your-journey-so-we-can-find-you-the-best-matches')}
                        </h1>

                        <h1 className="font-medium not-heading-font text-cavolo text-18 mt-2">
                            {t('carpooling.leaving-date-and-time')}
                        </h1>

                        <div onClick={onSubmitLeavingDate}
                            className="flex items-center justify-between h-[48px] border border-avocado rounded-lg cursor-pointer mt-1.5 pr-4">
                            <h1 className="ml-[14px]">
                                {!leavingDate ? (
                                    <h1 className="text-grey01">Select date and
                                        time
                                    </h1>
                                ) : (formatDateJourney(leavingDate || new Date()))}
                            </h1>

                            <ArrowIcon />
                        </div>

                        {isOpenLeavingDate && (
                            <div className="rounded-[4px] border w-full mt-2 pb-4"
                                style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.10)' }}>
                                <div className="flex justify-center mt-4">
                                    <DatePicker
                                        selected={leavingDate}
                                        onChange={handleChangeLeavingDate}
                                        inline
                                        dateFormat="dd/mm/yyyy"
                                        locale={languageData}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={10}
                                        timeCaption="Time"
                                    />
                                </div>
                            </div>
                        )}

                        <div>
                            <h1 className="font-medium not-heading-font text-cavolo text-18 mt-4 mb-1.5">
                                {t('carpooling.returning-date-and-time')}
                            </h1>
                        </div>

                        <div onClick={onSubmitReturningDate}
                            className="flex items-center justify-between h-[48px] border border-avocado rounded-lg cursor-pointer mt-1.5 pr-4">

                            {returningDate ? (
                                <h1 className="ml-[14px] text-cavolo text-16 font-normal">
                                    {formatDateJourney(returningDate || new Date())}
                                </h1>
                            ) : returningOption ? (
                                <h1 className="ml-[14px] text-cavolo text-16 font-normal">
                                    {returningOption}
                                </h1>
                            ) : (
                                <h1 className="text-grey01 text-16 font-normal ml-[14px]">
                                    {t('log.select-an-option')}
                                </h1>
                            )}

                            <ArrowIcon />
                        </div>

                        {isOpenReturningDate && (
                            <div className="rounded-[4px] border w-full mt-2 pb-4"
                                style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.10)' }}>
                                <div onClick={handleReturningOption}
                                    className={`flex items-center h-[48px] border-t border-b w-full mt-1 pl-[14px] ${returningDate && 'bg-white text-cavolo'} ${returningOption && 'bg-spinach text-white'} cursor-pointer hover:bg-spinach hover:text-white`}>
                                    <h1>
                                        {t('carpooling.immediately-after-the-event').replace('[event]', user?.company?.travelEventName)}
                                    </h1>
                                </div>

                                <div className="flex justify-center mt-4">
                                    <DatePicker
                                        selected={returningDate}
                                        onChange={handleChangeReturningDate}
                                        inline
                                        dateFormat="dd/mm/yyyy"
                                        locale={languageData}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={10}
                                        timeCaption="Time"
                                    />
                                </div>
                            </div>
                        )}

                        <h1 className="font-medium not-heading-font text-cavolo text-18 mt-4 mb-1.5">
                            {t('carpooling.flexibility')}
                        </h1>

                        <Select<ColourOption, false, GroupBase<ColourOption>>
                            {...selectProps}
                            maxMenuHeight={200}
                            options={options(t)}
                            placeholder={t('log.select-an-option')}
                            styles={colourStyles(whiteLabeling)}
                            isSearchable={false}
                            onChange={handleSelectFlexibility}
                        />

                        <div>
                            <h1 className="font-medium not-heading-font text-cavolo text-18 mt-4 mb-1.5">
                                {t('carpooling.preferences')}
                            </h1>

                            <div className="grid grid-cols-4 items-center gap-4">
                                <div />

                                {preferenceOptions(t)?.map((option) => (
                                    <div key={option} className="text-center text-14 font-normal text-cavolo">
                                        {option}
                                    </div>
                                ))}

                                {preferencesData(t)?.map((item) => (
                                    <React.Fragment key={item?.id}>
                                        <div
                                            className={`text-14 font-normal ${item?.disabled ? 'text-grey01' : 'text-cavolo'}`}>
                                            {item.label}
                                        </div>

                                        {preferenceOptions(t)?.map((option) => (
                                            <div className="text-center">
                                                <label
                                                    className={`flex items-center justify-center cursor-pointer ${item.disabled ? 'opacity-50 pointer-events-none' : ''}`}>
                                                    <Radio
                                                        inputClassName={item.disabled ? 'border-grey01' : 'border-rocket'}
                                                        containerClassName={`w-6 h-6 ${item?.disabled ? 'disable-radio-style' : 'radio-style'} `}
                                                        key={item?.id}
                                                        label=""
                                                        value={option}
                                                        checked={preferences[item.key as keyof typeof preferences] === option}
                                                        onChange={() => handleChange(item.key, option)}
                                                    />
                                                </label>
                                            </div>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>

                        {isSubmitted && (flexibility === '' || !leavingDate || (returningOption === '' && !returningDate)) && (
                            <h1 className="text-[#BD4823] tracking-[-0.02em] leading-[140%] text-15 font-semibold mt-2">
                                {t('carpooling.please-complete-all-fields')}
                            </h1>
                        )}

                        <SecondButton
                            onClick={onSubmit}
                            text={t('carpooling.view-matches')}
                            className="mt-4 w-full h-[48px] font-bold bg-rocket rounded-md text-16 text-pistachio"
                        />

                        <SecondButton
                            onClick={() => {
                                saveJourneyData()
                                navigate('/carpooling-journeys/new-journey')
                            }}
                            text={t('carpooling.back')}
                            className="mt-2 w-full h-[48px] font-bold border border-cavolo rounded-md text-16 text-cavolo"
                        />
                    </div>
                </div>
            </div>

            <JourneyInfoModal
                title={t('carpooling.are-you-sure-you-want-to–leave-without-adding-a-new-journey')}
                showDeleteModal={showInfoModal}
                setShowDeleteModal={setShowInfoModal}
                detail
            />
        </Layout>
    )
}

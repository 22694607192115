import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import JourneyInfoModal from './JourneyInfoModal'
import { Carpooling } from '../../types/data'
import { capitalizeFirstLetter, convertToTitleCase, optionsTravelling } from '../../utils/carpooling'
import { carpoolingJourneyList } from '../../state/travel/selectors'
import { getUser } from '../../state/user/selectors'

interface IProps {
  showModal: boolean
  active: boolean
  setShowModal: (value: boolean) => void
  item: Carpooling
}

export default function CarpoolingMoreMenuModal({ showModal = false, setShowModal, item, active }: IProps) {
    const navigate = useNavigate()
    const { user } = useSelector(getUser)
    const [t] = useTranslation('global')
    const { carpoolingJourney } = useSelector(carpoolingJourneyList)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

    const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target instanceof HTMLElement && e.target.classList.contains('bg-[#000]')) {
            setShowModal(false)
        }
    }

    const carpoolingDetail = carpoolingJourney.find((i) => i?.id === item?.id)

    const onSubmit = () => {
        sessionStorage.removeItem('carpoolingJourneyData')
        sessionStorage.removeItem('carpoolingJourneyDetail')

        const selectedOption = optionsTravelling(t).find((option) => option.value === carpoolingDetail?.travellingAs)

        const journeyData = {
            startPlace: {
                placeId: carpoolingDetail?.startPlaceId,
                lon: carpoolingDetail?.startPlaceLon,
                lat: carpoolingDetail?.startPlaceLat,
                name: carpoolingDetail?.startPlaceName,
            },
            travellingAs: selectedOption,
        }
        sessionStorage.setItem('carpoolingJourneyData', JSON.stringify(journeyData))

        const journeyDetail = {
            leavingDate: carpoolingDetail?.leavingDate,
            returningDate: carpoolingDetail?.returningImmediately ? null : carpoolingDetail?.returningDate,
            returningOption: carpoolingDetail?.returningImmediately ? t('carpooling.immediately-after-the-event').replace('[event]', user?.company?.travelEventName) : '',
            flexibility: carpoolingDetail?.flexibility,
            preferences: {
                smoking: convertToTitleCase(capitalizeFirstLetter(carpoolingDetail?.smoking)),
                pets: convertToTitleCase(capitalizeFirstLetter(carpoolingDetail?.pets)),
                food: convertToTitleCase(capitalizeFirstLetter(carpoolingDetail?.food)),
                verified: '',
            },
        }
        sessionStorage.setItem('carpoolingJourneyDetail', JSON.stringify(journeyDetail))

        navigate('/carpooling-journeys/new-journey', { state: { id: item.id } })
    }

    return (
        showModal && (
            <div className="fixed inset-0 z-[1000000] overflow-y-auto" onClick={handleBackdropClick}>
                <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                    <div className="bg-pistachio relative px-4 py-6 xs-mobile:w-[500px] w-11/12 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                        <h1 className="text-16 text-cavolo font-bold tracking-[-0.02rem]">{item?.game?.name}</h1>

                        <div className="tablet:mt-[16px] mt-1.5 cursor-pointer">
                            {(!active && item?.goingWith?.length && !item?.isReviewed) ? (
                                <div onClick={() => navigate('/carpooling-reviews/create-reviews', { state: item })}
                                    className="flex items-center justify-center w-full h-[48px] rounded-md border border-cavolo mt-3">
                                    <h1 className="text-16 font-bold text-cavolo not-heading-font">
                                        {t('carpooling.leave-a-review')}
                                    </h1>
                                </div>
                            ) : null}

                            {active && (
                                <div onClick={() => navigate(`/carpooling-journeys/your-matches/${carpoolingDetail?.id}`)}
                                    className="flex items-center justify-center w-full h-[48px] rounded-md border border-cavolo mt-3">
                                    <h1 className="text-16 font-bold text-cavolo not-heading-font">
                                        {t('carpooling.view-matches')}
                                    </h1>
                                </div>
                            )}

                            {active && (
                                <div onClick={() => {
                                    navigate('/carpooling-journeys/edit-journey', { state: { id: item.id } })
                                    sessionStorage.removeItem('carpoolingJourneyData')
                                    sessionStorage.removeItem('carpoolingJourneyDetail')
                                }} className="flex items-center justify-center w-full h-[48px] rounded-md border border-cavolo mt-3">
                                    <h1 className="text-16 font-bold text-cavolo not-heading-font">
                                        {t('carpooling.edit-journey')}
                                    </h1>
                                </div>
                            )}

                            <div
                                onClick={onSubmit}
                                className="flex items-center justify-center w-full h-[48px] rounded-md border border-cavolo mt-3">
                                <h1 className="text-16 font-bold text-cavolo not-heading-font">
                                    {t('carpooling.copy-journey')}
                                </h1>
                            </div>

                            {active && (
                                <div className="flex items-center justify-center w-full h-[48px] rounded-md border border-cavolo mt-3"
                                    onClick={() => setShowDeleteModal(true)}>
                                    <h1 className="text-16 font-bold text-cavolo not-heading-font">
                                        {t('carpooling.delete-journey')}
                                    </h1>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <JourneyInfoModal
                    title={t('carpooling.are-you-sure-you-want-to-delete-your-journey-to-event-name').replace('[event name]', item?.game?.name)}
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    setShowModal={setShowModal}
                    item={item}
                />
            </div>
        )
    )
}

import { RootState } from '../index'

export const travelSelector = (state: RootState) => state.travel
export const statisticsList = (state: RootState) => state.travel
export const getJourneyTypesList = (state: RootState) => state.travel
export const commuteStartEndList = (state: RootState) => state.travel
export const legTransportList = (state: RootState) => state.travel
export const commuteSaveList = (state: RootState) => state.travel
export const travelArticlesList = (state: RootState) => state.travel
export const surveyList = (state: RootState) => state.travel
export const carpoolingJourneyList = (state: RootState) => state.travel
export const carpoolingUserJourneyList = (state: RootState) => state.travel
export const carpoolingReviewsList = (state: RootState) => state.travel
export const matchesJourneyList = (state: RootState) => state.travel
export const chatsList = (state: RootState) => state.travel
export const messagesList = (state: RootState) => state.travel

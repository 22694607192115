import React, { useCallback, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import Layout from '../../components/layout/Layout'
import ReviewsCard from '../../components/carpooling/ReviewsCard'
import { fetchCarpoolingReviews, fetchCarpoolingUserJourney } from '../../state/travel/actions'
import { carpoolingReviewsList, carpoolingUserJourneyList } from '../../state/travel/selectors'
import { replacePartsOfText } from '../../utils/helpers'
import { getUser } from '../../state/user/selectors'
import { switchDataReview } from '../../utils/carpooling'
import Loader from '../../components/common/Loader'

export default function CarpoolingReviews() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector(getUser)
    const { carpoolingUserJourney } = useSelector(carpoolingUserJourneyList)
    const { carpoolingReviews, loading } = useSelector(carpoolingReviewsList)
    const [t] = useTranslation('global')
    const [showSwitch, setShowSwitch] = useState<number>(0)

    const switchSelectorFunc = (index: number) => {
        setShowSwitch(index)
        dispatch(fetchCarpoolingReviews(index === 0 ? 'about_others' : 'from_others', user?.id))
    }
    const onRestartLoad = useCallback(() => {
        dispatch(fetchCarpoolingUserJourney())
        dispatch(fetchCarpoolingReviews('about_others', user?.id))
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    return (
        <Layout showFooter={false}>
            <div className="w-full min-h-screen">
                <div
                    className="pb-[100px] flex flex-col items-center mobile:mt-[54px] mt-[12px] mobile:px-[42px] px-[16px]">
                    <div className="max-w-[600px] w-full">
                        <h1 className="heading-uppercase text-22 tracking-tight font-semibold text-cavolo mt-2">
                            {t('carpooling.reviews')}
                        </h1>

                        <div className="flex justify-between mt-2">
                            {switchDataReview(t)?.map((item, index) => (
                                <div onClick={() => switchSelectorFunc(index)} key={item?.id}
                                    className={`flex items-center justify-center w-[47%] h-[31px] transition-all duration-300 rounded-md cursor-pointer ${showSwitch === index ? item?.backgroundActiveColor : item?.backgroundDefaultColor} ${showSwitch === index ? item?.borderActiveColor : item?.borderDefaultColor}`}>
                                    <h1 className={`font-semibold not-heading-font text-14 transition-colors duration-300 text-marrow ${showSwitch === index ? item?.textActiveColor : item?.textDefaultColor}`}>
                                        {item?.name}
                                    </h1>
                                </div>
                            ))}
                        </div>

                        {loading ? (
                            <div className="w-full flex justify-center items-center h-[200px]">
                                <Loader width={75} height={75} />
                            </div>
                        ) : (
                            <div>
                                {showSwitch === 0 && (
                                    carpoolingUserJourney?.map((item) => (
                                        <div className="p-2.5 bg-pistachio rounded-[15px] mt-2"
                                            style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                                            <h1 className="text-12 text-cavolo font-normal">
                                                {replacePartsOfText(t('carpooling.you-need-to-review-your-journey–to-event-name-with-name'), {
                                                    '[event-name]': item?.game?.name,
                                                    '[name]': item?.goingWith?.map((i: any) => i.firstName).join(', ') || '',
                                                })}&nbsp;

                                                <span
                                                    onClick={() => navigate('/carpooling-reviews/create-reviews', { state: item })}
                                                    className="underline underline-offset-2 cursor-pointer">
                                                    {t('global.write-a-review')}
                                                </span>
                                            </h1>
                                        </div>
                                    ))
                                )}

                                {isEmpty(carpoolingReviews) ? (
                                    <h1 className="mt-2 font-normal text-14 not-heading-font text-cavolo">
                                        {t('carpooling.you-don’t-have-any-reviews-yet')}
                                    </h1>
                                ) : (
                                    carpoolingReviews?.map((item: any) => (
                                        <ReviewsCard item={item} itemUser={showSwitch === 0 ? item.toUser : item.fromUser} />
                                    ))
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

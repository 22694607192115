import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Context } from '../common/BaseContext'
import { DismissIcon } from '../common/Svg'
import SecondButton from '../common/SecondButton'
import { Carpooling } from '../../types/data'
import { fetchCarpoolingJourney, removeCarpoolingJourney } from '../../state/travel/actions'

interface IProps {
    showDeleteModal: boolean
    setShowDeleteModal: (value: boolean) => void;
    setShowModal?: (value: boolean) => void;
    title: string
    detail?: boolean
    item?: Carpooling
    // edit?: boolean
}

export default function JourneyInfoModal({ detail, showDeleteModal = false, title, setShowDeleteModal, setShowModal, item }: IProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { screenWidth } = useContext(Context)
    const [t] = useTranslation('global')

    const onClose = () => {
        setShowDeleteModal(false)
        if (setShowModal) {
            setShowModal(false)
        }
    }

    const onSubmit = async () => {
        if (setShowModal) {
            dispatch(removeCarpoolingJourney(item?.id, () => {
                setShowDeleteModal(false)
                setShowModal?.(false)
                dispatch(fetchCarpoolingJourney(true))
            }))
        } else if (detail) {
            navigate('/carpooling-journeys/new-journey')
        } else {
            navigate('/carpooling-journeys')
        }
    }

    return (
        <div>
            {showDeleteModal && (
                <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                    <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                    <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                        <div className="bg-cloud relative xs-mobile:w-[500px] w-11/12 mobile:px-4 px-3 rounded-[16px]"
                            style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                            <div onClick={onClose} className="cursor-pointer mobile:w-[36px] mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center mt-[16px]">
                                <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} />
                            </div>

                            <div className="flex flex-col justify-center items-center pb-[32px]">
                                <div
                                    className="xs-mobile:w-[296px] w-[242px] flex flex-col items-center justify-center">
                                    <h1 className="text-center mobile:text-22 text-18 text-darken font-medium tracking-[-0.02rem] font">
                                        {title}
                                    </h1>
                                </div>

                                <div className="flex justify-between w-[249px] mt-[32px]">
                                    <SecondButton
                                        onClick={onSubmit}
                                        text={t('yourImpact.ok')}
                                        className="w-[89px] h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200" />

                                    <button
                                        onClick={onClose}
                                        className="border-2 border-solid border-rocket bg-mint hover:text-spinach hover:ease-in hover:duration-200 hover:bg-[#A7D49D] w-[136px] h-[48px] font-semibold rounded-md text-14 text-marrow">
                                        {t('global.cancel')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

import apiClient from '../instance'
import { AgreeGoingTogetherData, Carpooling, CarpoolingChatList,
    CarpoolingJourney,
    CarpoolingMessageData, CarpoolingMessageList,
    CarpoolingReviews,
    IAccommodation,
    ICommuteSave,
    ICommuteStartEnd,
    IGame,
    ILeg,
    ILegStartEnd,
    ILegTransport,
    ILegTransportDetails,
    ILogRouteData,
    IMultipleSaveLeg,
    IMultipleSaveLegs,
    IPlanJourneyData,
    IPrizeDrawEntryData,
    IRemoveOrOffsetData,
    IReviewsData,
    ISavedRoute,
    IStatisticsResponse,
    ISurveyData,
    ITransportCeo2Data,
    ITransportData,
    ITravelArticles,
    ITravelCommuteData,
    ITravelData,
    JourneyTypes,
    TransportMode } from '../../types/data'
import { AGREE_GOING_TOGETHER_URL,
    ANSWER_SURVEY_QUESTION_URL,
    ARTICLE_BOOKMARK_URL,
    CARPOOLING_CHATS_URL,
    CARPOOLING_JOURNEY_DELETE_URL,
    CARPOOLING_JOURNEY_EDIT_URL,
    CARPOOLING_JOURNEY_URL,
    CARPOOLING_MESSAGES_URL,
    CARPOOLING_REPORT_CHAT_DETAIL_URL,
    CARPOOLING_REVIEWS_CREATE_URL,
    CARPOOLING_REVIEWS_LIST_URL,
    CARPOOLING_USER_JOURNEYS_URL,
    CHANGE_LEG_URL,
    CHANGE_TRACK_TRAVEL_URL,
    COMMUTE_SAVE_URL,
    COMMUTE_START_END_URL,
    DELETE_FAVOURITE_ROUTES_URL,
    DELETE_LEGS_URL,
    FAVOURITE_ROUTES_URL,
    GAME_URL,
    JOURNEY_TYPES_URL,
    LEG_START_END_URL,
    LEG_TRANSPORT_DETAILS_URL,
    LEG_TRANSPORT_URL,
    LOG_ROUTE_URL,
    MATCHES_JOURNEY_URL,
    MULTIPLE_SAVE_LEGS_URL,
    PLAN_JOURNEY_URL,
    PRIZE_DRAW_ENTRIES_REPORT_URL,
    RECENT_ROUTES_URL,
    REMOVE_OR_OFFSET_URL,
    STATISTICS_EMPLOYEE_URL,
    STATISTICS_FAN_URL,
    SURVEYS_URL,
    TRANSPORT_CEO2_URL,
    TRANSPORT_MODES_URL,
    TRANSPORT_URL,
    TRAVEL_ACCOMMODATION_URL,
    TRAVEL_AI_LOG_JOURNEY,
    TRAVEL_ARTICLES_URL,
    TRAVEL_CHECK_TRAVEL_DATES_VALID,
    TRAVEL_COMMUTE_DETAIL_URL,
    TRAVEL_COMMUTE_URL,
    TRAVEL_CONTENT_BLOCK_URL,
    TRAVEL_LAST_MULTI_DAY_SECTION_URL } from '../../urls'

/**
 * Get game
 */

export const getGame = async (): Promise<IGame | null> => {
    try {
        const { data: response } = await apiClient.get<IGame>(GAME_URL)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get transport
 */

export const getTransport = async (): Promise<ITransportData | null> => {
    try {
        const { data: response } = await apiClient.get<ITransportData>(TRANSPORT_URL)
        return response
    } catch (e) {
        throw e
    }
}

export const getRecentRoutes = async (): Promise<ITravelData | null> => {
    try {
        const { data: response } = await apiClient.get<ITravelData>(RECENT_ROUTES_URL)
        return response
    } catch (e) {
        throw e
    }
}

export const getTransportCeo2 = async (): Promise<ITransportCeo2Data | null> => {
    try {
        const { data: response } = await apiClient.get<ITransportCeo2Data>(TRANSPORT_CEO2_URL)
        return response
    } catch (e) {
        throw e
    }
}

export const getSavedRoutes = async (): Promise<ISavedRoute | null> => {
    try {
        const { data: response } = await apiClient.get<ISavedRoute>(FAVOURITE_ROUTES_URL)
        return response
    } catch (e) {
        throw e
    }
}

export const postSavedRoutes = async (data: ISavedRoute): Promise<ISavedRoute | null> => {
    try {
        const { data: response } = await apiClient.post<ISavedRoute>(FAVOURITE_ROUTES_URL, data)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Post travel commute
 */
export const postTravelCommuteItem = async (data: ITravelCommuteData): Promise<ITravelCommuteData> => {
    try {
        const { data: response } = await apiClient.post<ITravelCommuteData>(TRAVEL_COMMUTE_URL, data)
        return response
    } catch (e) {
        throw e
    }
}

export const putTravelCommute = async (data: ITravelData): Promise<ITravelData> => {
    try {
        const { data: response } = await apiClient.put<ITravelData>(CHANGE_TRACK_TRAVEL_URL, data)
        return response
    } catch (e) {
        throw e
    }
}

export const getTravelCommute = async (id: number): Promise<ITravelData> => {
    try {
        const { data: response } = await apiClient.get<ITravelData>(TRAVEL_COMMUTE_DETAIL_URL.replace('{id}', String(id)))
        return response
    } catch (e) {
        throw e
    }
}

export const postLogRoute = async (data: ILogRouteData): Promise<ITravelData> => {
    try {
        const { data: response } = await apiClient.post<ITravelData>(LOG_ROUTE_URL, data)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * get statistics fan item
 */
export const getStatisticsFan = async (): Promise<IStatisticsResponse | null> => {
    try {
        const { data: response } = await apiClient.get<IStatisticsResponse>(STATISTICS_FAN_URL)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * get statistics employee item
 */
export const getStatisticsEmployee = async (): Promise<IStatisticsResponse | null> => {
    try {
        const { data: response } = await apiClient.get<IStatisticsResponse>(STATISTICS_EMPLOYEE_URL)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Delete legs
 */
export const deleteLeg = async (legId: number | string | undefined | number[]): Promise<number | string | null> => {
    try {
        const { data: response } = await apiClient.delete<any>(DELETE_LEGS_URL, { data: { legs: legId } })
        return response
    } catch (e) {
        throw e
    }
}

export const getJourneyTypes = async (): Promise<JourneyTypes | null> => {
    try {
        const { data: response } = await apiClient.get<JourneyTypes>(JOURNEY_TYPES_URL)
        return response
    } catch (e) {
        throw e
    }
}

export const getTransportModes = async (): Promise<TransportMode | null> => {
    try {
        const { data: response } = await apiClient.get<TransportMode>(TRANSPORT_MODES_URL)
        return response
    } catch (e) {
        throw e
    }
}

export const getTravelContentBlock = async (): Promise<TransportMode | null> => {
    try {
        const { data: response } = await apiClient.get<TransportMode>(TRAVEL_CONTENT_BLOCK_URL)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * post commute start end
 */
export const postCommuteStartEnd = async (data: ICommuteStartEnd): Promise<ICommuteStartEnd> => {
    try {
        const { data: response } = await apiClient.post<ICommuteStartEnd>(COMMUTE_START_END_URL, data)
        return response
    } catch (e) {
        throw e
    }
}

export const changeLegDetailsPost = async (id: number, data: ICommuteStartEnd): Promise<ILeg> => {
    try {
        const { data: response } = await apiClient.put<ILeg>(CHANGE_LEG_URL.replace('{id}', String(id)), data)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * post leg transport
 */
export const postLegTransport = async (data: ILegTransport): Promise<ILegTransport> => {
    try {
        const { data: response } = await apiClient.post<ILegTransport>(LEG_TRANSPORT_URL, data)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * post leg transport details
 */
export const postLegTransportDetails = async (data: ILegTransportDetails): Promise<ILegTransportDetails> => {
    try {
        const { data: response } = await apiClient.post<ILegTransportDetails>(LEG_TRANSPORT_DETAILS_URL, data)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * post leg start end
 */
export const postLegStartEnd = async (data: ILegStartEnd): Promise<ILegStartEnd> => {
    try {
        const { data: response } = await apiClient.post<ILegStartEnd>(LEG_START_END_URL, data)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * post multiple save legs
 */
export const postMultipleSaveLegs = async (data: { legs: IMultipleSaveLeg[] }): Promise<IMultipleSaveLegs> => {
    try {
        const { data: response } = await apiClient.post<IMultipleSaveLegs>(MULTIPLE_SAVE_LEGS_URL, data)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * post commute save
 */
export const postCommuteSave = async (data: ICommuteSave): Promise<ITravelData> => {
    try {
        const { data: response } = await apiClient.post<ITravelData>(COMMUTE_SAVE_URL, data)
        return response
    } catch (e) {
        throw e
    }
}

export const postPlanJourney = async (data: IPlanJourneyData): Promise<ITravelData> => {
    try {
        const { data: response } = await apiClient.post<ITravelData>(PLAN_JOURNEY_URL, data)
        return response
    } catch (e) {
        throw e
    }
}

export const postPrizeDrawEntryItems = async (data: IPrizeDrawEntryData): Promise<IPrizeDrawEntryData> => {
    try {
        const { data: response } = await apiClient.post<IPrizeDrawEntryData>(PRIZE_DRAW_ENTRIES_REPORT_URL, data)
        return response
    } catch (e) {
        throw e
    }
}

export const postRemoveOrOffset = async (data: IRemoveOrOffsetData): Promise<IRemoveOrOffsetData> => {
    try {
        const { data: response } = await apiClient.post<IRemoveOrOffsetData>(REMOVE_OR_OFFSET_URL, data)
        return response
    } catch (e) {
        throw e
    }
}

export const getTravelArticles = async (): Promise<ITravelArticles | null> => {
    try {
        const { data: response } = await apiClient.get<ITravelArticles>(TRAVEL_ARTICLES_URL)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Post travel article bookmark item
 */
export const postTravelArticleBookmarkItem = async (id: number | undefined): Promise<{ isBookmark: boolean } | null> => {
    try {
        const { data: response } = await apiClient.post<boolean | null>(ARTICLE_BOOKMARK_URL, { articleId: id })
        return response === null ? null : { isBookmark: response }
    } catch (e) {
        throw e
    }
}

/**
 * Post accommodation item
 */
export const postAccommodationItem = async (data: IAccommodation): Promise<ITravelData> => {
    try {
        const { data: response } = await apiClient.post<ITravelData>(TRAVEL_ACCOMMODATION_URL, data)

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Post last multi day section item
 */
export const postLastMultiDaySectionItem = async (data: IAccommodation, id: number | string): Promise<ITravelData> => {
    try {
        const { data: response } = await apiClient.post<ITravelData>(TRAVEL_LAST_MULTI_DAY_SECTION_URL.replace('{id}', String(id)), data)

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Check travel dates valid
 */
export const checkTravelDatesValid = async (data: { dates: string[] }): Promise<{ message?: string }> => {
    try {
        const { data: response } = await apiClient.post<{ message?: string }>(TRAVEL_CHECK_TRAVEL_DATES_VALID, data)

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Post survey item
 */
export const postSurveyItem = async (data: ISurveyData): Promise<ISurveyData> => {
    try {
        const { data: response } = await apiClient.post<ISurveyData>(ANSWER_SURVEY_QUESTION_URL, data)

        return response
    } catch (e) {
        throw e
    }
}

/**
 * Delete saved routes
 */
export const deleteSavedRoutes = async (id: number | string | undefined): Promise<number | string | null> => {
    try {
        const { data: response } = await apiClient.delete<any>(DELETE_FAVOURITE_ROUTES_URL.replace('{id}', String(id)))

        return response
    } catch (e) {
        throw e
    }
}

/**
 * post log journey with AI
 */
export const postLogJourneyAI = async (data: {text?: string, audio?: File | null, previousUserInput?: string, errorMessage?: string, dates?: string[], games?: number[]}): Promise<{text?: string, audio?: File}> => {
    try {
        const formData = new FormData()
        if (data.text) formData.append('text', data.text)
        if (data.audio) formData.append('audio', data.audio)
        if (data.previousUserInput) formData.append('previous_user_input', data.previousUserInput)
        if (data.errorMessage) formData.append('error_message', data.errorMessage)
        if (data.games && data.games.length) formData.append('games', data.games.join(','))
        if (data.dates) formData.append('dates', data.dates.join(','))

        const { data: response } = await apiClient.post<{text?: string, audio?: File}>(TRAVEL_AI_LOG_JOURNEY, formData)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Post travel article bookmark item
 */
export const getSurveyItem = async (id: number | string): Promise<ITravelData> => {
    try {
        const { data: response } = await apiClient.get<ITravelData>(SURVEYS_URL.replace('{id}', String(id)))
        return response
    } catch (e) {
        throw e
    }
}

/**
 * get carpooling journey
 */
export const getCarpoolingJourney = async (active: boolean): Promise<Carpooling | null> => {
    try {
        const { data: response } = await apiClient.get<Carpooling>(CARPOOLING_JOURNEY_URL, {
            params: { active },
        })
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Delete carpooling journey
 */
export const deleteCarpoolingJourney = async (id: number | undefined): Promise<{}> => {
    try {
        const { data: response } = await apiClient.delete<{}>(CARPOOLING_JOURNEY_DELETE_URL.replace('{id}', String(id)))

        return response
    } catch (e) {
        throw e
    }
}

/**
 * get carpooling user journey
 */
export const getCarpoolingUserJourney = async (): Promise<Carpooling | null> => {
    try {
        const { data: response } = await apiClient.get<Carpooling>(CARPOOLING_USER_JOURNEYS_URL)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * get carpooling reviews
 */
export const getCarpoolingReviews = async (reviewType: string, userId: number | string): Promise<CarpoolingReviews> => {
    try {
        const { data: response } = await apiClient.get<CarpoolingReviews>(CARPOOLING_REVIEWS_LIST_URL, {
            params: { reviewType, userId },
        })
        return response
    } catch (e) {
        throw e
    }
}

/**
 * create carpooling reviews
 */
export const postCarpoolingReviews = async (data: IReviewsData): Promise<any> => {
    try {
        const { data: response } = await apiClient.post<any>(CARPOOLING_REVIEWS_CREATE_URL, data)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * create carpooling journey
 */
export const postCarpoolingJourney = async (data: CarpoolingJourney): Promise<CarpoolingJourney> => {
    try {
        const { data: response } = await apiClient.post<CarpoolingJourney>(CARPOOLING_JOURNEY_URL, data)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Edit carpooling journey
 */
export const changeCarpoolingJourney = async (id: number | undefined, data: CarpoolingJourney): Promise<CarpoolingJourney> => {
    try {
        const { data: response } = await apiClient.put<CarpoolingJourney>(CARPOOLING_JOURNEY_EDIT_URL.replace('{id}', String(id)), data)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get matches journey
 */
export const getMatchesJourney = async (id: string | undefined): Promise<Carpooling> => {
    try {
        const { data: response } = await apiClient.get<Carpooling>(MATCHES_JOURNEY_URL.replace('{id}', String(id)))
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get messages item
 */
export const postMessagesItem = async (data?: CarpoolingMessageData): Promise<any> => {
    try {
        const { data: response } = await apiClient.post<any>(CARPOOLING_MESSAGES_URL, data)
        return response
    } catch (e) {
        throw e
    }
}

export const getReportChatDetail = async (): Promise<any> => {
    try {
        const { data: response } = await apiClient.get<any>(CARPOOLING_REPORT_CHAT_DETAIL_URL)
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get chat item
 */
export const getChatItem = async (lastMessageTimestamp?: string): Promise<CarpoolingChatList> => {
    try {
        const { data: response } = await apiClient.get<CarpoolingChatList>(CARPOOLING_CHATS_URL, {
            params: { lastMessageTimestamp },
        })
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Get carpooling message item
 */
export const getMessageItem = async (chat: string | undefined, lastMessageId?: number): Promise<CarpoolingMessageList> => {
    try {
        const { data: response } = await apiClient.get<CarpoolingMessageList>(CARPOOLING_MESSAGES_URL, {
            params: { chat, lastMessageId },
        })
        return response
    } catch (e) {
        throw e
    }
}

/**
 * Post confirm journey
 */
export const postAgreeGoingTogether = async (data: AgreeGoingTogetherData): Promise<AgreeGoingTogetherData> => {
    try {
        const { data: response } = await apiClient.post<AgreeGoingTogetherData>(AGREE_GOING_TOGETHER_URL, data)
        return response
    } catch (e) {
        throw e
    }
}

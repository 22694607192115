import React, { useCallback, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import Layout from '../../components/layout/Layout'
import MatchCard from '../../components/carpooling/MatchCard'
import { fetchCarpoolingJourney, fetchMatchesJourney } from '../../state/travel/actions'
import { matchesJourneyList } from '../../state/travel/selectors'

export default function CarpoolingMatches() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [t] = useTranslation('global')
    const { id } = useParams()
    const { matchesJourney } = useSelector(matchesJourneyList)

    const onRestartLoad = useCallback(() => {
        dispatch(fetchMatchesJourney(id))
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    return (
        <Layout showFooter={false}>
            <div className="w-full min-h-screen">
                <div
                    className="pb-[100px] flex flex-col items-center mobile:mt-[54px] mt-[12px] mobile:px-[42px] px-[16px]">
                    <div className="max-w-[600px] w-full">
                        <h1 className="heading-uppercase text-22 tracking-tight font-semibold text-cavolo mt-2">
                            {t('carpooling.your-matches')}
                        </h1>

                        {isEmpty(matchesJourney) ? (
                            <div>
                                <h1 className="text-14 font-normal text-cavolo mt-2">
                                    {t('carpooling.you-currently-have-no-matches-for-this-journey')}
                                </h1>

                                <h1 className="text-14 font-normal text-cavolo mt-2">
                                    {t('carpooling.please-check-back-later-or')}&nbsp;
                                    <span onClick={() => {
                                        navigate('/carpooling-journeys/edit-journey', { state: { id } })
                                        sessionStorage.removeItem('carpoolingJourneyData')
                                        sessionStorage.removeItem('carpoolingJourneyDetail')
                                        dispatch(fetchCarpoolingJourney(true))
                                    }} className="underline underline-offset-2 cursor-pointer">
                                        {t('carpooling.update-your-journey-details')}
                                    </span>
                                </h1>
                            </div>
                        ) : (
                            matchesJourney?.sort((a, b) => a.distance - b.distance)?.map((item) => (
                                <div key={item.id}
                                    onClick={() => navigate(`/carpooling-journeys/your-matches-details/${item?.id}`, { state: { id } })}>
                                    <MatchCard item={item} />
                                </div>
                            ))
                        )}

                        <h1 className="text-14 font-normal text-cavolo mt-2">
                            {t('carpooling.back-to')}&nbsp;
                            <span onClick={() => navigate('/carpooling-journeys')}
                                className="underline underline-offset-2 cursor-pointer">
                                {t('carpooling.my-journeys')}
                            </span>
                        </h1>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Layout from '../../components/layout/Layout'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { Context } from '../../components/common/BaseContext'
import { getHomePageLists } from '../../state/home/selectors'
import { getUser } from '../../state/user/selectors'
import { BigMicIcon } from '../../components/common/Svg'
import SecondButton from '../../components/common/SecondButton'

export default function LogTravelAI() {
    const { user } = useSelector(getUser)
    const navigate = useNavigate()
    const [t] = useTranslation('global')
    const { navbarHeight, screenWidth } = useContext(Context)
    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Log')

    const [isRequesting, setIsRequesting] = useState(false)

    const requestMicPermission = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
            // Stop the stream after getting permission
            stream.getTracks().forEach((track) => track.stop())
            return true
        } catch (err) {
            return false
        }
    }

    const onSubmit = async () => {
        if (isRequesting) return
        setIsRequesting(true)
        const hasPermission = await requestMicPermission()
        if (hasPermission) navigate('/log/log-travel-AI-voice-listening')
        setIsRequesting(false)
    }
    return (
        <Layout showFooter={false}>
            <div className="w-full">
                {sponsoredBlock && <div className="mb-3 mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}

                <div style={{ marginTop: screenWidth > 768 ? navbarHeight : 0 }}
                    className={`flex flex-col items-center ${screenWidth < 768 && 'pt-5'} mobile:px-[42px] px-4 h-screen`}>
                    <div className="max-w-[600px] w-full pb-[300px] flex flex-col items-center">
                        <h1 className="font-bold text-22 text-cavolo tracking-tight">
                            {t('log.hi-i\'m-chatbot-name-tell-me-about-your-journey-and-i\'ll-log-your-emissions-data-for-you').replace('[chatbot-name]', user?.company?.chatbotName)}
                        </h1>

                        <h1 className="text-14 font-semibold text-grey01 not-heading-font mt-[45px]">
                            {t('log.tap-the-microphone-to-start')}
                        </h1>

                        <div className="cursor-pointer mt-4" onClick={onSubmit}>
                            <BigMicIcon />
                        </div>

                        <h1 className="text-14 font-semibold text-grey01 not-heading-font mt-[55px]">
                            {t('log.don\'t-want-to-talk')}
                        </h1>

                        <SecondButton
                            onClick={() => navigate('/log/log-travel-AI-text')}
                            text={t('log.tell-me-in-writing')}
                            className="mt-4 w-full h-[41px] font-semibold bg-raspberry rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200"
                        />

                        <SecondButton
                            onClick={() => navigate('/log/track-travel-start-end')}
                            text={t('log.enter-journey-manually')}
                            className="mt-3 w-full h-[41px] font-semibold bg-raspberry rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200"
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

import moment from 'moment'

export const DATETIME_FORMAT_AUTO = 'DD MMM YYYY'
export const DAY_AUTO_FORMAT = 'DD'
export const MONTH_YEAR_FORMAT_AUTO = 'MMM, YYYY'
export const DATE_AUTO_FORMAT_MONTHS = 'MMMM YYYY'
export const DATE_LOG_DAY_AUTO = 'YYYY-MM-DD'
export const DATE_TRAVEL = 'YYYY-MM-DD'
export const DATE_STATISTICS = 'DD MMM'
export const DATE_STATISTICS_EMPLOYEE = 'MMMM'
export const DATE_MONTH_FORMAT_AUTO = 'DD MMM'

export function getDateTime(date: string | undefined) {
    return moment(date).format(DATETIME_FORMAT_AUTO)
}

export function getDates(date: Date | undefined | string) {
    return moment(date).format(DATETIME_FORMAT_AUTO)
}

export function travelDate(date: Date | undefined | string) {
    return moment(date).format(DATE_TRAVEL)
}

export function getDateTimes(date: any) {
    return moment(date).format(DATE_MONTH_FORMAT_AUTO)
}

export function getMonths(date?: string) {
    return moment(date).format(DATE_AUTO_FORMAT_MONTHS)
}

export function getDateTimeChallenge(startDate: string | undefined, endDate: string | undefined) {
    const firstDay = moment(startDate).format(DAY_AUTO_FORMAT)
    const lastDay = moment(endDate).format(DAY_AUTO_FORMAT)
    const monthAndYear = moment(endDate).format(MONTH_YEAR_FORMAT_AUTO)
    return `${firstDay} - ${lastDay} ${monthAndYear}`
}

export function dash(name: string | undefined) {
    // @ts-ignore
    return name?.split(' ').join('-').split('(').join('').split(')').join('')
}

export function getDate(date: string) {
    return moment(date).format(DATETIME_FORMAT_AUTO)
}

export function formatDate(date: string | number, locale: string) {
    moment.locale(locale)
    return moment(date).format('D MMM')
}

export async function getDatesLocalization(date: Date | undefined | string, language: string) {
    if (language !== 'en') {
        await import(`moment/locale/${language}`)
        moment.locale(language)
    }
    return moment(date).format(DATETIME_FORMAT_AUTO)
}

export async function statisticsDate(date: Date | undefined | string, language: string) {
    if (language !== 'en') {
        await import(`moment/locale/${language}`)
        moment.locale(language)
    }
    return moment(date).format(DATE_STATISTICS)
}

export async function statisticsDateEmployee(date: number | string | undefined, language: string) {
    if (language !== 'en') {
        await import(`moment/locale/${language}`)
        moment.locale(language)
    }
    return moment(date, 'M').format(DATE_STATISTICS_EMPLOYEE)
}

export const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const
export const daysOfWeekShort = ['M', 'T', 'W', 'T', 'F', 'S', 'S']

export const checkDateSequence = (d: string[]) => {
    const dateObjects = d.map((date) => new Date(date))

    const isDescending = dateObjects[0] > dateObjects[1]
    const isAscending = dateObjects[0] < dateObjects[1]

    for (let i = 0; i < dateObjects.length - 1; i++) {
        const currentDate = dateObjects[i]
        const nextDate = dateObjects[i + 1]

        const diffInDays = (currentDate.getTime() - nextDate.getTime()) / (1000 * 60 * 60 * 24)

        if (Math.abs(diffInDays) !== 1) {
            return { isConsecutive: false, sequence: null } // Dates are not consecutive
        }
    }

    return {
        isConsecutive: true,
        sequence: isAscending ? 'ascending' : isDescending ? 'descending' : null,
    }
}

export const formatDateJourney = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    }

    const timeOptions: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    }

    const formattedDate = new Date(date).toLocaleDateString('en-GB', options)
    const formattedTime = new Date(date).toLocaleTimeString('en-GB', timeOptions)

    // Combine date and time in the desired format
    return `${formattedDate} at ${formattedTime.toLowerCase()}`
}

export const formatTime = (isoDate: string) => {
    const date = new Date(isoDate)
    const hours = date.getHours()
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const period = hours >= 12 ? 'pm' : 'am'
    const formattedHours = hours % 12 || 12
    return `${formattedHours}:${minutes} ${period}`
}

export const getHourAndMinute = (isoDate: string | undefined) => {
    if (!isoDate) return { hour: 0, minute: 0 }
    const date = new Date(isoDate)
    const hour = Number(date.getHours() || 0)
    const minute = Number(date.getMinutes().toString().padStart(2, '0') || 0)
    return { hour, minute }
}

export const formatResponseDate = (dateString: string) => {
    const date = new Date(dateString)

    const today = new Date()

    const isToday = date.getDate() === today.getDate()
                    && date.getMonth() === today.getMonth()
                    && date.getFullYear() === today.getFullYear()

    if (isToday) {
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')
        return `${hours}:${minutes}`
    }

    const day = date.getDate()
    const month = date.toLocaleString('default', { month: 'short' })
    return `${day} ${month}`
}

export const formatResponseHour = (dateString: string) => {
    const date = new Date(dateString)

    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    return `${hours}:${minutes}`
}

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import lodash from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import { travelSelector } from '../../state/travel/selectors'
import { ArrowTravel, BinIcon } from '../../components/common/Svg'
import { readableC02, readableCO2, replacePartsOfText } from '../../utils/helpers'
import { ITravelData } from '../../types/data'
import DeleteFavouriteJourneyModal from '../../components/travel/DeleteFavouriteJourneyModal'
import { getUser } from '../../state/user/selectors'
import { logSavedCommute } from '../../utils/travel'
import InfoModal from '../../components/travel/InfoModal'

export default function TrackTravelSavedRoutes() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { savedRoutes, loading } = useSelector(travelSelector)
    const [t] = useTranslation('global')
    const { user } = useSelector(getUser)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [savedRouteId, setSavedRouteId] = useState<number | string | undefined>()
    const travelData = JSON.parse(localStorage.getItem('travelData') || '{}')

    const [showModalPoint, setShowModalPoint] = useState<boolean | undefined>(false)
    const [destination, setDestination] = useState<any>()
    const [nameLog, setNameLog] = useState<string>('')

    function getTotalDistance(commute: ITravelData) {
        let distance = commute.distanceInMil ? `${commute.distanceInMil} mil${commute.distanceInKm ? ', ' : ''}` : ''
        distance += commute.distanceInKm ? `${commute.distanceInKm} km` : ''
        if (!commute.distanceInKm && !commute.distanceInMil) return '0 km'

        const numberValue = parseFloat((distance.match(/\d+\.\d+|\d+/g) ?? [])[0] || '0')
        const co2Value = readableCO2(numberValue)
        return `${co2Value} km`
    }

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Track travel saved routes' })
    }, [])

    const onSubmit = (id: number | string | undefined) => {
        setSavedRouteId(id)
        setShowDeleteModal(true)
    }

    const onContinue = (commute: any, name: string) => {
        if (loading) return

        setNameLog(name)

        setDestination(commute)
        if (commute?.endPlaceId !== travelData?.gameData[0]?.item?.placeId) {
            setShowModalPoint(true)
        } else {
            logCommute(commute)
        }
    }

    const logCommute = (commute: any) => {
        if (loading) return

        logSavedCommute(() => null, dispatch, travelData.date, commute, travelData?.gameIds, travelData?.gameDate, navigate)
    }

    return (
        <div className="w-full">
            <div className="justify-end medium:mt-[52px] mt-[32px] pl-5 mobile:pl-[45px] pr-4 mobile:mx-[42px]">
                <div onClick={() => navigate(-1)} className="flex cursor-pointer">
                    <ArrowTravel width={22} height={22} />
                </div>

                <div className="flex flex-col items-center mobile:mt-[30px]">
                    <div className="max-w-[764px] w-full">
                        <div className="flex items-center justify-between mb-[3px] mt-[30px]">
                            <h1 className="heading-uppercase heading-font font-bold text-22 text-black">
                                {t('global.saved-routes')}
                            </h1>
                        </div>

                        {savedRoutes?.map((item) => (
                            <div key={item?.id} className="mt-4 border-b border-grey01 pb-[6px] flex items-center">
                                <div className="w-full">
                                    <div className="flex items-center justify-between">
                                        <p className="text-14 font-bold text-black">
                                            {item?.name}
                                        </p>

                                        <div onClick={() => item?.commute.startPlaceId && (travelData?.date && user?.company?.travelTrackingType === 'event' ? onContinue(item.commute, 'favourite') : logCommute(item.commute))}
                                            className={`flex items-center ${!loading && 'cursor-pointer'}`}>
                                            <p className={`text-14 font-bold ${!loading ? 'text-black' : 'text-grey01'} mr-[4px]`}>
                                                + {t('global.log-route')}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between mt-[4px]">
                                        <p className="font-normal text-14 text-grey01">
                                            {getTotalDistance(item.commute)}
                                        </p>

                                        <p className="font-normal text-14 text-grey01">
                                            {t('log.by')}&nbsp;
                                            {lodash
                                                .uniq(item?.commute?.legs?.map((i) => i?.transport?.name))
                                                .join(', ')}
                                        </p>

                                        <p className="font-normal not-heading-font text-14 text-grey01">
                                            +{readableC02(item?.commute?.totalCeo2Kg || 0)}
                                        </p>
                                    </div>
                                </div>

                                <div className="cursor-pointer ml-2" onClick={() => { if (!loading) { onSubmit(item?.id) } }}>
                                    <BinIcon />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <DeleteFavouriteJourneyModal
                loading={loading}
                id={savedRouteId}
                title={t('log.are-you-sure-you-want-to-delete-this-favourite-journey')}
                isShown={showDeleteModal}
                onContinue={() => setShowDeleteModal(false)}
            />

            <InfoModal
                isShown={showModalPoint}
                onClose={() => setShowModalPoint(false)}
                log
                title={replacePartsOfText(t('log.log-route-end-place-invalid-error-message'), {
                    '[travel-event-name]': user?.company?.travelEventName || '',
                    '[fan-company-game]': travelData?.gameData?.[0]?.item?.place ? travelData.gameData[0].item.place.slice(0, travelData.gameData[0].item.place.indexOf(',')) : '',
                    '[saved-favourite-destination]': destination?.endPlaceName ? destination.endPlaceName.slice(0, destination.endPlaceName.indexOf(',')) : '',
                    '[recent/favourite]': nameLog,
                })} />
        </div>
    )
}

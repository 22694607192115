/* USER */
export const USER_URL = 'users/user-detail'
export const USER_UPDATE = 'users/user-settings'
export const USER_CHANGE_PASSWORD = 'users/change-password'
export const USER_EMAIL_REGISTER = 'users/register-with-email'
export const USER_SIGN_IN = 'users/sign-in'
export const USER_RESET_LINK = 'users/reset-link'
export const USER_RESET_PASSWORD = 'users/reset-password'
export const USER_ADD_POINT = 'users/visit'
export const DELETE_MY_ACCOUNT = 'users/delete-my-account'
export const USER_AVATAR_UPDATE = 'users/avatar-update'
export const SETTINGS_URL = 'core/settings'
export const SAVED_PLACES_URL = 'users/saved-places'
export const GOOGLE_SOCIAL_LOGIN_URL = 'users/social-login/google'

/* PERKS */
export const PERKS_HOME_PAGE_URL = 'perks/frontend-home-page'
export const PERKS_TYPE_URL = 'perks/type-list'
export const PERKS_BRANDS_URL = 'perks/all-perks-with-brands'
export const PERKS_BOOKMARK_URL = 'perks/bookmark'
export const PERKS_BY_TYPE_URL = 'perks/by-perk-type-or-tag'
export const PERKS_RATE_URL = 'perks/rate'
export const PERKS_COMMENT_URL = 'perks/comment'
export const PERKS_LAST_COMMENT_URL = 'perks/last-comment'
export const PERKS_COMMENT_LIST_URL = 'perks/comment-list-pagination'
export const PERKS_TAG_URL = 'perks/tag-list'
export const PERKS_MY_BOOKMARK_URL = 'perks/my-bookmark-list-pagination'
export const PERKS_SEARCH_URL = 'perks/search'
export const PERKS_SEARCH_COLLECTION_URL = 'searchs/perk-search-collection'
export const PERKS_BOL_GET_COUPON = 'perks/bol/coupon'
export const PERKS_LOG = 'perks/log'
export const PERK_ID_DEEPLINK_URL = 'perks/perk-by-id'

/* RECIPES */
export const RECIPE_BOOKMARK_URL = 'recipes/bookmark'
export const RECIPES_MY_BOOKMARK_URL = 'recipes/my-bookmark-list-pagination'
export const RECIPE_RATING_URL = 'recipes/rate'
export const RECIPE_COMMENT_URL = 'recipes/comment'
export const COMMENTS_BY_RECIPE_PAGINATION_URL = 'recipes/comments-by-recipe/pagination'
export const RECIPE_CATEGORY_URL = 'recipes/category-list'
export const RECIPE_BY_CATEGORY_URL = 'recipes/by-category-tags'
export const RECIPE_TAGS_URL = 'recipes/tags'
export const RECIPE_HOME_PAGE_URL = 'recipes/frontend-home-page-recipes'
export const RECIPE_SEARCH_URL = 'recipes/search'
export const RECIPE_SEARCH_COLLECTION_URL = 'searchs/recipe-search-collection'
export const RECIPE_ID_DEEPLINK_URL = 'recipes/recipe-by-id'
export const RECIPE_LOG = 'recipes/log'

/* ARTICLE */
export const ARTICLE_HOME_PAGE_URL = 'articles/frontend-homepage'
export const ARTICLE_CATEGORY_URL = 'articles/category-list'
export const ARTICLE_BY_CATEGORY_URL = 'articles/articles/by/category'
export const ARTICLE_ANSWER_URL = 'articles/quizz/answers'
export const ARTICLE_BOOKMARK_URL = 'articles/articles/bookmark'
export const ARTICLES_MY_BOOKMARK_URL = 'articles/my-bookmark-pagination-list'
export const ARTICLES_SEARCH_URL = 'articles/search'
export const ARTICLES_SEARCH_COLLECTION_URL = 'searchs/article-search-collection'
export const ARTICLES_BY_ID_URL = 'articles/article-by-id'

/* PRIZE */
export const WINNERS_PRICE = 'progress-bar-prizes/winners-price'
export const WINNERS_PRICE_UPDATE = 'progress-bar-prizes/winners-price-patch'
export const PRIZE_WHEEL_TYPE_URL = 'progress-bar-prizes/prize-wheel-list'
export const BADGE_TASKS_URL = 'progress-bar-prizes/badge-tasks-list'

/* HOME PAGE */
export const HOME_PAGE_URL = 'dashboard/frontend-homepage'

/* COMPANY */
export const MONTHLY_COMPETITION_URL = 'companies/monthly-competition'
export const HOW_TO_EARN_POINTS_URL = 'companies/how-to-earn-points-instruction'
export const COMPANY_ARTICLES_URL = 'companies/articles'
export const HOW_TO_COLLECT_POINTS_URL = 'companies/fan-how-to-collect-points-instruction'
export const SPONSORSHIP_BLOCKS_URL = 'companies/sponsorship-blocks'
export const INITIALIZE_RESPONSE_URL = 'core/initialize'
export const FINAL_POINT_REWARDS_URL = 'companies/final-point-rewards'
export const WHITE_LABELING_URL = 'companies/white-labeling'
export const MENU_ITEMS_URL = 'companies/menu-items'
export const LANGUAGE_ITEMS_URL = 'companies/languages'
export const LANDING_PAGE_URL = 'companies/landing-page/{id}'
export const ORGANISATIONAL_UNITS_URL = 'companies/organisational-units'
export const ACTIVE_REFERRAL_CAMPAIGN_URL = 'companies/active-referral-campaign'
export const ACTIVITIES_URL = 'companies/activities'
export const PRIZE_DRAW_ENTRIES_REPORT_URL = 'companies/prize-draw-entry'
export const JOURNEY_TYPES_URL = 'companies/journey-types'
export const LOGIN_CONFIG_URL = 'companies/login-config'
export const PRIVACY_POLICY_URL = 'companies/privacy-policy'

/* LEADERBOARD */
export const LEADERBOARD = 'leaderboard/leaderboard'
export const MY_RANGE_BELOW_TEN_URL = 'leaderboard/fan-leaderboard'

/* CHALLENGES */
export const CHALLENGES_URL = 'challenges/challenges-list'
export const CHALLENGES_JOIN_URL = 'challenges/join'
export const CHALLENGES_REMIND_ME_NOTIFICATION_URL = 'challenges/remind-me-notification'
export const CHALLENGES_LOG_DAY_URL = 'challenges/log-day'
export const CHALLENGES_REMIND_ON_START_URL = 'challenges/remind-on-start'
export const CHALLENGES_DELETE_REMIND_ON_START_URL = 'challenges/remind-on-start/{challengeId}'
export const CHALLENGES_LAST_DAY_TO_TRACK_URL = 'core/general'
export const MY_PLEDGES_LIST_URL = 'challenges/my-pledges'
export const CHALLENGE_PLEDGES_LIST_URL = 'challenges/pledges-homepage'
export const CHALLENGE_PLEDGE_DETAIL_URL = 'challenges/pledges/{pledgeId}'
export const COMMIT_PLEDGES_URL = 'challenges/commit-pledge'

/* SEARCHES HISTORY */
export const SEARCHES_HISTORY_URL = 'searchs/history'

/* QUIZ */
export const QUIZ_URL = 'quizzes/quiz/{id}'
export const QUIZ_ANSWER_URL = 'quizzes/quiz/answer'

/* SURVEY */
export const ANSWER_SURVEY_QUESTION_URL = 'companies/answer-survey-question'
export const SURVEYS_URL = 'companies/surveys/{id}'

/* CARPOOLING */
export const CARPOOLING_JOURNEY_URL = 'travel/carpooling/journeys'
export const CARPOOLING_JOURNEY_DELETE_URL = 'travel/carpooling/journeys/{id}'
export const CARPOOLING_JOURNEY_EDIT_URL = 'travel/carpooling/journeys/{id}'
export const CARPOOLING_USER_JOURNEYS_URL = 'travel/carpooling/user/journeys'
export const CARPOOLING_REVIEWS_LIST_URL = 'travel/carpooling/reviews'
export const CARPOOLING_REVIEWS_CREATE_URL = 'travel/carpooling/reviews'
export const MATCHES_JOURNEY_URL = 'travel/carpooling/matched-journeys/{id}'
export const CARPOOLING_CHATS_URL = 'travel/carpooling/chats'
export const CARPOOLING_MESSAGES_URL = 'travel/carpooling/messages'
export const CARPOOLING_REPORT_CHAT_DETAIL_URL = 'travel/carpooling/report-chat-detail'
export const AGREE_GOING_TOGETHER_URL = 'travel/carpooling/agree-going-together'

/* TRAVEL */
export const GAME_URL = 'travel/games'
export const TRAVEL_ARTICLES_URL = 'travel/travel-articles'
export const TRANSPORT_URL = 'travel/transports'
export const RECENT_ROUTES_URL = 'travel/recent-routes'
export const TRANSPORT_CEO2_URL = 'travel/transports-ceo2'
export const FAVOURITE_ROUTES_URL = 'travel/favourite-routes'
export const DELETE_FAVOURITE_ROUTES_URL = 'travel/favourite-routes/{id}'
export const TRAVEL_COMMUTE_URL = 'travel/commute'
export const CHANGE_TRACK_TRAVEL_URL = 'travel/change-legs'
export const TRAVEL_COMMUTE_DETAIL_URL = 'travel/commute/{id}'
export const LOG_ROUTE_URL = 'travel/log-route'
export const STATISTICS_FAN_URL = 'travel/ceo2-statistics/fan'
export const STATISTICS_EMPLOYEE_URL = 'travel/ceo2-statistics/employee'
export const DELETE_LEGS_URL = 'travel/delete-legs'
export const COMMUTE_START_END_URL = 'travel/commute-start-end'
export const CHANGE_LEG_URL = 'travel/change-leg/{id}'
export const LEG_TRANSPORT_URL = 'travel/leg-transport'
export const LEG_TRANSPORT_DETAILS_URL = 'travel/leg-transport-details'
export const LEG_START_END_URL = 'travel/leg-start-end'
export const MULTIPLE_SAVE_LEGS_URL = 'travel/multiple-save-legs'
export const COMMUTE_SAVE_URL = 'travel/commute-save'
export const PLAN_JOURNEY_URL = 'travel/plan-journey'
export const REMOVE_OR_OFFSET_URL = 'travel/remove-or-offset-emission'
export const TRANSPORT_MODES_URL = 'travel/transport-modes'
export const TRAVEL_CONTENT_BLOCK_URL = 'travel/content-block'
export const TRAVEL_ACCOMMODATION_URL = 'travel/accommodation'
export const TRAVEL_LAST_MULTI_DAY_SECTION_URL = 'travel/last-multi-day-section/{id}'
export const TRAVEL_CHECK_TRAVEL_DATES_VALID = 'travel/check-dates'
export const TRAVEL_AI_LOG_JOURNEY = 'travel/ai-log-journey'

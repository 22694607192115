import React from 'react'
import { useSelector } from 'react-redux'
import { RateStarIcon, UserDefaultIcon } from '../common/Svg'
import { getWhiteLabelingList } from '../../state/user/selectors'
import { getDateTime } from '../../utils/date'

export default function ReviewsCard({ item, itemUser }: any) {
    const { whiteLabeling } = useSelector(getWhiteLabelingList)

    return (
        <div key={item?.id} className="flex items-center justify-between mt-2">
            <div className="flex">
                {[1, 2, 3, 4, 5].map((i) => (
                    <div key={i}>
                        <button className="mr-2">
                            {i <= item?.rating ? (
                                <RateStarIcon color={whiteLabeling?.rocket} width={15} height={15} />
                            ) : (
                                <RateStarIcon color="#fff" width={15} height={15} />
                            )}
                        </button>
                    </div>
                ))}
            </div>

            <div className="w-full">
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        {itemUser?.uploadedImage ? (
                            <img
                                className="rounded-full w-[21px] h-[21px] object-cover mx-2.5"
                                src={itemUser?.uploadedImage}
                                alt=""
                            />
                        ) : (
                            <UserDefaultIcon className="w-[21px] h-[21px] mx-2" />
                        )}

                        <h1 className="font-bold text-12 text-cavolo">
                            {itemUser?.firstName} {itemUser?.lastName[0]}.
                        </h1>
                    </div>

                    <h1 className="font-normal text-12 text-cavolo">{getDateTime(item?.createdAt)}</h1>
                </div>

                <h1 className="text-12 font-normal text-black mt-1 leading-4 ml-2">{item?.comment}</h1>
            </div>
        </div>
    )
}

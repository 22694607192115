import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import Select, { GroupBase } from 'react-select'
import DatePicker from 'react-datepicker'
import { useLocation, useNavigate } from 'react-router-dom'
import Layout from '../../components/layout/Layout'
import { getSavedPlacesList, getUser, getWhiteLabelingList } from '../../state/user/selectors'
import Dropdown from '../../components/travel/DropDown'
import { ArrowIcon, DefaultHeartIcon } from '../../components/common/Svg'
import GoogleAutoComplete from '../../components/GoogleAutoComplete'
import { ColourOption, colourStyles } from '../../utils/organisationUnit'
import { formatDateJourney } from '../../utils/date'
import { Radio } from '../../components/common/Radio'
import { capitalizeFirstLetter, convertToTitleCase,
    options,
    optionsTravelling,
    preferenceOptions,
    preferencesData } from '../../utils/carpooling'
import SecondButton from '../../components/common/SecondButton'
import JourneyInfoModal from '../../components/carpooling/JourneyInfoModal'
import { carpoolingJourneyList } from '../../state/travel/selectors'
import { IGame } from '../../types/data'
import { editCarpoolingJourney, fetchCarpoolingJourney } from '../../state/travel/actions'

export default function CarpoolingEditJourney() {
    const dispatch = useDispatch()
    const { user } = useSelector(getUser)
    const navigate = useNavigate()
    const { savedPlaces } = useSelector(getSavedPlacesList)
    const location = useLocation()
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const { carpoolingJourney } = useSelector(carpoolingJourneyList)
    const carpoolingDetail = carpoolingJourney.find((item) => item.id === Number(location?.state?.id))
    const [t] = useTranslation('global')
    const [gameDate, setGameDate] = useState<IGame | null>(null)
    const [startPlace, setStartPlace] = useState({ placeId: '', lon: '', lat: '', name: '' })
    const [travellingAs, setTravellingAs] = useState<ColourOption | null>(null)
    const [isOpenLeavingDate, setIsOpenLeavingDate] = useState<boolean>(false)
    const [leavingDate, setLeavingDate] = useState<Date | null>(null)
    const [isOpenReturningDate, setIsOpenReturningDate] = useState<boolean>(false)
    const [returningDate, setReturningDate] = useState<Date | null>(null)
    const [returningOption, setReturningOption] = useState<string>('')
    const [flexibility, setFlexibility] = useState<ColourOption | null>(null)
    const [preferences, setPreferences] = useState({ smoking: '', verified: '', food: '', pets: '' })
    const [showInfoModal, setShowInfoModal] = useState<boolean>(false)

    const languageDataString = localStorage.getItem('language')
    const languageData = languageDataString ? JSON.parse(languageDataString) : 'en'

    useEffect(() => {
        if (carpoolingDetail?.leavingDate) {
            setLeavingDate(new Date(carpoolingDetail?.leavingDate))
        }
        if (carpoolingDetail?.returningDate) {
            setReturningDate(new Date(carpoolingDetail?.returningDate))
        }
        if (carpoolingDetail?.returningImmediately) {
            setReturningOption(t('carpooling.immediately-after-the-event').replace('[event]', user?.company?.travelEventName))
        }
        if (carpoolingDetail?.startPlaceId) {
            setStartPlace({
                placeId: carpoolingDetail?.startPlaceId,
                lat: carpoolingDetail?.startPlaceLat,
                lon: carpoolingDetail?.startPlaceLon,
                name: carpoolingDetail?.startPlaceName,
            })
        }
        if (carpoolingDetail?.travellingAs) {
            const selectedOption = optionsTravelling(t).find((option) => option.value === carpoolingDetail?.travellingAs)
            setTravellingAs(selectedOption || null)
        }
        if (carpoolingDetail?.flexibility) {
            const selectedOption = options(t).find((option) => option.value === carpoolingDetail?.flexibility)
            setFlexibility(selectedOption || null)
        }
        if (carpoolingDetail?.smoking) {
            setPreferences({
                smoking: convertToTitleCase(capitalizeFirstLetter(carpoolingDetail?.smoking)),
                pets: convertToTitleCase(capitalizeFirstLetter(carpoolingDetail?.pets)),
                food: convertToTitleCase(capitalizeFirstLetter(carpoolingDetail?.food)),
                verified: '',
            })
        }
        if (carpoolingDetail?.game) {
            setGameDate(carpoolingDetail?.game)
        }
    }, [carpoolingDetail?.travellingAs, carpoolingDetail?.game, carpoolingDetail?.leavingDate, carpoolingDetail?.returningDate, carpoolingDetail?.returningImmediately, carpoolingDetail?.startPlaceId, carpoolingDetail?.startPlaceLat, carpoolingDetail?.startPlaceLon, carpoolingDetail?.startPlaceName, t, user?.company?.travelEventName, carpoolingDetail?.flexibility, carpoolingDetail?.smoking, carpoolingDetail?.pets, carpoolingDetail?.food])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

        const handlePopState = () => {
            setShowInfoModal(true)
            window.history.pushState(null, document.title, window.location.pathname)
        }

        window.addEventListener('popstate', handlePopState)
        window.history.pushState(null, document.title, window.location.pathname)
        return () => {
            window.removeEventListener('popstate', handlePopState)
        }
    }, [dispatch])

    const handleSelectGameChange = (selectedOption: any) => {
        setGameDate(selectedOption?.item)
    }

    const handleSelectTravellingAsChange = (selectedOption: ColourOption | null) => {
        setTravellingAs(selectedOption)
    }

    const handleChangeLeavingDate = (date: Date) => {
        setLeavingDate(date)
    }

    const handleReturningOption = () => {
        setReturningOption(t('carpooling.immediately-after-the-event').replace('[event]', user?.company?.travelEventName))
        setIsOpenReturningDate(false)
        setReturningDate(null)
    }

    const handleChangeReturningDate = (date: Date) => {
        setReturningDate(date)
        setReturningOption('')
    }

    const handleSelectFlexibility = (selectedOption: any) => {
        setFlexibility(selectedOption)
    }

    const handleChange = (key: string, value: string) => {
        setPreferences((prev) => ({ ...prev, [key]: value }))
    }

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            event.preventDefault()
            event.returnValue = ''
        }

        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])

    const onSubmit = () => {
        dispatch(editCarpoolingJourney(carpoolingDetail?.id, {
            game: gameDate?.id,
            startPlaceId: startPlace?.placeId,
            startPlaceName: startPlace?.name,
            startPlaceLon: startPlace?.lon,
            startPlaceLat: startPlace?.lat,
            travellingAs: travellingAs?.value,
            leavingDate,
            returningDate,
            returningImmediately: !!returningOption,
            flexibility: flexibility?.value,
            smoking: preferences?.smoking?.toLowerCase()?.toLowerCase().replace(/\s+/g, '_'),
            food: preferences?.food?.toLowerCase()?.toLowerCase().replace(/\s+/g, '_'),
            pets: preferences?.pets?.toLowerCase()?.toLowerCase().replace(/\s+/g, '_'),
        }, (response) => {
            navigate(`/carpooling-journeys/your-matches/${response?.id}`)
            dispatch(fetchCarpoolingJourney(true))
        }))
    }

    return (
        <Layout showFooter={false}>
            <div className="w-full min-h-screen">
                <style>
                    {`
                        .disable-radio-style input[type='radio'] {
                            outline: none;
                            appearance: none;
                            width: 24px;
                            height: 24px;
                            padding: 3px;
                        }
                        
                        .disable-radio-style input[type="radio"]:checked {
                            width: 24px;
                            height: 24px;                               
                            background: ${(whiteLabeling as any)?.grey01} content-box;
                        }
                        
                        .btnd:active,
                        .btnd:focus,
                        .btnd:focus:active {
                            background-image: none;
                            outline: 0;
                            box-shadow: none;
                        }
                        
                        .radio-style input[type='radio'] {
                            outline: none;
                            appearance: none;
                            width: 24px;
                            height: 24px;
                            padding: 3px;
                        }
                        
                        .radio-style input[type="radio"]:checked {
                            width: 24px;
                            height: 24px;
                            background: ${(whiteLabeling as any)?.rocket} content-box;
                        }
                    `}
                </style>

                <div
                    className="pb-[100px] flex flex-col items-center mobile:mt-[54px] mt-[12px] mobile:px-[42px] px-[16px]">
                    <div className="max-w-[600px] w-full">
                        <h1 className="heading-uppercase text-22 tracking-tight font-semibold text-cavolo mt-2">
                            {t('carpooling.edit-journey')}
                        </h1>

                        <h1 className="font-normal not-heading-font text-cavolo text-14 mt-2">
                            {t('carpooling.update-your-journey–details')}
                        </h1>

                        <h1 className="font-medium not-heading-font text-cavolo text-18 mt-2">
                            {t('carpooling.select-event-you-are-attending').replace('[event]', user?.company?.travelEventName)}
                        </h1>

                        <div className="mt-[7px]">
                            <Dropdown carpooling handleSelectChange={handleSelectGameChange} defaultGameId={gameDate?.id || carpoolingDetail?.game?.id} />
                        </div>

                        <h1 className="font-medium not-heading-font text-cavolo text-18 mt-4">
                            {t('global.starting-from')}
                        </h1>

                        <h1 className="font-normal not-heading-font text-cavolo text-14 my-1.5">
                            {t('carpooling.this-will-be-visible-to-others-so-we-suggest-your-road-name-or-postcode-not-your-exact-address')}
                        </h1>

                        {isEmpty(savedPlaces) && user?.company?.showSavedPlaces ? (
                            <div className="flex items-center mb-2.5">
                                <DefaultHeartIcon />

                                <h1 className="ml-2.5 text-12 text-grey01">
                                    {t('savedPlaces.tap-the-heart-to-save-a-place-for-future-use')}
                                </h1>
                            </div>
                        ) : null}

                        <GoogleAutoComplete placeholder={t('log.start-typing')} setPlace={setStartPlace}
                            defaultValue={startPlace} />

                        <h1 className="font-medium not-heading-font text-cavolo text-18 mt-4 mb-1.5">
                            {t('carpooling.travelling-as')}
                        </h1>

                        <Select<ColourOption, false, GroupBase<ColourOption>>
                            value={travellingAs}
                            maxMenuHeight={200}
                            options={optionsTravelling(t)}
                            placeholder={t('log.select-an-option')}
                            styles={colourStyles(whiteLabeling)}
                            isSearchable={false}
                            onChange={handleSelectTravellingAsChange}
                        />

                        <h1 className="font-medium not-heading-font text-cavolo text-18 mt-2">
                            {t('carpooling.leaving-date-and-time')}
                        </h1>

                        <div onClick={() => setIsOpenLeavingDate(!isOpenLeavingDate)}
                            className="flex items-center justify-between h-[48px] border border-avocado rounded-lg cursor-pointer mt-1.5 pr-4">
                            <h1 className="ml-[14px]">
                                {!leavingDate ? (
                                    <h1 className="text-grey01">Select date and
                                        time
                                    </h1>
                                ) : (formatDateJourney(leavingDate || new Date()))}
                            </h1>

                            <ArrowIcon />
                        </div>

                        {isOpenLeavingDate && (
                            <div className="rounded-[4px] border w-full mt-2 pb-4"
                                style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.10)' }}>
                                <div className="flex justify-center mt-4">
                                    <DatePicker
                                        selected={leavingDate}
                                        onChange={handleChangeLeavingDate}
                                        inline
                                        dateFormat="dd/mm/yyyy"
                                        locale={languageData}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={10}
                                        timeCaption="Time"
                                    />
                                </div>
                            </div>
                        )}

                        <div>
                            <h1 className="font-medium not-heading-font text-cavolo text-18 mt-4 mb-1.5">
                                {t('carpooling.returning-date-and-time')}
                            </h1>
                        </div>

                        <div onClick={() => setIsOpenReturningDate(!isOpenReturningDate)}
                            className="flex items-center justify-between h-[48px] border border-avocado rounded-lg cursor-pointer mt-1.5 pr-4">

                            {returningDate ? (
                                <h1 className="ml-[14px] text-cavolo text-16 font-normal">
                                    {formatDateJourney(returningDate || new Date())}
                                </h1>
                            ) : returningOption ? (
                                <h1 className="ml-[14px] text-cavolo text-16 font-normal">
                                    {returningOption}
                                </h1>
                            ) : (
                                <h1 className="text-grey01 text-16 font-normal ml-[14px]">
                                    {t('log.select-an-option')}
                                </h1>
                            )}

                            <ArrowIcon />
                        </div>

                        {isOpenReturningDate && (
                            <div className="rounded-[4px] border w-full mt-2 pb-4"
                                style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.10)' }}>
                                <div onClick={handleReturningOption}
                                    className={`flex items-center h-[48px] border-t border-b w-full mt-1 pl-[14px] ${returningDate && 'bg-white text-cavolo'} ${returningOption && 'bg-spinach text-white'} cursor-pointer hover:bg-spinach hover:text-white`}>
                                    <h1>
                                        {t('carpooling.immediately-after-the-event').replace('[event]', user?.company?.travelEventName)}
                                    </h1>
                                </div>

                                <div className="flex justify-center mt-4">
                                    <DatePicker
                                        selected={returningDate}
                                        onChange={handleChangeReturningDate}
                                        inline
                                        dateFormat="dd/mm/yyyy"
                                        locale={languageData}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={10}
                                        timeCaption="Time"
                                    />
                                </div>
                            </div>
                        )}

                        <h1 className="font-medium not-heading-font text-cavolo text-18 mt-4 mb-1.5">
                            {t('carpooling.flexibility')}
                        </h1>

                        <Select<ColourOption, false, GroupBase<ColourOption>>
                            value={flexibility}
                            maxMenuHeight={200}
                            options={options(t)}
                            placeholder={t('log.select-an-option')}
                            styles={colourStyles(whiteLabeling)}
                            isSearchable={false}
                            onChange={handleSelectFlexibility}
                        />

                        <div>
                            <h1 className="font-medium not-heading-font text-cavolo text-18 mt-4 mb-1.5">
                                {t('carpooling.preferences')}
                            </h1>

                            <div className="grid grid-cols-4 items-center gap-4">
                                <div />

                                {preferenceOptions(t)?.map((option) => (
                                    <div key={option} className="text-center text-14 font-normal text-cavolo">
                                        {option}
                                    </div>
                                ))}

                                {preferencesData(t).map((item) => (
                                    <React.Fragment key={item?.id}>
                                        <div
                                            className={`text-14 font-normal ${item?.disabled ? 'text-grey01' : 'text-cavolo'}`}>
                                            {item.label}
                                        </div>

                                        {preferenceOptions(t)?.map((option) => (
                                            <div className="text-center">
                                                <label
                                                    className={`flex items-center justify-center cursor-pointer ${item.disabled ? 'opacity-50 pointer-events-none' : ''}`}>
                                                    <Radio
                                                        inputClassName={item.disabled ? 'border-grey01' : 'border-rocket'}
                                                        containerClassName={`w-6 h-6 ${item?.disabled ? 'disable-radio-style' : 'radio-style'} `}
                                                        key={item?.id}
                                                        label=""
                                                        value={option}
                                                        checked={preferences[item.key as keyof typeof preferences] === option}
                                                        onChange={() => handleChange(item.key, option)}
                                                    />
                                                </label>
                                            </div>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>

                        <SecondButton
                            onClick={onSubmit}
                            text={t('carpooling.update-and-view-matches')}
                            className="mt-4 w-full h-[48px] font-bold bg-rocket rounded-md text-16 text-pistachio"
                        />

                        <SecondButton
                            onClick={() => {
                                setShowInfoModal(true)
                            }}
                            text={t('global.cancel')}
                            className="mt-2 w-full h-[48px] font-bold border border-cavolo rounded-md text-16 text-cavolo"
                        />
                    </div>
                </div>
            </div>

            <JourneyInfoModal
                title={t('carpooling.are-you-sure-you-want-to-leave-editing-your-journey')}
                showDeleteModal={showInfoModal}
                setShowDeleteModal={setShowInfoModal}
            />
        </Layout>
    )
}

import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import Layout from '../../components/layout/Layout'
import { PlusAddLeg } from '../../components/common/Svg'
import JourneyCard from '../../components/carpooling/JourneyCard'
import { fetchCarpoolingJourney } from '../../state/travel/actions'
import { carpoolingJourneyList } from '../../state/travel/selectors'
import { Carpooling } from '../../types/data'
import { switchDataJourney } from '../../utils/carpooling'

export default function CarpoolingJourneys() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { carpoolingJourney } = useSelector(carpoolingJourneyList)
    const [t] = useTranslation('global')
    const [showSwitch, setShowSwitch] = useState<number>(0)

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    const switchSelectorFunc = (index: number) => {
        setShowSwitch(index)
        dispatch(fetchCarpoolingJourney(index === 0 && true))
    }

    const onRestartLoad = useCallback(() => {
        dispatch(fetchCarpoolingJourney(true))
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    return (
        <Layout>
            <div className="w-full min-h-screen">
                <div className="pb-[100px] flex flex-col items-center mobile:mt-[54px] mt-[12px] mobile:px-[42px] px-[16px]">
                    <div className="max-w-[600px] w-full">
                        <h1 className="heading-uppercase text-22 tracking-tight font-semibold text-cavolo mt-2">
                            {t('carpooling.my-journeys')}
                        </h1>

                        <div className="flex justify-between mt-2">
                            {switchDataJourney(t)?.map((item, index) => (
                                <div onClick={() => switchSelectorFunc(index)} key={item?.id}
                                    className={`flex items-center justify-center w-[47%] h-[31px] transition-all duration-300 rounded-md cursor-pointer ${showSwitch === index ? item?.backgroundActiveColor : item?.backgroundDefaultColor} ${showSwitch === index ? item?.borderActiveColor : item?.borderDefaultColor}`}>
                                    <h1 className={`font-semibold not-heading-font text-14 transition-colors duration-300 text-marrow ${showSwitch === index ? item?.textActiveColor : item?.textDefaultColor}`}>
                                        {item?.name}
                                    </h1>
                                </div>
                            ))}
                        </div>

                        {isEmpty(carpoolingJourney) ? (
                            <h1 className="mt-2 font-normal text-14 not-heading-font text-cavolo">
                                {t('carpooling.no-active-journeys')}
                            </h1>
                        ) : (
                            carpoolingJourney?.map((item: Carpooling) => (
                                <div key={item?.id}>
                                    <JourneyCard item={item} active={showSwitch === 0 ? !!carpoolingJourney : !carpoolingJourney} />
                                </div>
                            ))
                        )}

                        {showSwitch === 0 && (
                            <div>
                                <div className="flex items-center cursor-pointer mt-6">
                                    <PlusAddLeg />

                                    <h1 onClick={() => {
                                        navigate('/carpooling-journeys/new-journey')
                                        sessionStorage.removeItem('carpoolingJourneyData')
                                        sessionStorage.removeItem('carpoolingJourneyDetail')
                                    }} className="text-14 font-normal text-cavolo not-heading-font ml-1.5">
                                        {t('carpooling.add–a-journey')}
                                    </h1>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder'
import { useNavigate } from 'react-router-dom'
import Layout from '../../components/layout/Layout'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { Context } from '../../components/common/BaseContext'
import { getHomePageLists } from '../../state/home/selectors'
import { LoadingIcon, SpeakerIcon, StopIcon } from '../../components/common/Svg'
import { AudioVisualizer } from '../../components/travel/AudioVisualizer'
import { fetchLogJourneyAI } from '../../state/travel/actions'
import { saveCommuteInstanceToLocalstorage } from '../../utils/travel'

export default function LogTravelAIVoiceListening() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { navbarHeight, screenWidth } = useContext(Context)
    const [t] = useTranslation('global')
    const [analyser, setAnalyser] = useState<AnalyserNode | null>(null)
    const [audioFile, setAudioFile] = useState<File | null>(null)
    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Log')

    const [messageAudioUrl, setMessageAudioUrl] = useState<string | undefined>(undefined)
    const [previousUserInput, setPreviousUserInput] = useState<string | undefined>(undefined)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [mediaStream, setMediaStream] = useState<MediaStream | null>(null)
    const messageAudioRef = useRef<HTMLAudioElement>(null)

    const travelData = JSON.parse(localStorage.getItem('travelData') || '{}')

    const [loading, setLoading] = useState(false)

    const recorderControls = useAudioRecorder()

    const addAudioElement = (blob: Blob) => {
        const file = new File([blob], 'recording.mp3', { type: 'audio/mp3' })
        setAudioFile(file)
    }

    useEffect(() => {
        const initAudio = async () => {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
            setMediaStream(stream)
            const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)()
            const source = audioContext.createMediaStreamSource(stream)

            const analyserNode = audioContext.createAnalyser()
            analyserNode.fftSize = 256
            source.connect(analyserNode)

            setAnalyser(analyserNode)
            recorderControls.startRecording()
        }

        initAudio()

        return () => {
            if (analyser) {
                analyser.disconnect()
            }
            mediaStream?.getTracks().forEach((track) => track.stop())
        }
        // eslint-disable-next-line
    }, [])

    const onSubmit = async () => {
        recorderControls.stopRecording()
        setLoading(true)

        if (analyser) {
            analyser.disconnect()
        }

        if (mediaStream) {
            mediaStream.getTracks().forEach((track) => track.stop())
            setMediaStream(null)
        }
    }

    useEffect(() => {
        const submitAudio = () => {
            if (audioFile) {
                setLoading(true)

                dispatch(fetchLogJourneyAI({
                    audio: audioFile,
                    previousUserInput,
                    errorMessage,
                    dates: travelData?.date,
                    games: travelData?.gameIds,
                }, (response) => {
                    setLoading(false)
                    navigate('/log/track-travel-overview', { state: { fromLogScreen: true, defaultTile: !response?.returnJourneyStartPlaceId ? 'one-way' : '' } })
                    window.location.reload()

                    saveCommuteInstanceToLocalstorage(response, {
                    // gameIds,
                    // gameDate,
                    }, {}, '')
                }, (error) => {
                    if (error?.response?.data?.messageAudio) {
                        const audioBlob = new Blob([Uint8Array.from(atob(error?.response?.data?.messageAudio), (c) => c.charCodeAt(0))], {
                            type: 'audio/mpeg',
                        })

                        const url = URL.createObjectURL(audioBlob)
                        setMessageAudioUrl(url)
                        setAudioFile(null)
                        setPreviousUserInput(error?.response?.data?.previousUserInput)
                        setErrorMessage(error?.response?.data?.message)

                        // Reinitialize audio and start recording again
                        const initAudioAgain = async () => {
                            const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
                            setMediaStream(stream)

                            const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)()
                            const source = audioContext.createMediaStreamSource(stream)

                            const analyserNode = audioContext.createAnalyser()
                            analyserNode.fftSize = 256
                            source.connect(analyserNode)

                            setAnalyser(analyserNode)
                            recorderControls.startRecording()
                        }

                        initAudioAgain()
                        setLoading(false)
                    }
                }))
                // navigate('/')
            }
        }

        submitAudio()
    }, [audioFile, dispatch, navigate])

    useEffect(() => {
        if (messageAudioUrl && messageAudioRef.current) {
            // @ts-ignore
            messageAudioRef.current.play().catch((error: any) => {
                console.error('Error playing audio:', error)
            })

            messageAudioRef.current.onended = () => {
                setLoading(false)
                setMessageAudioUrl(undefined)
                recorderControls.startRecording()
            }
        }
    }, [messageAudioUrl])

    return (
        <Layout showFooter={false}>
            <audio style={{ display: 'none' }} ref={messageAudioRef} src={messageAudioUrl} controls />

            <div className="w-full">
                {sponsoredBlock && <div className="mb-3 mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}

                <div style={{ marginTop: screenWidth > 768 ? navbarHeight : 0 }}
                    className={`flex flex-col items-center ${screenWidth < 768 && 'pt-5'} mobile:px-[42px] px-4 h-screen`}>
                    <div className="max-w-[600px] w-full pb-[300px]">
                        <h1 className="font-bold text-22 text-cavolo tracking-tight">
                            {t('log.i\'m-listening')}
                        </h1>

                        {!analyser ? (
                            <div className="w-full h-[100px] mt-10" />
                        ) : (
                            <div className="mt-10 w-full">
                                {analyser && <AudioVisualizer analyser={analyser} />}
                            </div>
                        )}

                        <div className="hidden">
                            <AudioRecorder
                                onRecordingComplete={(blob) => addAudioElement(blob)}
                                recorderControls={recorderControls} />
                        </div>

                        <h1 className="font-normal text-16 text-cavolo mt-[45px]">
                            {t('log.use-your-own-words-to-describe-your-journey')}
                        </h1>

                        <h1 className="mt-4 font-normal text-16 text-cavolo">
                            {t('log.try-and-be-as-precise-as-possible-for-example-say-i-walked-to-leeds-station-instead-of-i-went-to-the-station')}
                        </h1>

                        <h1 className="mt-4 font-normal text-16 text-cavolo">
                            {t('log.when-you\'re-finished-press-the-button-to-stop-recording')}
                        </h1>

                        <div onClick={onSubmit}
                            className="cursor-pointer mt-[45px] flex items-center justify-center ml-5">
                            {loading ? (
                                <LoadingIcon />
                            ) : messageAudioUrl ? (
                                <SpeakerIcon />
                            ) : (
                                <StopIcon />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

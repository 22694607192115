import React, { useContext, useRef } from 'react'
import cn from 'classnames'
import { ContentBlockPositions, ILandingPageData } from '../types/data'
import Button from './common/Button'
import SponsoredByBlock from './SponsoredByBlock'
import ArticleModal from './article/ArticleModal'
import { Context } from './common/BaseContext'
import ContentBlockTitle from './ContentBlockTitle'

interface IProps {
    item: ContentBlockPositions | ILandingPageData
}

export default function ContentBlockComponent({ item }: IProps) {
    const containerRef = useRef<HTMLDivElement | null>(null)
    const { showArticleModal, setShowArticleModal } = useContext(Context)

    if (item.contentBlock.type === 'single_block') {
        const subBlock = item.contentBlock.subBlocks[0]

        return (
            <div>
                {subBlock?.type === 'large' ? (
                    <div className={`${subBlock?.isFullWidth ? 'w-full' : 'max-mobile:mx-4'}`}>
                        <ContentBlockTitle text={item.contentBlock.title} />

                        <style>
                            {`
                                .line-clamp-five {
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    -webkit-line-clamp: 5;
                                    line-clamp: 5;
                                }
                            `}
                        </style>

                        <div className="cursor-pointer w-full" style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))', marginTop: item.contentBlock.title ? 12 : 0 }}>
                            {subBlock.picture && <img className="rounded-t-lg mobile:h-[363px] h-auto w-full object-cover" src={subBlock.picture} alt="" />}

                            <div className="card">
                                <div className={cn('rounded-b-lg', { 'rounded-t-lg': !subBlock.picture })} style={{ background: subBlock.backgroundColor }}>
                                    {subBlock.sponsorshipBlock && <SponsoredByBlock noBorder noBackground item={subBlock.sponsorshipBlock} />}

                                    <div style={{ backgroundColor: subBlock?.backgroundColour }} className="mobile:px-5 px-4 mobile:pb-5 pt-5 pb-4">
                                        <h5 className="flex mt-0.5 font-medium font tracking-tight text-darken text-18">
                                            <ContentBlockTitle text={subBlock.title} />
                                        </h5>

                                        {subBlock.description && (
                                            <p className="flex mt-0.5 font-light tracking-tight text-grey01 text-14">
                                                {subBlock.description}
                                            </p>
                                        )}

                                        {subBlock.bodyButtonText && (
                                            <Button
                                                hover
                                                className="border-0"
                                                text={subBlock.bodyButtonText}
                                                onClick={() => {
                                                    if (subBlock?.overlayArticle) {
                                                        setShowArticleModal(subBlock?.overlayArticle || 0)
                                                    } else if (subBlock.bodyButtonUrl) {
                                                        window.location.href = subBlock.bodyButtonUrl
                                                    }
                                                }} />
                                        )}

                                        {subBlock?.leftButtonText && subBlock?.rightButtonText && (
                                            <div className={cn('flex', { 'mb-4': subBlock.footer })}>
                                                <div className="mr-2 w-full">
                                                    <Button
                                                        className="border-0 bg-pistachio text-rocket"
                                                        textColor=""
                                                        hoverBackground="rgba(156, 60, 45, 1)"
                                                        text={subBlock.leftButtonText}
                                                        onClick={(e: any) => {
                                                            if (typeof e?.preventDefault === 'function') e.preventDefault()

                                                            if (subBlock.leftButtonUrl) {
                                                                window.location.href = subBlock.leftButtonUrl
                                                            }
                                                        }} />
                                                </div>

                                                <div className="ml-2 w-full">
                                                    <Button
                                                        className="border-0 bg-rocket text-pistachio"
                                                        textColor=""
                                                        hoverBackground="rgba(156, 60, 45, 1)"
                                                        text={subBlock.rightButtonText}
                                                        onClick={(e: any) => {
                                                            if (typeof e?.preventDefault === 'function') e.preventDefault()

                                                            if (subBlock.rightButtonUrl) {
                                                                window.location.href = subBlock.rightButtonUrl
                                                            }
                                                        }} />
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {subBlock.footer && (
                                        <div onClick={() => setShowArticleModal(subBlock?.footerOverlayArticle || 0)}>
                                            <div className="bg-lime h-1" />

                                            <div className="items-end flex flex-col mobile:px-5 px-4 pb-3 rounded-b-lg pt-3" style={{ backgroundColor: subBlock?.footerBackgroundColour }}>
                                                {/* eslint-disable-next-line react/no-danger */}
                                                <span dangerouslySetInnerHTML={{ __html: subBlock.footer || '' }} />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        style={{ filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))' }}
                        className={`${subBlock?.isFullWidth ? 'w-full' : 'max-mobile:mx-4'} cursor-pointer flex`}
                        ref={containerRef}
                        onClick={() => {
                            if (subBlock?.overlayArticle) {
                                setShowArticleModal(subBlock?.overlayArticle || 0)
                            } else if (subBlock.bodyButtonUrl) {
                                window.location.href = subBlock.bodyButtonUrl
                            }
                        }}
                    >
                        {subBlock.picture && (
                            <div className="w-[150px]">
                                <img
                                    src={subBlock.picture}
                                    className="object-cover w-full h-full rounded-tl-lg rounded-bl-lg"
                                    alt=""
                                />
                            </div>
                        )}

                        <div style={{ backgroundColor: subBlock?.backgroundColour }} className="w-full bg-[#fefde3] rounded-r-lg flex items-center px-4 py-[12px]">
                            <div>
                                <p style={{ lineHeight: '21.12px' }} className="line-clamp-five font text-16 font-medium text-black mt-1">
                                    <ContentBlockTitle text={subBlock?.title} />
                                </p>

                                <p className="font-normal text-12 text-grey01">
                                    {subBlock.description}
                                </p>
                            </div>
                        </div>
                    </div>
                )}

                {showArticleModal && showArticleModal === subBlock?.overlayArticle ? (
                    <ArticleModal setShowModal={setShowArticleModal} articleId={subBlock?.overlayArticle} />
                ) : undefined}

                {showArticleModal && showArticleModal === subBlock?.footerOverlayArticle ? (
                    <ArticleModal setShowModal={setShowArticleModal} articleId={subBlock?.footerOverlayArticle} />
                ) : undefined}
            </div>
        )
    }

    return <div />
}

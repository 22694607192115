import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Context } from '../common/BaseContext'
import { DismissIcon } from '../common/Svg'
import SecondButton from '../common/SecondButton'
import { ILeg, ILegs } from '../../types/data'
import { changeCommuteDestination, removeTravelLeg } from '../../state/travel/actions'
import { getUser } from '../../state/user/selectors'
import { legTransportList } from '../../state/travel/selectors'

interface IProps {
    isShown?: boolean
    onContinue: () => void
    returnLeg?: boolean
    outboundLeg?: boolean
    leg?: ILegs
    title: string
    overviewLeg?: boolean
    goingBackCommute?: boolean
    legs: any
}

export default function DeleteLegModal({ isShown = false, legs, onContinue, goingBackCommute, returnLeg, outboundLeg, leg, title, overviewLeg }: IProps) {
    const dispatch = useDispatch()
    const { screenWidth } = useContext(Context)
    const [t] = useTranslation('global')

    const outboundData = JSON.parse(localStorage.getItem('travelData') || '{}')
    const returnJourneyData = JSON.parse(localStorage.getItem('returnTravelData') || '{}')

    const deleteOutboundLegs = outboundData?.legs?.map((item: ILeg) => item?.id).join(',')
    const deleteReturnLegs = returnJourneyData?.legs?.map((item: ILeg) => item?.id).join(',')
    const { commuteStartEnd } = useSelector(legTransportList)

    const { user } = useSelector(getUser)

    const afterLegDelete = () => {
        if (!leg) return

        dispatch(removeTravelLeg(leg?.combinedLegs?.map((i) => i.id || 0) || 0, onContinue))
    }

    const deleteLeg = () => {
        if (outboundLeg) {
            dispatch(removeTravelLeg(deleteOutboundLegs))
            localStorage.removeItem('travelData')
            localStorage.removeItem('places')
        }

        if (returnLeg) {
            dispatch(removeTravelLeg(deleteReturnLegs))
            localStorage.removeItem('returnTravelData')
        }

        if (overviewLeg && leg) {
            const legIndex = legs.findIndex((obj: ILegs) => obj.backendLegId === leg.backendLegId)

            if (legIndex === 0 && legs.length > 1) {
                const secondLeg = legs[1]

                let commuteStartEndData = {
                    startPlaceId: outboundData.startPlaceId,
                    startPlaceName: outboundData.startPlaceName,
                    startPlaceLon: outboundData.startPlaceLon,
                    startPlaceLat: outboundData.startPlaceLat,
                    endPlaceId: outboundData.endPlaceId,
                    endPlaceName: outboundData.endPlaceName,
                    endPlaceLon: outboundData.endPlaceLon,
                    endPlaceLat: outboundData.endPlaceLat,
                    returnJourneyStartPlaceId: returnJourneyData.startPlaceId,
                    returnJourneyStartPlaceName: returnJourneyData.startPlaceName,
                    returnJourneyStartPlaceLon: returnJourneyData.startPlaceLon,
                    returnJourneyStartPlaceLat: returnJourneyData.startPlaceLat,
                    returnJourneyEndPlaceId: returnJourneyData.endPlaceId,
                    returnJourneyEndPlaceName: returnJourneyData.endPlaceName,
                    returnJourneyEndPlaceLon: returnJourneyData.endPlaceLon,
                    returnJourneyEndPlaceLat: returnJourneyData.endPlaceLat,
                }

                if (!goingBackCommute) {
                    commuteStartEndData = {
                        ...commuteStartEndData,
                        startPlaceId: secondLeg.startPlaceId,
                        startPlaceName: secondLeg.startPlaceName,
                        startPlaceLon: secondLeg.startPlaceLon,
                        startPlaceLat: secondLeg.startPlaceLat,
                    }
                }

                if (goingBackCommute) {
                    commuteStartEndData = {
                        ...commuteStartEndData,
                        returnJourneyStartPlaceId: secondLeg.startPlaceId,
                        returnJourneyStartPlaceName: secondLeg.startPlaceName,
                        returnJourneyStartPlaceLon: secondLeg.startPlaceLon,
                        returnJourneyStartPlaceLat: secondLeg.startPlaceLat,
                    }
                }

                dispatch(changeCommuteDestination(outboundData, outboundData.backendCommuteId || commuteStartEnd.id, commuteStartEndData, () => {
                    afterLegDelete()
                }))
            } else if (legIndex + 1 === legs.length && !user.company.isTravelTypeEvent && legs.length > 1) {
                const secondLastLeg = legs[legs.length - 2]

                let commuteStartEndData = {
                    startPlaceId: outboundData.startPlaceId,
                    startPlaceName: outboundData.startPlaceName,
                    startPlaceLon: outboundData.startPlaceLon,
                    startPlaceLat: outboundData.startPlaceLat,
                    endPlaceId: outboundData.endPlaceId,
                    endPlaceName: outboundData.endPlaceName,
                    endPlaceLon: outboundData.endPlaceLon,
                    endPlaceLat: outboundData.endPlaceLat,
                    returnJourneyStartPlaceId: returnJourneyData.startPlaceId,
                    returnJourneyStartPlaceName: returnJourneyData.startPlaceName,
                    returnJourneyStartPlaceLon: returnJourneyData.startPlaceLon,
                    returnJourneyStartPlaceLat: returnJourneyData.startPlaceLat,
                    returnJourneyEndPlaceId: returnJourneyData.endPlaceId,
                    returnJourneyEndPlaceName: returnJourneyData.endPlaceName,
                    returnJourneyEndPlaceLon: returnJourneyData.endPlaceLon,
                    returnJourneyEndPlaceLat: returnJourneyData.endPlaceLat,
                }

                if (!goingBackCommute) {
                    commuteStartEndData = {
                        ...commuteStartEndData,
                        endPlaceId: secondLastLeg.endPlaceId,
                        endPlaceName: secondLastLeg.endPlaceName,
                        endPlaceLon: secondLastLeg.endPlaceLon,
                        endPlaceLat: secondLastLeg.endPlaceLat,
                    }
                }

                if (goingBackCommute) {
                    commuteStartEndData = {
                        ...commuteStartEndData,
                        returnJourneyEndPlaceId: secondLastLeg.endPlaceId,
                        returnJourneyEndPlaceName: secondLastLeg.endPlaceName,
                        returnJourneyEndPlaceLon: secondLastLeg.endPlaceLon,
                        returnJourneyEndPlaceLat: secondLastLeg.endPlaceLat,
                    }
                }

                dispatch(changeCommuteDestination(outboundData, outboundData.backendCommuteId || commuteStartEnd.id, commuteStartEndData, () => {
                    afterLegDelete()
                }))
            } else if (legIndex === 0 && legs.length === 1) {
                afterLegDelete()
            } else {
                afterLegDelete()
            }
        }
    }

    return (
        <div>
            {isShown && (
                <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                    <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                    <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                        <div className="bg-cloud relative xs-mobile:w-[500px] w-11/12 mobile:px-4 px-3 rounded-[16px]" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                            <div onClick={onContinue} className="cursor-pointer mobile:w-[36px] mobile:h-[36px] w-[24px] h-[24px] flex items-center justify-center mt-[16px]">
                                <DismissIcon width={screenWidth >= 768 ? 20 : 13} height={screenWidth >= 768 ? 20 : 13} />
                            </div>

                            <div className="flex flex-col justify-center items-center pb-[32px]">
                                <div className="xs-mobile:w-[296px] w-[242px] flex flex-col items-center justify-center">
                                    <h1 className="text-center mobile:text-22 text-18 text-darken font-medium tracking-[-0.02rem] font">
                                        {title}
                                    </h1>
                                </div>

                                <div className="flex justify-between w-[249px] mt-[32px]">
                                    <SecondButton onClick={deleteLeg} text={t('log.yes')} className="w-[89px] h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200" />

                                    <button
                                        onClick={onContinue}
                                        className="border-2 border-solid border-rocket bg-mint hover:text-spinach hover:ease-in hover:duration-200 hover:bg-[#A7D49D] w-[136px] h-[48px] font-semibold rounded-md text-14 text-marrow">
                                        {t('log.no')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

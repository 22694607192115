import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { ISurvey } from '../../types/data'

interface IProps {
    activeQuestionIndex: number
    totalQuestionsCount: number
    card?: boolean
    survey?: boolean
    surveyDetail: ISurvey
}

export default function SurveyDetailHeader({ surveyDetail, activeQuestionIndex, totalQuestionsCount, card, survey }: IProps) {
    return (
        <div className={`${card && 'mobile:mx-[42px] mx-4'}`}>
            <div className="flex justify-center">
                <div className={`${(card || surveyDetail?.display === 'full') && 'mt-[31px]'} w-full items-center justify-between`}>
                    {(card || surveyDetail?.display === 'full') && (
                        <div className="flex items-center">
                            <p className="px-2 w-screen left-0 text-center text-cavolo text-18 font font-medium tracking-[-0.02rem] leading-[132%]">
                                {surveyDetail.title}
                            </p>

                            <p className="min-w-[20px] text-black text-12 font-bold tracking-[-0.02rem] leading-[150%] mr-2 max-mobile:block hidden">
                                {surveyDetail?.id ? activeQuestionIndex + 1 : 0}/{totalQuestionsCount}
                            </p>
                        </div>
                    )}

                    {!card && (
                        surveyDetail?.questions?.length > 1 && (
                            <div
                                className={`flex w-full items-center justify-center ${!card && survey && 'mt-[25px]'}`}>
                                <p className={`min-w-[20px] text-black text-12 font-bold tracking-[-0.02rem] leading-[150%] mr-2 ${!card && survey && 'hidden'} mobile:block`}>
                                    {surveyDetail?.id ? activeQuestionIndex + 1 : 0}/{totalQuestionsCount}
                                </p>

                                <div
                                    className={cn('max-w-[764px] w-full rounded-full flex-1 h-3', css(styles.progressBar))}>
                                    <div
                                        className="z-10 relative flex rounded-l-xl h-3 bg-raspberry"
                                        style={{ width: surveyDetail?.id ? `${(activeQuestionIndex * 100) / totalQuestionsCount}%` : 0 }} />

                                    <div
                                        className={cn('w-full relative z-0 rounded-full bg-white h-3', css(styles.unfilledBar))} />
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    )
}

const styles = StyleSheet.create({
    progressBar: {
        boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.25)',
    },
    unfilledBar: {
        marginTop: -12,
    },
})

import { ColourOption } from './organisationUnit'

type TranslationFunction = (key: string) => string;

export const options = (t: TranslationFunction) :ColourOption[] => ([
    { id: 0, value: 'exact_time', label: t('carpooling.exact-time') },
    { id: 1, value: '15_mins', label: `+/- 15 ${t('yourImpact.mins')}` },
    { id: 2, value: '30_mins', label: `+/- 30 ${t('yourImpact.mins')}` },
    { id: 3, value: '1_hour', label: `+/- 1 ${t('carpooling.hour')}` },
    { id: 4, value: 'any_time', label: t('carpooling.any-time') },
])

export const optionsTravelling = (t: TranslationFunction) :ColourOption[] => ([
    { id: 0, value: 'driver_or_passenger', label: t('carpooling.driver-or-passenger') },
    { id: 1, value: 'driver', label: t('carpooling.driver') },
    { id: 2, value: 'passenger', label: t('carpooling.passenger') },
])

export const preferencesData = (t:TranslationFunction) => ([
    { id: 1, label: t('carpooling.smoking'), key: 'smoking', disabled: false },
    { id: 2, label: t('carpooling.verified-coming-soon'), key: 'verified', disabled: true },
    { id: 3, label: t('carpooling.food'), key: 'food', disabled: false },
    { id: 4, label: t('carpooling.pets'), key: 'pets', disabled: false },
])

export const switchDataJourney = (t:TranslationFunction) => [
    {
        id: 1,
        name: t('carpooling.active'),
        textActiveColor: 'text-marrow',
        textDefaultColor: 'text-cavolo',
        backgroundActiveColor: 'bg-mint',
        backgroundDefaultColor: 'bg-white',
        borderActiveColor: '',
        borderDefaultColor: 'border border-cavolo',
    },
    {
        id: 2,
        name: t('carpooling.previous'),
        textActiveColor: 'text-white',
        textDefaultColor: 'text-grey01',
        backgroundActiveColor: 'bg-grey01',
        backgroundDefaultColor: 'bg-white',
        borderActiveColor: '',
        borderDefaultColor: 'border border-grey01',
    },
]

export const switchDataReview = (t:TranslationFunction) => [
    {
        id: 1,
        name: t('carpooling.about-others'),
        textActiveColor: 'text-marrow',
        textDefaultColor: 'text-cavolo',
        backgroundActiveColor: 'bg-mint',
        backgroundDefaultColor: 'bg-white',
        borderActiveColor: '',
        borderDefaultColor: 'border border-cavolo',
    },
    {
        id: 2,
        name: t('carpooling.from-others'),
        textActiveColor: 'text-white',
        textDefaultColor: 'text-grey01',
        backgroundActiveColor: 'bg-grey01',
        backgroundDefaultColor: 'bg-white',
        borderActiveColor: '',
        borderDefaultColor: 'border border-grey01',
    },
]

export const preferenceOptions = (t:TranslationFunction) => [t('log.yes'), t('log.no'), t('carpooling.don\'t-mind')]

export const svgUserLocation = `<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="17" cy="17" r="8" fill="#0000FF" stroke="white" stroke-width="4"/>
  <circle cx="17" cy="17" r="17" fill="#0000FF" fill-opacity="0.15"/>
</svg>`

export const svgMarker = `<svg width="36" height="43" viewBox="0 0 36 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M33.5181 18.0227C33.5181 30.4848 17.4954 41.1667 17.4954 41.1667C17.4954 41.1667 1.47266 30.4848 1.47266 18.0227C1.47266 13.7732 3.16076 9.69779 6.1656 6.69295C9.17045 3.6881 13.2459 2 17.4954 2C21.7449 2 25.8203 3.6881 28.8252 6.69295C31.83 9.69779 33.5181 13.7732 33.5181 18.0227Z" fill="#00805B"/>
<path d="M17.4954 23.3636C20.4451 23.3636 22.8363 20.9724 22.8363 18.0227C22.8363 15.073 20.4451 12.6818 17.4954 12.6818C14.5457 12.6818 12.1545 15.073 12.1545 18.0227C12.1545 20.9724 14.5457 23.3636 17.4954 23.3636Z" fill="#00805B"/>
<path d="M33.5181 18.0227C33.5181 30.4848 17.4954 41.1667 17.4954 41.1667C17.4954 41.1667 1.47266 30.4848 1.47266 18.0227C1.47266 13.7732 3.16076 9.69779 6.1656 6.69295C9.17045 3.6881 13.2459 2 17.4954 2C21.7449 2 25.8203 3.6881 28.8252 6.69295C31.83 9.69779 33.5181 13.7732 33.5181 18.0227Z" stroke="#00805B" stroke-width="2.36776" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.4954 23.3636C20.4451 23.3636 22.8363 20.9724 22.8363 18.0227C22.8363 15.073 20.4451 12.6818 17.4954 12.6818C14.5457 12.6818 12.1545 15.073 12.1545 18.0227C12.1545 20.9724 14.5457 23.3636 17.4954 23.3636Z" stroke="#00805B" stroke-width="2.36776" stroke-linecap="round" stroke-linejoin="round"/>
<g clip-path="url(#clip0_23248_50655)">
<path d="M30.9403 18.2075C30.9403 28.5209 17.6801 37.3611 17.6801 37.3611C17.6801 37.3611 4.41992 28.5209 4.41992 18.2075C4.41992 14.6906 5.81697 11.3179 8.30374 8.83108C10.7905 6.34432 14.1633 4.94727 17.6801 4.94727C21.1969 4.94727 24.5697 6.34432 27.0565 8.83108C29.5432 11.3179 30.9403 14.6906 30.9403 18.2075Z" stroke="#FBF4E7" stroke-width="2.36776" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.6801 22.6275C20.1212 22.6275 22.1002 20.6486 22.1002 18.2075C22.1002 15.7663 20.1212 13.7874 17.6801 13.7874C15.239 13.7874 13.26 15.7663 13.26 18.2075C13.26 20.6486 15.239 22.6275 17.6801 22.6275Z" stroke="#FBF4E7" stroke-width="2.36776" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_23248_50655">
<rect width="35.3605" height="35.3605" fill="white" transform="translate(0 3.47363)"/>
</clipPath>
</defs>
</svg>`

const encodedSVGUser = encodeURIComponent(svgUserLocation)
const encodedSVGMarker = encodeURIComponent(svgMarker)
export const dataUserLocationURL = `data:image/svg+xml,${encodedSVGUser}`
export const dataMarkerURL = `data:image/svg+xml,${encodedSVGMarker}`

export const capitalizeFirstLetter = (text: string | undefined | null) => (text ? text.charAt(0).toUpperCase() + text.slice(1) : '')

export const convertToTitleCase = (str: string): string => {
    const words = str.split('_')
    return words
        .map((word, index) => (index === 0
            ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            : word.toLowerCase()))
        .join(' ')
}

import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { DirectionsRenderer, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import isEmpty from 'lodash/isEmpty'
import Layout from '../../components/layout/Layout'
import { ArrowTravel, MapIcon, PassengerIcon, StarIcon, UserDefaultIcon, WheelIcon } from '../../components/common/Svg'
import Clock from '../../components/common/ClockIcon'
import { getUser } from '../../state/user/selectors'
import Input from '../../components/common/Input'
import SecondButton from '../../components/common/SecondButton'
import { GOOGLE_API_KEY } from '../../constants'
import { dataMarkerURL, dataUserLocationURL } from '../../utils/carpooling'
import Loader from '../../components/common/Loader'
import ReviewsCard from '../../components/carpooling/ReviewsCard'
import { formatTime, getHourAndMinute } from '../../utils/date'
import { displayDistanceWithUnit, replacePartsOfText } from '../../utils/helpers'
import { carpoolingJourneyList, carpoolingReviewsList, matchesJourneyList } from '../../state/travel/selectors'
import { createCarpoolingMessages, fetchCarpoolingReviews } from '../../state/travel/actions'
import { Context } from '../../components/common/BaseContext'
import { getReportChatDetail } from '../../state/travel/api'

export default function CarpoolingMatchesDetails() {
    const dispatch = useDispatch()
    const { user } = useSelector(getUser)
    const navigate = useNavigate()
    const location = useLocation()
    const [visibleCount, setVisibleCount] = useState<number>(3)
    const isCollapsed = visibleCount === 3
    const [t] = useTranslation('global')
    const { matchesJourney } = useSelector(matchesJourneyList)
    const { carpoolingJourney } = useSelector(carpoolingJourneyList)
    const { carpoolingReviews } = useSelector(carpoolingReviewsList)
    const { id } = useParams()
    const matchesJourneyDetail = matchesJourney.find((item) => item?.id === Number(id))
    const journeyDetail = carpoolingJourney.find((item) => item?.id === Number(location?.state?.id))
    const { distanceUnit } = useContext(Context)

    const origin = { lat: Number(matchesJourneyDetail?.startPlaceLat), lng: Number(matchesJourneyDetail?.startPlaceLon) }
    const destination = { lat: Number(journeyDetail?.startPlaceLat), lng: Number(journeyDetail?.startPlaceLon) }

    const onSubmit = (value: { description: string }, { resetForm }: { resetForm: () => void }) => {
        dispatch(createCarpoolingMessages({
            receiver: matchesJourneyDetail?.user?.id,
            carpoolingJourneys: [journeyDetail?.id, matchesJourneyDetail?.id],
            text: value?.description,
        }, (response) => {
            navigate(`/carpooling-messages/${response?.chat}`, { state: { data: matchesJourneyDetail } })
        }))
        resetForm()
    }

    const onRestartLoad = useCallback(() => {
        dispatch(fetchCarpoolingReviews('from_others', matchesJourneyDetail?.user?.id || 0))
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_API_KEY,
    })

    const [directionsResponse, setDirectionsResponse] = useState<google.maps.DirectionsResult | null>(null)

    useEffect(() => {
        if (!isLoaded) return

        const directionsService = new google.maps.DirectionsService()
        directionsService.route(
            {
                origin,
                destination,
                travelMode: google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === google.maps.DirectionsStatus.OK && result) {
                    setDirectionsResponse(result)
                } else {
                    console.error('Directions request failed due to ', status)
                }
            },
        )
        // eslint-disable-next-line
    }, [isLoaded])

    const onSubmitReportAbuse = async () => {
        const response = await getReportChatDetail()

        navigate(`/carpooling-messages/${response?.id}`, { state: { isReportUser: true } })
    }

    return (
        <Layout showFooter={false}>
            <div className="w-full min-h-screen">
                <div
                    className="pb-[100px] flex flex-col items-center mobile:mt-[54px] mt-[12px] mobile:px-[42px] px-[16px]">
                    <div className="max-w-[600px] w-full">
                        <Formik initialValues={{ description: '' }} onSubmit={onSubmit}>
                            {() => (
                                <Form>
                                    <div className="flex justify-between xs-mobile:items-center">
                                        <div>
                                            <div className="flex items-center">
                                                <div onClick={() => navigate(-1)}
                                                    className="flex justify-end cursor-pointer">
                                                    <ArrowTravel width={22} height={22} />
                                                </div>

                                                {matchesJourneyDetail?.user?.uploadedImage ? (
                                                    <img
                                                        className="rounded-full medium:w-10 medium:h-10 w-[36px] h-[36px] object-cover mx-2.5"
                                                        src={matchesJourneyDetail?.user?.uploadedImage}
                                                        alt=""
                                                    />
                                                ) : (
                                                    <UserDefaultIcon className="w-[38px] h-[38px] mx-2.5" />
                                                )}

                                                <h1 className="heading-uppercase text-22 tracking-tight font-semibold text-cavolo">
                                                    {matchesJourneyDetail?.user?.firstName} {matchesJourneyDetail?.user?.lastName[0]}.
                                                </h1>

                                                {matchesJourneyDetail?.review && (
                                                    <div className="ml-2">
                                                        <StarIcon width={18} height={19} />
                                                    </div>
                                                )}

                                                <p className={`font-semibold not-heading-font text-grey01 text-12 ${matchesJourneyDetail?.review ? 'ml-0.5' : 'ml-2'}`}>
                                                    {matchesJourneyDetail?.review?.toFixed(1)} {isEmpty(carpoolingReviews) ? '' : `(${carpoolingReviews?.length} ${carpoolingReviews?.length === 1 ? t('carpooling.review') : t('carpooling.reviews')})`}
                                                </p>
                                            </div>

                                            <div className="flex xs-mobile:hidden mt-2.5">
                                                <MapIcon />

                                                <div className="flex flex-col ml-1">
                                                    <span className="text-12 font-normal text-cavolo">
                                                        {matchesJourneyDetail?.startPlaceName?.split(',')[0]}
                                                    </span>

                                                    <span className="text-10 text-grey01 font-normal">
                                                        {displayDistanceWithUnit(Number(matchesJourneyDetail?.distance), distanceUnit, t)} {t('carpooling.away')}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex max-xs-mobile:hidden">
                                            <MapIcon />

                                            <div className="flex flex-col ml-1">
                                                <span className="text-12 font-normal text-cavolo">
                                                    {matchesJourneyDetail?.startPlaceName?.split(',')[0]}
                                                </span>

                                                <span className="text-10 text-grey01 font-normal">
                                                    {displayDistanceWithUnit(Number(matchesJourneyDetail?.distance), distanceUnit, t)} {t('carpooling.away')}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    {!isLoaded ? (
                                        <div className="flex items-center justify-center w-full h-[342px]">
                                            <Loader width={75} height={75} />
                                        </div>
                                    ) : (
                                        <div className="mt-2">
                                            <GoogleMap
                                                zoom={14}
                                                mapContainerStyle={{ width: '100%', height: '342px' }}
                                                options={{
                                                    zoomControl: false,
                                                    streetViewControl: false,
                                                    mapTypeControl: false,
                                                    fullscreenControl: false,
                                                }}
                                            >
                                                {directionsResponse && (
                                                    <DirectionsRenderer
                                                        directions={directionsResponse}
                                                        options={{
                                                            polylineOptions: {
                                                                strokeColor: 'rgba(0, 0, 255, 0.6)',
                                                                strokeWeight: 4,
                                                            },
                                                            suppressMarkers: true,
                                                        // markerOptions: {
                                                        //     icon: {
                                                        //         url: dataMarkerURL,
                                                        //         scaledSize: new window.google.maps.Size(34, 34),
                                                        //         origin: new window.google.maps.Point(0, 0),
                                                        //         anchor: new window.google.maps.Point(17, 34),
                                                        //     },
                                                        // },
                                                        }}
                                                    />
                                                )}

                                                <Marker
                                                    position={origin}
                                                    icon={{
                                                        url: dataMarkerURL,
                                                        scaledSize: new window.google.maps.Size(35, 39),
                                                        origin: new window.google.maps.Point(0, 0),
                                                        anchor: new window.google.maps.Point(17.5, 39),
                                                    }}
                                                />

                                                <Marker
                                                    position={destination}
                                                    icon={{
                                                        url: dataUserLocationURL,
                                                        scaledSize: new window.google.maps.Size(34, 34),
                                                        origin: new window.google.maps.Point(0, 0),
                                                        anchor: new window.google.maps.Point(17, 17),
                                                    }}
                                                />
                                            </GoogleMap>
                                        </div>
                                    )}

                                    <div className="flex items-center mt-5">
                                        <div className="mr-1">
                                            <Clock {...getHourAndMinute(matchesJourneyDetail?.leavingDate)} />
                                        </div>

                                        {matchesJourneyDetail?.returningDate && (
                                            <Clock {...getHourAndMinute(matchesJourneyDetail?.returningDate)} />
                                        )}

                                        <h1 className="ml-2 text-cavolo font-normal text-12">
                                            {replacePartsOfText(t('carpooling.leaving-at-and-returning-at'), {
                                                '[leaving date]': formatTime(matchesJourneyDetail?.leavingDate || ''),
                                                '[returning date]': matchesJourneyDetail?.returningDate ? formatTime(matchesJourneyDetail?.returningDate) : t('carpooling.immediately-after-the-event').replace('[event]', user?.company?.travelEventName).toLowerCase(),
                                            })}
                                        </h1>
                                    </div>

                                    <div className="flex items-center mt-2">
                                        {matchesJourneyDetail?.travellingAs === 'driver_or_passenger' || matchesJourneyDetail?.travellingAs === 'driver' ? (
                                            <div className="mr-1">
                                                <WheelIcon />
                                            </div>
                                        ) : (
                                            <div className="w-[19px] h-[19px]" />
                                        )}

                                        {matchesJourneyDetail?.travellingAs === 'driver_or_passenger' || matchesJourneyDetail?.travellingAs === 'passenger' ? (
                                            <PassengerIcon />
                                        ) : (
                                            <div className="w-[19px] h-[19px]" />
                                        )}

                                        <h1 className="ml-2 text-cavolo font-normal text-12 ">
                                            {matchesJourneyDetail?.travellingAs === 'driver_or_passenger' ? (
                                                t('carpooling.happy-to-be-driver-or-passenger')
                                            ) : (
                                                t(`carpooling.${matchesJourneyDetail?.travellingAs}`)
                                            )}
                                        </h1>
                                    </div>

                                    <h1 className="text-cavolo font-semibold text-18 mt-5">
                                        {t('carpooling.message')}
                                    </h1>

                                    <h1 className="text-12 font-normal text-black mt-2 leading-4">
                                        {t('carpooling.get-in-touch-with-name-to-arrange-a-shared-journey').replace('[name]', matchesJourneyDetail?.user?.firstName || '')}
                                    </h1>

                                    <Input
                                        placeholder={t('carpooling.would-you-like-to-share-a-journey-to-event-name').replace('[event name]', user?.company?.travelEventName)}
                                        type="textarea"
                                        name="description"
                                        className="h-28 mt-2 mb-[0px] border border-avocado"
                                        component="textarea"
                                    />

                                    <SecondButton
                                        text={t('carpooling.message-name').replace('[name]', matchesJourneyDetail?.user?.firstName || '')}
                                        className="mt-1 w-full h-[48px] font-bold bg-rocket rounded-md text-16 text-pistachio"
                                    />

                                    <h1 className="text-cavolo font-semibold text-18 mt-2">
                                        {t('carpooling.about-me')}
                                    </h1>

                                    <h1 className="text-12 font-normal text-black mt-2 leading-4">
                                        {t('carpooling.no-about-me')}
                                    </h1>

                                    <div className="flex items-center mt-2">
                                        <h1 className="text-cavolo font-semibold text-18">
                                            {t('carpooling.reviews')}
                                        </h1>

                                        {matchesJourneyDetail?.review && (
                                            <div className="ml-2">
                                                <StarIcon width={18} height={19} />
                                            </div>
                                        )}

                                        <p className={`font-semibold not-heading-font text-grey01 text-12 ${matchesJourneyDetail?.review ? 'ml-0.5' : 'ml-2'} `}>
                                            {matchesJourneyDetail?.review?.toFixed(1)} {isEmpty(carpoolingReviews) ? '' : `(${carpoolingReviews?.length} ${carpoolingReviews?.length === 1 ? t('carpooling.review') : t('carpooling.reviews')})`}
                                        </p>
                                    </div>

                                    {isEmpty(carpoolingReviews) ? (
                                        <h1 className="mt-2 font-normal text-14 not-heading-font text-cavolo">
                                            {t('carpooling.you-don’t-have-any-reviews-yet')}
                                        </h1>
                                    ) : (
                                        <>
                                            {/* {carpoolingReviews?.map((item: any) => ( */}
                                            {/*    <ReviewsCard itemUser={item.fromUser} item={item} /> */}
                                            {/* ))} */}

                                            {carpoolingReviews?.slice(0, visibleCount).map((item: any) => (
                                                <ReviewsCard itemUser={item?.fromUser} item={item} />
                                            ))}

                                            {visibleCount !== carpoolingReviews?.length && carpoolingReviews?.length > 3 && (
                                                <h1 onClick={() => setVisibleCount(isCollapsed ? carpoolingReviews.length : 3)}
                                                    className="text-12 font-normal text-black underline underline-offset-2 cursor-pointer mt-2">
                                                    {t('carpooling.see-more')}
                                                </h1>
                                            )}
                                            {/* {carpoolingReviews?.length > 3 && ( */}
                                            {/*    <h1 className="text-12 font-normal text-black underline underline-offset-2 cursor-pointer mt-2"> */}
                                            {/*        {t('carpooling.see-more')} */}
                                            {/*    </h1> */}
                                            {/* )} */}
                                        </>
                                    )}

                                    <SecondButton
                                        type="button"
                                        onClick={onSubmitReportAbuse}
                                        text={t('carpooling.report-abuse')}
                                        className="mt-2 w-full h-[48px] font-bold border border-cavolo rounded-md text-16 text-cavolo"
                                    />
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import SponsoredByBlock from '../SponsoredByBlock'
import { getUser, getWhiteLabelingList } from '../../state/user/selectors'
import SurveyBody from './SurveyBody'
import QuizQuitOverlayModal from '../quiz/QuizQuitOverlayModal'
import { Context } from '../common/BaseContext'
import { fetchSurveys } from '../../state/travel/actions'
import { surveyList } from '../../state/travel/selectors'
import { useQueryParams } from '../../hooks/queryString'
import { DismissIcon } from '../common/Svg'
import Loader from '../common/Loader'

export default function SurveyModal() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user } = useSelector(getUser)
    const { survey: surveyId } = useQueryParams()
    const [t] = useTranslation('global')
    const { setShowSurveyModal } = useContext(Context)
    const [showModalPoint, setShowModalPoint] = useState<boolean>(false)
    const [showSurveyQuitOverlayModal, setShowSurveyQuitOverlayModal] = useState(false)
    const { surveyById } = useSelector(surveyList)
    const { whiteLabeling, loading } = useSelector(getWhiteLabelingList)

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    useEffect(() => {
        dispatch(fetchSurveys(surveyId || 0))
        // eslint-disable-next-line
    }, [])

    if (loading && !surveyById?.id) {
        return (
            <div className="flex items-center justify-center w-full h-screen">
                <Loader width={75} height={75} />
            </div>
        )
    }

    return (
        <div>
            <div className="fixed bg-white inset-0 z-[1000000] overflow-y-auto">
                <div onClick={() => setShowSurveyModal(0)} className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                <div className="relative flex items-center justify-center">
                    <div className="relative w-full h-full bg-white rounded-lg">
                        <div className="w-full bg-pistachio min-h-screen overflow-y-scroll pb-[250px]">
                            <div onClick={() => {
                                if (!surveyById?.id) {
                                    setShowSurveyModal(0)
                                    navigate('/')
                                } else {
                                    setShowSurveyQuitOverlayModal(true)
                                }
                            }} className={`z-10 cursor-pointer mt-[35px] mobile:px-[42px] px-[16px] ${surveyById?.id && 'absolute'}`}>
                                <DismissIcon width={13} height={13} color={whiteLabeling?.black} />
                            </div>

                            {!surveyById?.id && loading && (
                                <div className="p-4 heading-uppercase heading-font text-22 tracking-tight font-semibold text-cavolo mobile:px-[42px] px-[16px]">
                                    {t('surveys.survey-not-found')}
                                </div>
                            )}

                            {surveyById?.sponsorshipBlock && (
                                <div className="mb-3 mobile:hidden"><SponsoredByBlock
                                    item={surveyById?.sponsorshipBlock} />
                                </div>
                            )}

                            {/* {surveyById?.id && ( */}
                            {/*    <SurveyDetailHeader activeQuestionIndex={0} */}
                            {/*        surveyDetail={surveyById} */}
                            {/*        totalQuestionsCount={surveyById?.questions?.length || 0} */}
                            {/*        onClose={() => setShowSurveyQuitOverlayModal(true)} */}
                            {/*    /> */}
                            {/* )} */}

                            {surveyById?.id && (
                                <div className="w-full flex justify-center mobile:px-[42px] px-[16px]">
                                    <div className="max-w-[764px] w-full">
                                        <SurveyBody
                                            surveyDetail={surveyById}
                                            survey
                                            setShowModalPoint={setShowModalPoint}
                                            showModalPoint={showModalPoint} />
                                    </div>
                                </div>
                            )}

                            <QuizQuitOverlayModal
                                description={user?.company?.gamification ? t('home.quit-text-if-gamification-is-enabled') : t('home.quit-text-if-gamification-is-disabled')}
                                isShown={showSurveyQuitOverlayModal}
                                onClose={() => {
                                    navigate('/')
                                    setShowSurveyModal(0)
                                }}
                                onContinue={() => setShowSurveyQuitOverlayModal(false)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getUser } from '../state/user/selectors'
import OrganisationUnitBody from './travel/OrganisationUnitBody'

interface IProps {
    isShown?: boolean
    setScheduleShowModal?: (value: boolean) => void;
    onClose: () => void
}

export default function OrganisationUnitModal({ isShown = true, onClose, setScheduleShowModal }: IProps) {
    const { user } = useSelector(getUser)
    const [t] = useTranslation('global')

    return (
        <div>
            {isShown && (
                <div>
                    <div className="fixed inset-0 z-[1000000] overflow-y-auto">
                        <div className="fixed inset-0 w-full h-full bg-[#000] opacity-50" />

                        <div className="mobile:mt-[0px] flex items-center justify-center min-h-screen">
                            <div className="bg-pistachio relative xs-mobile:w-[500px] w-11/12 mobile:px-4 px-3 rounded-lg" style={{ boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.15)' }}>
                                <div className="flex flex-col justify-center py-[32px]">
                                    <h1 className="text-black font tracking-tight font-medium text-22 mb-[20px]">
                                        {user?.company?.organisationalUnitAffiliation ? t('navbar.my_affiliations') : t('account.your-name').replace('[name]', user?.company?.organisationalUnitName || 'club')}
                                    </h1>

                                    <OrganisationUnitBody setScheduleShowModal={setScheduleShowModal} onClose={onClose}
                                        isModal />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { JourneyBadge, MapIcon, PassengerIcon, StarIcon, UserDefaultIcon, WheelIcon } from '../common/Svg'
import Clock from '../common/ClockIcon'
import { getUser } from '../../state/user/selectors'
import { formatTime, getHourAndMinute } from '../../utils/date'
import { displayDistanceWithUnit, replacePartsOfText } from '../../utils/helpers'
import { Context } from '../common/BaseContext'

interface IProps{
    item: any
}

export default function MatchCard({ item }: IProps) {
    const { user } = useSelector(getUser)
    const [t] = useTranslation('global')
    const { distanceUnit } = useContext(Context)

    return (
        <div className="cursor-pointer">
            <div className="w-full bg-pistachio mt-2 rounded-2xl px-4 py-3"
                style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        {item?.user.uploadedImage ? (
                            <img
                                className="rounded-full medium:w-10 medium:h-10 w-[36px] h-[36px] object-cover mr-2.5"
                                src={item?.user?.uploadedImage}
                                alt=""
                            />
                        ) : (
                            <UserDefaultIcon className="w-[38px] h-[38px] mr-2.5" />
                        )}

                        <h1 className="font-bold text-14 text-cavolo">
                            {item?.user?.firstName} {item?.user?.lastName[0]}.
                        </h1>

                        {item.verificationStatus && (
                            <div className="ml-1.5">
                                <JourneyBadge />
                            </div>
                        )}

                        {item?.review && (
                            <React.Fragment>
                                <div className="ml-2">
                                    <StarIcon width={18} height={19} />
                                </div>

                                <p className="font-semibold not-heading-font text-grey01 text-12 ml-0.5">
                                    {item?.review?.toFixed(1)}
                                </p>
                            </React.Fragment>
                        )}
                    </div>

                    <div className="flex">
                        <MapIcon />

                        <div className="flex flex-col ml-1">
                            <span className="text-12 font-normal text-cavolo">
                                {item?.startPlaceName?.split(',')[0]}
                            </span>

                            <span className="text-10 text-grey01 font-normal">
                                {displayDistanceWithUnit(item?.distance, distanceUnit, t)} {t('carpooling.away')}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="mt-2.5 flex items-center">
                    <div className="mr-1">
                        <Clock {...getHourAndMinute(item?.leavingDate)} />
                    </div>

                    {item?.returningDate && (
                        <Clock {...getHourAndMinute(item?.returningDate)} />
                    )}

                    <h1 className="ml-2 text-cavolo font-normal text-12">
                        {replacePartsOfText(t('carpooling.leaving-at-and-returning-at'), {
                            '[leaving date]': formatTime(item?.leavingDate),
                            '[returning date]': item?.returningDate ? formatTime(item?.returningDate) : t('carpooling.immediately-after-the-event').replace('[event]', user?.company?.travelEventName).toLowerCase(),
                        })}
                    </h1>
                </div>

                <div className="flex items-center mt-2">
                    {item?.travellingAs === 'driver_or_passenger' || item?.travellingAs === 'driver' ? (
                        <div className="mr-1">
                            <WheelIcon />
                        </div>
                    ) : (
                        <div className="w-[19px] h-[19px]" />
                    )}

                    {item?.travellingAs === 'driver_or_passenger' || item?.travellingAs === 'passenger' ? (
                        <PassengerIcon />
                    ) : (
                        <div className="w-[19px] h-[19px]" />
                    )}

                    <h1 className="ml-2 text-cavolo font-normal text-12 ">
                        {item?.travellingAs === 'driver_or_passenger' ? (
                            t('carpooling.happy-to-be-driver-or-passenger')
                        ) : (
                            t(`carpooling.${item?.travellingAs}`)
                        )}
                    </h1>
                </div>
            </div>
        </div>
    )
}

import axios from 'axios'
import { Dispatch } from 'redux'
import moment from 'moment/moment'
import { NavigateFunction } from 'react-router-dom'
import cloneDeep from 'lodash/cloneDeep'
import { ILeg, ILegs, ITravelData } from '../types/data'
import { GOOGLE_API_KEY } from '../constants'
import { clearCommuteStartEnd,
    clearLegTransport,
    multipleSaveLegs, changeCommuteDestination, clearCommuteSave } from '../state/travel/actions'

export function legInstancesToFormData(legs: ILeg[]) {
    return legs.map((item: ILeg) => ({
        id: item.id,
        backendLegId: item.id,
        ceo2Kg: item.ceo2Kg,
        transportId: item.transport?.id,
        transportName: item.transport?.name,
        transportSize: item.transportSize ? {
            ...item.transportSize,
            selected: true,
        } : undefined,
        transportFuelType: item.transportFuelType ? {
            ...item.transportFuelType,
            selected: true,
        } : undefined,
        travelDistanceMiles: item.travelDistanceMiles ? `${item.travelDistanceMiles} miles` : '',
        travelDistanceKilometres: item.travelDistanceKilometres || 0,
        travelDistanceMilesNum: item.travelDistanceMiles || '',
        travelDistanceKilometresNum: item.travelDistanceKilometres || '',
        countOfPeople: item.countOfPeople,
        isGoingBack: item.isGoingBack,
        choosedCar: {
            ...item.transport,
            selected: true,
        },
        startPlaceId: item.startPlaceId,
        startPlaceName: item.startPlaceName,
        startPlaceLon: item.startPlaceLon,
        startPlaceLat: item.startPlaceLat,
        endPlaceId: item.endPlaceId,
        endPlaceName: item.endPlaceName,
        endPlaceLon: item.endPlaceLon,
        endPlaceLat: item.endPlaceLat,

        position: item.position,
    }))
}

export function saveCommuteInstanceToLocalstorage(commute: ITravelData, outboundFields = {}, returnFields = {}, date = '') {
    if (!commute || !commute.legs) {
        console.log('Invalid commute object or missing legs property.')
        return
    }

    const outboundLegs = legInstancesToFormData(commute.legs.filter((i: ILeg) => !i.isGoingBack))

    const returnLegs = legInstancesToFormData(commute.legs.filter((i: ILeg) => i.isGoingBack))

    const gameDate = typeof commute.game === 'object' && commute.game !== null ? commute.game?.date : null

    localStorage.setItem('travelData', JSON.stringify({
        backendCommuteId: commute.id,
        totalCeo2Kg: commute.totalCeo2Kg,
        id: commute.id,
        date: date || commute.date,
        startPostcode: commute.startPostcode,
        legs: outboundLegs,
        startPlaceId: commute.startPlaceId,
        startPlaceName: commute.startPlaceName,
        startPlaceLon: commute.startPlaceLon,
        startPlaceLat: commute.startPlaceLat,
        endPlaceId: commute.endPlaceId,
        endPlaceName: commute.endPlaceName,
        endPlaceLon: commute.endPlaceLon,
        endPlaceLat: commute.endPlaceLat,

        gameDate,
        ...outboundFields,
    }))

    localStorage.setItem('returnTravelData', JSON.stringify({
        totalCeo2Kg: commute.totalCeo2Kg,
        id: commute.id,
        date: commute.date,
        startPostcode: commute.startPostcode,
        legs: returnLegs,
        gameDate,

        endPlaceId: commute.returnJourneyEndPlaceId,
        endPlaceName: commute.returnJourneyEndPlaceName,
        endPlaceLon: commute.returnJourneyEndPlaceLon,
        endPlaceLat: commute.returnJourneyEndPlaceLat,
        startPlaceId: commute.returnJourneyStartPlaceId,
        startPlaceName: commute.returnJourneyStartPlaceName,
        startPlaceLon: commute.returnJourneyStartPlaceLon,
        startPlaceLat: commute.returnJourneyStartPlaceLat,

        ...returnFields,
    }))
}

export const getLocationByPlaceId = async (placeId: string) => {
    const { data } = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${GOOGLE_API_KEY}`)
    let location = { lon: '', lat: '' }

    if (data.results && data.results[0]) {
        const dataLocation = data.results[0].geometry.location

        location = { lon: dataLocation.lng, lat: dataLocation.lat }
    }

    return location
}

export const getPlaceInfoByLocation = async (latitude: number, longitude: number) => {
    const { data } = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=true&key=${GOOGLE_API_KEY}`)
    return data.results[0].formatted_address
}

export const clearTravelData = (dispatch: Dispatch) => {
    localStorage.removeItem('travelData')
    localStorage.removeItem('places')
    localStorage.removeItem('returnTravelData')
    localStorage.removeItem('travelDataEdit')

    dispatch(clearLegTransport())
    dispatch(clearCommuteStartEnd())
    dispatch(clearCommuteSave())
}

export const logSavedCommute = (setLoading: (loading: boolean) => void, dispatch: any, commuteDate: Date, commute: any, gameIds: any, gameDate: any, navigate: NavigateFunction) => {
    setLoading(true)

    const today = new Date()

    let outboundLegsString = `${commute.startPlaceName}||${commute.endPlaceName}`
    let returnLegsString = `${commute.returnJourneyEndPlaceName}||${commute.returnJourneyStartPlaceName}`

    commute.legs.filter((i: any) => !i.isGoingBack).sort((a: any, b: any) => a.position - b.position).map((i: any) => {
        outboundLegsString = `${outboundLegsString}||${i.startPlaceName}||${i.endPlaceName}`
        return null
    })

    commute.legs.filter((i: any) => i.isGoingBack).sort((a: any, b: any) => a.position - b.position).map((i: any) => {
        returnLegsString = `${returnLegsString}||${i.endPlaceName}||${i.startPlaceName}`
        return null
    })

    let defaultTile = 'one-way'

    if (outboundLegsString === returnLegsString) {
        defaultTile = 'same-as-outbound'
    }

    const commuteStartEndData = {
        startPlaceId: commute.startPlaceId,
        startPlaceName: commute.startPlaceName,
        startPlaceLon: commute.startPlaceLon,
        startPlaceLat: commute.startPlaceLat,
        endPlaceId: commute.endPlaceId,
        endPlaceName: commute.endPlaceName,
        endPlaceLon: commute.endPlaceLon,
        endPlaceLat: commute.endPlaceLat,
        returnJourneyStartPlaceId: defaultTile !== 'same-as-outbound' ? commute.returnJourneyStartPlaceId : undefined,
        returnJourneyStartPlaceName: defaultTile !== 'same-as-outbound' ? commute.returnJourneyStartPlaceName : undefined,
        returnJourneyStartPlaceLon: defaultTile !== 'same-as-outbound' ? commute.returnJourneyStartPlaceLon : undefined,
        returnJourneyStartPlaceLat: defaultTile !== 'same-as-outbound' ? commute.returnJourneyStartPlaceLat : undefined,
        returnJourneyEndPlaceId: defaultTile !== 'same-as-outbound' ? commute.returnJourneyEndPlaceId : undefined,
        returnJourneyEndPlaceName: defaultTile !== 'same-as-outbound' ? commute.returnJourneyEndPlaceName : undefined,
        returnJourneyEndPlaceLon: defaultTile !== 'same-as-outbound' ? commute.returnJourneyEndPlaceLon : undefined,
        returnJourneyEndPlaceLat: defaultTile !== 'same-as-outbound' ? commute.returnJourneyEndPlaceLat : undefined,

        games: gameIds,
        dates: commuteDate,
        journeyType: commute.journeyType,
    }

    dispatch(changeCommuteDestination({}, undefined, commuteStartEndData, (commuteResponse: any) => {
        const multipleSaveLegsData = commute?.legs.filter((i: any) => (defaultTile === 'same-as-outbound' ? !i.isGoingBack : true)).sort((a: ILeg, b: ILeg) => b.position - a.position)?.map((item: ILeg) => ({
            transport: item?.transport.id,
            transportSize: item?.transportSize?.id,
            transportFuelType: item?.transportFuelType?.id,
            countOfPeople: item?.countOfPeople,
            isGoingBack: item.isGoingBack,

            startPlaceId: item.startPlaceId,
            startPlaceName: item.startPlaceName,
            startPlaceLon: item.startPlaceLon,
            startPlaceLat: item.startPlaceLat,

            endPlaceId: item.endPlaceId,
            endPlaceName: item.endPlaceName,
            endPlaceLon: item.endPlaceLon,
            endPlaceLat: item.endPlaceLat,

            position: item.position,
        })) || []

        dispatch(multipleSaveLegs({ commute: commuteResponse.id, legs: multipleSaveLegsData }, async (saveLegsResponse) => {
            saveCommuteInstanceToLocalstorage({ ...commute, ...commuteStartEndData, id: commuteResponse.id, legs: saveLegsResponse.legs }, {
                gameIds,
                gameDate,
            }, {}, today ? moment(today).format('YYYY-MM-DD') : '')

            navigate('/log/track-travel-overview', { state: { defaultTile, todayDate: moment(commuteDate).format('YYYY-MM-DD'), fromLogScreen: true } })
            setLoading(false)
        }))
    }))
}

export const changeLegFromLocalstorage = (isGoingBack: boolean, newLeg: (leg?: any) => any, updatingLegId?: number) => {
    const storedDataString = localStorage.getItem(isGoingBack ? 'returnTravelData' : 'travelData') || '{}'
    const storedData = JSON.parse(storedDataString)

    let newLegs = storedData.legs

    if (updatingLegId) {
        newLegs = storedData.legs.map((i: ILegs) => {
            if (i.backendLegId === updatingLegId) {
                return newLeg(i)
            }

            return i
        })
    } else {
        const leg = newLeg({})

        newLegs = storedData.legs.map((i: ILegs) => {
            if ((i.position || 1) >= leg.position) {
                return {
                    ...i,
                    position: (i.position || 1) + 1,
                }
            }

            return i
        })

        newLegs.push(leg)
    }

    localStorage.setItem(isGoingBack ? 'returnTravelData' : 'travelData', JSON.stringify({
        ...storedData,
        legs: newLegs,
        createLegPosition: 0,
        legStartEndEmpty: false,
        legStartEnd: null,
    }))
}

export const checkIsCar = (transportName: string | undefined) => {
    const transportLower = transportName?.toLowerCase()
    return transportLower?.includes('car') || transportLower?.includes('taxi')
}

export function combineLegs(legs: ILegs[]): ILegs[] {
    const combinedLegs: ILegs[] = []
    let currentLeg: ILegs | null = null

    cloneDeep(legs).map((leg) => {
        if (currentLeg === null) {
            currentLeg = leg
            currentLeg.combinedLegs = [cloneDeep(leg)]
        } else if ((currentLeg.transportId || currentLeg.transport.id || 0) === (leg.transportId || leg.transport.id || 1) && leg.startPlaceName && currentLeg.endPlaceId === leg.startPlaceId && (checkIsCar(leg.transportName || leg.transport.name) ? (leg.transportSize.id === currentLeg.transportSize.id && leg.transportFuelType.id === currentLeg.transportFuelType.id && leg.countOfPeople === currentLeg.countOfPeople) : true)) {
            currentLeg.combinedLegs = [...(currentLeg.combinedLegs || []), cloneDeep(leg)]
            currentLeg.endPlaceId = leg.endPlaceId
            currentLeg.endPlaceLat = leg.endPlaceLat
            currentLeg.endPlaceLon = leg.endPlaceLon
            currentLeg.endPlaceName = leg.endPlaceName
        } else {
            combinedLegs.push(currentLeg)
            currentLeg = leg
            currentLeg.combinedLegs = [leg]
        }
        return leg
    })

    if (currentLeg !== null) {
        combinedLegs.push(currentLeg)
    }

    return combinedLegs.map((leg) => ({
        ...leg,
        travelDistanceKilometres: leg?.combinedLegs?.reduce((accumulator, i) => accumulator + (i.travelDistanceKilometres || 0), 0) || 0,
        ceo2Kg: leg?.combinedLegs?.reduce((accumulator, i) => accumulator + (i.ceo2Kg || 0), 0) || 0,
    }))
}

export const getTextBeforeFirstComma = (text: string) => {
    const commaIndex = text.indexOf(',')
    if (commaIndex === -1) {
        return text // No comma found, return the whole text
    }
    return text.substring(0, commaIndex)
}

async function getPlaceId(lat:string, lng:string) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_API_KEY}`

    try {
        const response = await fetch(url)
        const data = await response.json()

        if (data.status === 'OK' && data.results.length > 0) {
            return data.results[0].place_id
        }
        return null
    } catch (error) {
        return null
    }
}

export async function getPlaceIdsForSteps(steps: any) {
    const placeIdPromises = steps.map(async (step: any) => {
        const startLat = step.startLocation.latLng.latitude
        const startLng = step.startLocation.latLng.longitude
        const endLat = step.endLocation.latLng.latitude
        const endLng = step.endLocation.latLng.longitude

        const startPlaceId = await getPlaceId(startLat, startLng)
        const endPlaceId = await getPlaceId(endLat, endLng)

        if (startPlaceId && endPlaceId) {
            return {
                startLocation: step.startLocation,
                endLocation: step.endLocation,
                startPlaceId,
                endPlaceId,
            }
        }
        return null
    })

    const placeIdsArray = await Promise.all(placeIdPromises)

    return placeIdsArray.filter((place) => place !== null)
}

export const legDefaultsHelpText = (multiDaySection: any, t: any) => {
    if (!multiDaySection) {
        return ''
    }

    const { start, end } = multiDaySection
    let value = ''

    if (start === 'outbound' && end === 'event') {
        value = t('log.outbound-to-event')
    }
    if (start === 'outbound' && end === 'accommodation') {
        value = t('log.outbound-to-accommodation')
    }

    if (start === 'event' && end === 'accommodation') {
        value = t('log.event-to-accommodation')
    }
    if (start === 'accommodation' && end === 'event') {
        value = t('log.accommodation-to-event')
    }

    if (start === 'accommodation' && end === 'return') {
        value = t('log.return-from-accommodation')
    }
    if (start === 'event' && end === 'return') {
        value = t('log.return-from-event')
    }

    if (value) {
        return `${value}:`
    }

    return ''
}

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga4'
import { getUser } from '../state/user/selectors'
import { ShieldIcon } from '../components/common/Svg'
import AccountSidebar from '../components/account/AccountSidebar'
import { getLeaderboard } from '../state/leaderboard/selectors'
import OrganisationUnitBody from '../components/travel/OrganisationUnitBody'

export default function OrganisationUnit() {
    const { user } = useSelector(getUser)
    const { leaderboard } = useSelector(getLeaderboard)
    const [t] = useTranslation('global')

    const imageOrganisation = leaderboard?.leaderboards?.find((item) => item?.entry?.name === user?.company?.organisationalUnitName)

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Organisation units' })
    }, [])

    return (
        <AccountSidebar>
            <div
                className="mobile:mt-[52px] mt-[32px] flex-1 tablet:mb-[120px] mb-[80px] mobile:ml-10 mx-4 mobile:mr-10 mobile:w-[562px]">
                <h1 className="heading-uppercase heading-font text-black font tracking-tight font-medium tablet:text-36 mobile:text-26 text-24 mb-[22px]">
                    {user?.company?.organisationalUnitAffiliation ? t('navbar.my_affiliations') : t('account.your-name').replace('[name]', user?.company?.organisationalUnitName || 'club')}
                </h1>

                <div className="flex items-center mb-[22px]">
                    {user?.organisationUnit?.image ? (
                        <img className="w-[31px] h-[34px] object-cover" src={user?.organisationUnit?.image} alt="" />
                    ) : (
                        imageOrganisation?.entry?.image
                            ? (
                                <img className="w-[26px] h-[28px] object-cover" src={imageOrganisation?.entry?.image} alt="" />
                            ) : <ShieldIcon />
                    )}

                    <h1 className="tracking-tight text-black font-normal text-18 ml-2">
                        {user?.organisationUnit?.name}
                    </h1>
                </div>

                <OrganisationUnitBody />
            </div>
        </AccountSidebar>
    )
}
